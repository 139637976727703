import React, { useState } from 'react';

// COMPONENTS
// import AdminNav from '../Components/AdminNav';
// import Other from '../Components/OtherLog';
// import Checkbox from '../Components/Checkbox';
import Checkbox from './Checkbox';

// ASSETS
import spin from '../../Assets/loadingBtn2.gif';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../Store/Actions/authActions';
import { loginTeammate } from '../../Store/Actions/teammateActions';

// SHARE STYLE
import {
    AuthWrapper,
    RightSideAuth,
    FormWrapper,
    H1,
    H5,
    H6,
    // InputWrapper,
    PswWrapper,
    EyeWrapper,
    LabelCss,
    InputCss,
    // Label,
    // CheckTxt,
    BtnWrapper,
    Btn,
    LinkCss,
    Hr
} from '../ShareStyle/ShareStyle';

// PACKEGES
import styled from 'styled-components';

// COMPONENTS
import Title from '../../Dashboard/DashboardComponents/PagesTitles/PagesTitles';
import NotifDisplay from '../AuthComponents/NotifDisplay';

const LinksDiv = styled.div`
    display:flex;
        >h5:nth-child(1){
            text-align: left;
        }
`;

const CheckboxContainer = styled.div`
    position: relative;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
`;


const SignIn = () => {
    const dispatch = useDispatch();
    const err = useSelector((state) => state.auth.errors);
    const loading = useSelector((state) => state.auth.authLoading);
    const [showPassword, setShowPassword] = useState(true);
    const [access, setAccess] = useState({
        email: '',
        psw: '',
    });
    const [checked] = useState(false);
    const [checked2, setChecked2] = useState(false);

    const handleShowPassword = () => {
        setShowPassword(false);
        setTimeout(() => {
            setShowPassword(true);
        }, 4000)
    };

    // const handleCheckboxChange = event => {
    //     setChecked(event.target.checked)
    // };

    const onChange = (e) => {
        setChecked2(e.target.checked);
    };

    const submitForm = (e) => {
        e.preventDefault();
        if (!checked2) {
            dispatch(loginUser(access, checked));
        } else {
            dispatch(loginTeammate(access));
        }
    };


    return (
        <AuthWrapper>
            <Title
                first='Welcome back!'
                second='Thank you for choosing to visit us!'
                third=''
            />

            <CheckboxContainer>
                <Checkbox
                    onChange={onChange}
                    checked={checked2}
                />
            </CheckboxContainer>

            <RightSideAuth style={{ background: 'var(--background2)' }}>

                <FormWrapper signin onSubmit={submitForm}>
                    <H1>Login</H1>
                    <H5 style={{ textAlign: 'center', marginTop: '0' }}>
                        {!checked2 ?
                            'in to a personal account'
                            :
                            'in to a team account'
                        }

                    </H5>
                    <Hr></Hr>

                    {/* <H5 style={{ textAlign: 'center' }}>Log in with:</H5>
                    <Other />
                    <H5 style={{ textAlign: 'center', margin: '0' }}>Or with:</H5> */}


                    <LabelCss htmlFor="email">E-mail<span>*</span></LabelCss>
                    <InputCss
                        placeholder="Enter your e-mail"
                        id='email'
                        type="mail"
                        name='email'
                        required
                        value={access.email}
                        onChange={(e) =>
                            setAccess({
                                ...access,
                                email: e.target.value
                            })
                        }
                    >
                    </InputCss>

                    {!checked2 &&
                        <PswWrapper>
                            <LabelCss htmlFor='psw'>Password<span>*</span></LabelCss>
                            <InputCss
                                placeholder="Enter your password"
                                id='psw'
                                type={showPassword ? 'password' : 'text'}
                                name='password'
                                required
                                value={access.psw}
                                onChange={(e) =>
                                    setAccess({
                                        ...access,
                                        psw: e.target.value
                                    })
                                }
                            ></InputCss>
                            <EyeWrapper onClick={() => handleShowPassword()}>
                                {showPassword ?
                                    <i className="far fa-eye-slash"></i>
                                    :
                                    <i className="far fa-eye"></i>
                                }
                            </EyeWrapper>
                        </PswWrapper>
                    }


                    {/* <Label>
                        <Checkbox
                            checked={checked}
                            onChange={handleCheckboxChange}
                        />
                        <CheckTxt>Remember me</CheckTxt>
                    </Label> */}

                    <BtnWrapper>
                        {err === 'This e-mail is not activated!' &&
                            <NotifDisplay />
                        }
                        {loading ?
                            <Btn disabled>
                                <img src={spin} alt='loading'></img>
                                Loading...
                            </Btn>
                            :
                            <Btn type='submit'>Log in</Btn>
                        }
                        {/* <Btn type='submit'>Log in</Btn> */}
                    </BtnWrapper>

                    {!checked2 &&
                        <LinksDiv>
                            <H5>
                                Forgot
                                <LinkCss to='/forgotPsw'>password?</LinkCss >
                            </H5>
                            <H5>
                                Create new
                                <LinkCss to='/register'>account!</LinkCss >
                            </H5>
                        </LinksDiv>
                    }

                    <H6>all fields marked with * are required</H6>
                </FormWrapper>

            </RightSideAuth>

        </AuthWrapper>
    );
}
export default SignIn;