import styled from 'styled-components';

// export const RightSide = styled.div`
//     min-height: 80vh;
//     background: var(--background1);
//     border-radius: var(--radius);
//     padding: 100px 30px 30px;
//     text-align: center;
//         >h1, >h2, >h3 {
//             color: var(--text4);
//         }
//         >h2{
//             font-size: 20px;
//         }
//         >h3{
//             /* font-weight:bold; */
//             margin-top: 30px;
//             margin-bottom: 10px;
//         }
//         @media (max-width: 775px) {
//             >h1 {
//             font-size: 30px;
//             }
//             >h2{
//                 font-size: 12px;
//             }
//             >h3{
//                 font-size: 16px;
//             }
//         };
// `;

export const GeneralWrapper = styled.div`
    background: transparent;
    width:calc(100% - 250px);
    min-height:calc(100vh - 80px);
    margin-left:250px;
    padding: 20px;
    /* height:100vh; */
    display: flex;
    align-items: flex-start;
    justify-content: center;
    /* overflow:auto; */
        @media (max-width: 775px) {
                width:100%;
                margin-left:0;
            };
`;

export const RightSide = styled.div`
    position:relative;
    margin: 80px 0;
    width: 400px;
    max-width: 400px;
    /* min-height: 30vh; */
    /* max-height:20vh; */
    background-image: linear-gradient(to top,#28283e 0%,#52527b);
    border-radius: var(--radius);
    padding: 10px;
    margin:20px;
    text-align: center;    
        @media (max-width: 1320px) {
                    width:600px;
                    max-width:600px;
                };
`;

export const LeftSide = styled.div`
    margin: 80px 0;
    width: 600px;
    max-width: 600px;
    background-image: linear-gradient(to top,#28283e 0%,#52527b);
    border-radius: var(--radius);
    padding: 10px;
    margin:20px;
    text-align: left;
    color: var(--text4);
        >h5{
            padding: 20px 20px 0;
            margin-top: 0px;
            font-weight: bold;
        }
        >hr{
            width:95%;
        }
`;

export const FormWrapperCss = styled.form`
     width:100%;
     border-radius: var(--radius);
 `;

export const Fields = styled.div`
    width:100%;
    display: flex;
        @media (max-width: 775px) {
        display:block;
        };
`;

export const InputWrapper = styled.label`
	display: block;
    width: ${props => !props.full ? '50%' : '100%'};
    padding: 0 10px;
        @media (max-width: 775px) {
            width: 100%;
        };
`;

export const LabelCss = styled.label`
	display: flex;
	font-size: 1rem;
	margin: 20px 10px 10px;
    color: var(--text5);
    align-items: center;
    text-align:left;
        >span{
            border: 1px solid var(--color6);
            border-radius:4px;
            margin-left: 5px;
            transition: var(--transition);
            padding: 3px 5px;
            font-size: 12px;
            &:hover{
                background: var(--warning);
                border: 1px solid var(--warning);
                color: var(--text4);
            }
        }
`;

export const InputCss = styled.input`
    width:100%;
    height: 50px;
    padding: 6px 12px;
    border: 1px solid var(--color6);
    border-radius: var(--radius);
    background: var(--color7);
    color: var(--text5);
    transition: var(--transition) ease;
    box-shadow: 0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08)!important;
        &::placeholder { 
            color: var(--text2);
            font-size: 14px;
        }
        &:hover{
            border-color: var(--border);
        }
        &:focus{
            outline:none;
            border: 1px solid var(--color4);
        }
        &:-webkit-autofill{
            -webkit-text-fill-color: var(--text5);
            -webkit-box-shadow: 0 0 0px 1000px #fff inset;
            transition: background-color 5000s ease-in-out 0s; 
        }
`;

export const PswWrapper = styled.div`
   position:relative;
`;

export const EyeWrapper = styled.div`
   position:absolute;
   right: 15px;
   top: 45px;
   color: var(--text2);
   cursor: pointer;
`;

export const BtnWrapper = styled.div`
     width: 100%;
     display:flex;
     align-items: center;
     justify-content:flex-end;
 `;

export const Btn = styled.button`
     /* width: 25%; */
     height:50px;
     padding: 0 35px;
     display:flex;
     align-items: center;
     justify-content:center;
     border:none;
     /* color:var(--text5); */
     color:${props => !props.disabled ? 'var(--text5)' : 'var(--text2)'};
     border-radius: var(--radius);
     margin-top: 40px;
     margin-right:10px;
     margin-bottom:10px;
     cursor: pointer;
     /* transition: var(--transition) ease; */
     /* background: var(--color4); */
     background: ${props => !props.disabled ? 'var(--color4)' : 'var(--background2)'};
     outline:none;
     font-weight:bold;
     letter-spacing: 2px;
     transition: filter var(--transition) ease-in-out;
        >img{
            height: 25px;
            padding-right: 10px;
        }
        &:hover{
            filter: brightness(0.9);
            color:var(--text5);
        }
         &:active {
            -webkit-transform: translateY(3px);
            -moz-transform: translateY(3px);
            transform: translateY(3px);
            box-shadow: none;
        } 
 `;

// LOGO
export const UploadPozWrapper = styled.div`
    position: absolute;
    top: 40px;
    left: 50%;
    transform:translateX(50%);
`;

export const UploadWrapper = styled.div`
    position: relative;
    text-align: center;
    width: 50px;
    height: 50px;
    display: flex;
        >span{
            /* z-index: 0; */
            width: 50px;
            height: 50px;
            border-radius: 50px;
            cursor: pointer;
            color: var(--text4);
            font-size:20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: var(--hover);
            backdrop-filter: var(--blur);
        }
`;

export const DeleteBtn = styled.button`
    width: 50px;
    height: 50px;
    border-radius: 50px;
    border: none;
    cursor: pointer;
    color: var(--text4);
    font-size:20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--hover);
    backdrop-filter: var(--blur);
`;

export const UploadFileInput = styled.input`
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
        &::-webkit-file-upload-button{
            cursor: pointer;
        }
`;

export const LogoDisplay = styled.div`
    position: absolute;
    top: -70px;
    left: 50%;
    transform:translateX(-50%);
    height: 150px;
    width: 150px;
    border-radius: 100px;
    border: 10px solid var(--background2);
    display:flex;
    align-items:center;
    justify-content:center;
    overflow:hidden;
    background: var(--background3);
    color: var(--text4);
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    font-size: 50px;
        >h3{
            font-weight: bold;
        }
`;

export const LogoImg = styled.img`
    height: 100%;
    width: 100%;
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
`;

export const Hr = styled.hr`
    border: none;
    border-bottom: 1px solid var(--color8);
    width: 90%;
    margin: 10px auto;
`;

export const colourStyles = {
    control: styles => ({
        ...styles,
        backgroundColor: '#fff',
        borderRadius: '10px',
        minHeight: '50px',
        fontSize: '14px',
        paddingLeft: '4px',
        border: '1px solid var(--background1)',
        boxShadow: 'none',
    }),
    input: styles => ({ ...styles, fontSize: '14px' }),
    placeholder: styles => ({ ...styles, fontSize: '14px' }),
    singleValue: (styles, { data }) => ({ ...styles, fontSize: '14px', color: 'var(--text1)' }),
    multiValue: (styles, { data }) => ({ ...styles, fontSize: '14px', backgroundColor: 'white', color: 'var(--text1)' }),
};