import React from 'react';

// PACKAGES
import styled, { keyframes } from 'styled-components';


// const Shapes = styled.div`
//     height: 100vh;
//     width: 100%;
//     position: absolute;
//     top:0;
//     left:0;
//     z-index:1;
//     overflow: hidden;
//    >div:nth-child(1){
//         position: absolute;
//         z-index: 2;
//         top:-250px;
//         left:300px;
//         width: 400px;
//         height: 400px;
//         border-radius:53% 47% 50% 50% / 46% 52% 48% 54%;
//         background-image: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
//         box-shadow: -1px 20px 22px -17px rgb(0 0 0 / 75%);
//    }
//    >div:nth-child(2){
//         position: absolute;
//         z-index: 1;
//         top:-100px;
//         left:300px;
//         width:800px;
//         height:1000px;
//         border-radius:85% 15% 54% 46% / 75% 8% 92% 25%;
//         background-image: linear-gradient(to top, #cc208e 0%, #6713d2 100%);
//    }
//    >div:nth-child(3){
//         position: absolute;
//         z-index: 1;
//         top:200px;
//         right:-100px;
//         width:400px;
//         height:600px;
//         border-radius:87% 13% 63% 37% / 81% 14% 86% 19% ;
//         background-image: linear-gradient(to bottom, #f9d423 0%, #ff4e50 100%);
//         transform: rotate(-10deg);
//    }
//    >div:nth-child(4){
//         position: absolute;
//         bottom:-400px;
//         left:0px;
//         width: 600px;
//         height: 600px;
//         border-radius:50% 50% 50% 50% / 50% 50% 50% 50% ;
//         background-image: linear-gradient(-225deg,#06a760 18%,#2280a0 45%,#4596FB 100%);
// }
// >div:nth-child(5){
//         position: absolute;
//         bottom:300px;
//         left:-100px;
//         width: 400px;
//         height: 300px;
//         border-radius:36% 64% 26% 74% / 35% 73% 27% 65% ;
//         background-image: linear-gradient(to top, #cc208e 0%, #6713d2 100%);
//         /* transform: rotate(-10deg); */
// }
// >div:nth-child(6){
//         position: absolute;
//         top:30%;
//         left:55%;
//         width: 500px;
//         height: 500px;
//         border-radius:50% 50% 50% 50% / 50% 50% 50% 50% ;
//         background-image: linear-gradient(to right, #b8cbb8 0%, #b8cbb8 0%, #b465da 0%, #cf6cc9 33%, #ee609c 66%, #ee609c 100%);
//         transform: rotate(-10deg);
// }

//    }
// `;


const animate = keyframes`
   0%{
        transform: translateY(0px) rotate(0);
        opacity: 1;
        /* border-radius: 20px; */
       
    }
    100%{
        transform: translateY(1000px) rotate(720deg);
        opacity: 0;
        /* border-radius: 20px; */
    }
`;


const Shapes = styled.div`
    height: 100vh;
    width: 100%;
    position: absolute;
    top:0;
    left:0;
    z-index:1;
    overflow: hidden;
        >div{
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            /* list-style: none; */
            width: 20px;
            height: 20px;
            animation: ${animate} 25s linear infinite;
            /* bottom: -150px; */
            top: -150px;
                >i{
                    color:#fff;
                    filter: drop-shadow(2px 3px 2px #878787);
                }
        }
        >div:nth-child(1){
                left: 25%;
                width: 100px;
                height: 100px;
                border-radius: 20px;
                animation-delay: 0s;
                /* border-radius:53% 47% 50% 50% / 46% 52% 48% 54%; */
                /* background-image: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);\ */
                /* background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%); */
                background: rgba(255, 255, 255, 0.2);
                /* box-shadow: -1px 20px 22px -17px rgb(0 0 0 / 75%); */
                    >i{
                        /* color:#00d1f7; */
                        font-size: 70px;
                    }
        }
        >div:nth-child(2){
                left: 10%;
                width: 80px;
                height:80px;
                border-radius: 10px;
                animation-delay: 2s;
                animation-duration: 14s;
                /* border-radius:85% 15% 54% 46% / 75% 8% 92% 25%; */
                /* background-image: linear-gradient(to right, #b8cbb8 0%, #b8cbb8 0%, #b465da 0%, #cf6cc9 33%, #ee609c 66%, #ee609c 100%); */
                background: rgba(255, 255, 255, 0.2);
                    >i{
                        font-size: 50px;
                    }
        }
        >div:nth-child(3){
                left: 70%;
                width: 100px;
                height: 100px;
                border-radius: 20px;
                animation-delay: 4s;
                /* border-radius:87% 13% 63% 37% / 81% 14% 86% 19% ; */
                /* background-image: linear-gradient(to bottom, #f9d423 0%, #ff4e50 100%); */
                /* background-image: linear-gradient(to top, #dfe9f3 0%, white 100%); */
                background: rgba(255, 255, 255, 0.2);
                transform: rotate(-10deg);
                    >i{
                        /* color:#8dd23c; */
                        font-size: 60px;
                    }
        }
        >div:nth-child(4){
                left: 40%;
                width: 70px;
                height: 70px;
                border-radius: 10px;
                animation-delay: 0s;
                animation-duration: 18s;
                /* border-radius:50% 50% 50% 50% / 50% 50% 50% 50% ; */
                /* background-image: linear-gradient(-225deg,#06a760 18%,#2280a0 45%,#4596FB 100%); */
                background: rgba(255, 255, 255, 0.2);
                    >i{
                        font-size: 50px;
                    }
        }
        >div:nth-child(5){
                left: 65%;
                width: 80px;
                height: 80px;
                border-radius: 10px;
                animation-delay: 0s;
                /* border-radius:36% 64% 26% 74% / 35% 73% 27% 65% ; */
                /* background-image: linear-gradient(to top, #cc208e 0%, #6713d2 100%); */
                background: rgba(255, 255, 255, 0.2);
                    >i{
                        font-size: 50px;
                    }
        }
        >div:nth-child(6){
                left: 75%;
                width: 90px;
                height: 90px;
                border-radius: 20px;
                animation-delay: 3s;
                /* border-radius:50% 50% 50% 50% / 50% 50% 50% 50% ; */
                /* background-image: linear-gradient(to right, #b8cbb8 0%, #b8cbb8 0%, #b465da 0%, #cf6cc9 33%, #ee609c 66%, #ee609c 100%); */
                background: rgba(255, 255, 255, 0.2);
                transform: rotate(-10deg);
                    >i{
                        font-size: 50px;
                    }
        }
        >div:nth-child(7){
                left: 35%;
                width: 100px;
                height: 100px;
                border-radius: 20px;
                animation-delay: 7s;
                /* border-radius:50% 50% 50% 50% / 50% 50% 50% 50% ; */
                /* background-image: linear-gradient(to right, #b8cbb8 0%, #b8cbb8 0%, #b465da 0%, #cf6cc9 33%, #ee609c 66%, #ee609c 100%); */
                background: rgba(255, 255, 255, 0.2);
                transform: rotate(-10deg);
                    >i{
                        
                        font-size:70px;
                    }
        }
        >div:nth-child(8){
                left: 50%;
                width: 90px;
                height: 90px;
                border-radius: 10px;
                animation-delay: 15s;
                animation-duration: 45s;
                /* border-radius:50% 50% 50% 50% / 50% 50% 50% 50% ; */
                /* background-image: linear-gradient(to right, #b8cbb8 0%, #b8cbb8 0%, #b465da 0%, #cf6cc9 33%, #ee609c 66%, #ee609c 100%); */
                background: rgba(255, 255, 255, 0.2);
                transform: rotate(-10deg);
                    >i{
                        font-size: 50px;
                    }
        }
        >div:nth-child(9){
                left: 20%;
                width: 80px;
                height: 80px;
                border-radius: 10px;
                animation-delay: 2s;
                animation-duration: 35s;
                /* border-radius:50% 50% 50% 50% / 50% 50% 50% 50% ; */
                /* background-image: linear-gradient(to right, #b8cbb8 0%, #b8cbb8 0%, #b465da 0%, #cf6cc9 33%, #ee609c 66%, #ee609c 100%); */
                background: rgba(255, 255, 255, 0.2);
                transform: rotate(-10deg);
                    >i{
                        font-size: 50px;
                    }
        }
        >div:nth-child(10){
                left: 85%;
                width: 100px;
                height: 100px;
                border-radius: 20px;
                animation-delay: 0s;
                animation-duration: 11s;
                /* border-radius:50% 50% 50% 50% / 50% 50% 50% 50% ; */
                /* background-image: linear-gradient(to bottom, #f9d423 0%, #ff4e50 100%); */
                background: rgba(255, 255, 255, 0.2);
                transform: rotate(-10deg);
                    >i{
                        font-size: 80px;
                    }
        }
`;



const ShapesForLanding = () => {

    return (
        <Shapes>
            <div><i className="fab fa-sass"></i></div>
            <div><i className="fab fa-js-square"></i></div>
            <div><i className="fab fa-node-js"></i></div>
            <div><i className="fab fa-css3-alt"></i></div>
            <div><i className="fab fa-bootstrap"></i></div>
            <div><i className="fab fa-sass"></i></div>
            <div><i className="fab fa-react"></i></div>
            <div><i className="fab fa-figma"></i></div>
            <div><i className="fab fa-css3-alt"></i></div>
            <div><i className="fab fa-js-square"></i></div>
            <div></div>
        </Shapes>
    );
};

export default ShapesForLanding;
