import axios from 'axios';
axios.defaults.withCredentials = true;

export const reciveTeammates = (flag, reset) => (dispatch, getState) => {

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/reciveTeammate`)
        .then((res) => {
            // console.log("RECIVE_TEAMMATES", res.data)
            dispatch({
                type: 'RECIVED_TEAMMATES',
                payload: res.data
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const addNewTeammate = (access) => (dispatch, getState) => {
    // console.log('ADD_TEAMMATE', access);
    const profile = getState().auth.user;

    const body = {
        firstName: access.fname,
        lastName: access.lname,
        email: access.email,
        payStatus: profile.Stripe[0].payStatus,
        stripeId: profile.Stripe[0].stripeId
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/addTeammate`, body)
        .then((res) => {
            // console.log("ADD_TEAMMATE", res)
            dispatch({
                type: 'ADD_TEAMMATE',
                payload: res.data,
            });
            dispatch(notifHandler('success', res.data.message));
            dispatch(reciveTeammates())
        })
        .catch((err) => {
            console.log(err);
            dispatch(notifHandler('error', err.response.data.message));
        });
};

export const editTeammate = (access) => (dispatch, getState) => {
    // console.log('EDIT_TEAMMATE', more, poster, id, role)

    const body = {
        firstName: access.fname,
        lastName: access.lname,
        email: access.email,
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/editTeammate`, body)
        .then((res) => {
            // console.log("EDIT_TEAMMATE", res)
            dispatch({
                type: 'FETCHED_USER',
                payload: res.data,
            });
            // dispatch(notifHandler('success', res.data.message));
            dispatch(reciveTeammates());
        })
        .catch((err) => {
            console.log(err);
            dispatch(notifHandler('error', err.response.data.message));
        });
};

export const changeTeammateProfilePicture = (pic) => (dispatch, getState) => {

    const body = new FormData();
    body.append('file', pic);

    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/changeTeammateProfilePicture`, body, config)
        .then((res) => {
            // console.log("CHECK USER", res)
            dispatch({
                type: 'FETCHED_USER',
                payload: res.data,
            });
            // dispatch(fetchUser());
        })
        .catch((err) => {
            console.log(err);
        });
};

export const deleteTeammateProfilePicture = () => (dispatch, getState) => {

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/deleteTeammateProfilePicture`)
        .then((res) => {
            // console.log("CHECK USER", res)
            dispatch({
                type: 'FETCHED_USER',
                payload: res.data,
            });
            // dispatch(fetchUser());
        })
        .catch((err) => {
            console.log(err);
        });
};

export const deleteTeammate = (id) => (dispatch, getState) => {
    // console.log('DELETE_TEAMMATE', id)

    const body = {
        idTeammate: id,
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/deleteTeammate`, body)
        .then((res) => {
            // console.log("DELETE_TEAMMATE", res)
            // dispatch({
            //     type: 'DELETE_TEAMMATE',
            //     payload: res.data,
            // });
            dispatch(notifHandler('success', res.data.message));
            dispatch(reciveTeammates());
        })
        .catch((err) => {
            console.log(err);
            // dispatch(notifHandler('error', err.response.data.message));
        });
};

export const deleteOldTeam = () => (dispatch, getState) => {
    // console.log('DELETE_OLD_TEAM', id)

    const body = {
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/deleteOldTeam`, body)
        .then((res) => {
            // console.log("DELETE_OLD_TEAM", res)
            // dispatch({
            //     type: 'DELETE_OLD_TEAM',
            //     payload: res.data,
            // });
            // dispatch(notifHandler('success', res.data.message));
            dispatch(reciveTeammates());
        })
        .catch((err) => {
            console.log(err);
            // dispatch(notifHandler('error', err.response.data.message));
        });
};

export const loginTeammate = (access) => (dispatch) => {
    dispatch({ type: 'LOADING_SIGNIN' });

    const loginCredentials = {
        email: access.email,
        //   psw: access.psw,
    };

    // console.log('LOGIN', loginCredentials)
    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/loginTeammate`, loginCredentials)
        .then((res) => {
            // console.log("LOGIN RES", res);
            dispatch({
                type: 'LOGIN_SUCCESFUL',
                payload: res.data.user,
            });
            // dispatch(fetchUser());
            // dispatch(notifHandler('success', 'Successfully logged in!'));
        })
        .catch((err) => {
            // console.log("LOGIN ERR", err.response.data.message);
            // console.log("LOGIN ERR", err);
            if (err) {
                // console.log("LOGIN ERR", err.response.data.message);
                dispatch({
                    type: 'LOGIN_ERROR',
                    payload: err.response.data.message,
                });
                //   setTimeout(() => {
                //     dispatch({ type: 'LOADING_ERROR' });
                //   }, 50000);
                if (err.response.data.message === 'This e-mail is not activated!') {
                    dispatch(notifHandler('warning', err.response.data.message));
                } else {
                    dispatch(notifHandler('error', err.response.data.message));
                }
            }
        });
};

export const teammateNotif = () => (dispatch) => {
    dispatch(notifHandler('warning', 'You need to create your own personal account!'));
};

const notifHandler = (flag, msg) => (dispatch) => {
    const obj = {
        flag: flag,
        msg: msg,
    };
    dispatch({
        type: 'NOTIF_HANDLER_TEAMMATE',
        payload: obj,
    });
    setTimeout(() => {
        dispatch({
            type: 'NOTIF_HANDLER_TEAMMATE',
            payload: undefined,
        });
    }, 3000);
};