import React from 'react';
import { Link } from 'react-router-dom';

// PACKAGES
import styled from 'styled-components';

// STYLE
import { WrapperRightSide } from '../ShareStyle/ShareStyle';

const RightSide = styled.div`
    width: 100%;
    height: calc(100vh - 185px);
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
      >h1{
        font-size: 100px;
        color: var(--text4);
      }
      >h5{
        font-size: var(--fontXL);
        color: var(--text2)
      }
`;

const BtnLink = styled(Link)`
    width: 250px;
    height:50px;
    display:flex;
    align-items: center;
    justify-content:center;
    color:var(--text5);
    font-weight:bold;
    letter-spacing:2px;
    border-radius: var(--radius);
    margin: 50px auto;
    cursor: pointer;
    border: none;
    outline:none;
    text-decoration: none;
    transition: filter var(--transition) ease-in-out;
    /* background: linear-gradient(60deg,#f3a81f,#e08b2a); */
    background-color: ${props => !props.less ? 'var(--color4)' : 'var(--color6)'};
    box-shadow:${props => !props.less ?
      '0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px #da13f5ad'
      :
      '0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08)'
    };
        &:active{
                transform:translateY(2px) ;
        }
        >a{
          
          color: var(--text5);
        }
`;


const Canceled = () => {
  return (
    <WrapperRightSide>
      <RightSide>
        <h1>Ops</h1>
        <h5>Something went wrong and the payment was canceled!</h5>
        <BtnLink to='/dashboard/price'>Let's try again</BtnLink>
      </RightSide>
    </WrapperRightSide>
  );
};

export default Canceled;
