import React, { Component } from 'react';
import './Terms.css';



class Licence extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }


    render() {
        return (
            <div className="terms">

                <div className="terms-title"><h1>License Agreement</h1>
                    <h6>Last updated: Jun 19th, 2020</h6>
                </div><br></br>

                <hr className="terms-hr"></hr><br></br>

                <div className="terms-subtitle"><h4>General</h4></div>
                <p>By using any Item from <span className="csyes-span">CSYES<span className="terms-span">!</span> Team</span>, you agree that this End User User License Agreement (EULA) is a legally binding and valid contract and agree to be bound by it. You agree to abide by the intellectual property laws and all of the terms and conditions of this Agreement.</p>

                <div className="terms-subtitle"><h4>License Period</h4></div>
                <p>License is Unlimited for the entire contractual period. After the expiration of the contract, if you do not want the renewal, you can use our products only for another 1 week.</p>

                <div className="terms-subtitle"><h4>Use of the License</h4></div>
                <p>Your license to use any of our items is limited to the contractual period. You will not allow others to use, copy or evaluate copies of our articles.</p>
                <p>You can create and deploy an unlimited number of domains as long as you have an active subscription. After the expiration of the contract, if you do not want the renewal, you can use our products only for another 1 week </p>
                <p>If you choose, for any reason, to close an active plan, you can do as follows:<br></br> - within the first 30 days with the possibility of a refund<br></br> - after the first 30 days according to the contractual terms</p>
            
                {/* <div className="terms-subtitle"><h4>Freelancer License</h4></div>
                <p>You are licensed to use the Item to create Unlimited End Products for yourself or for one client, and the End Products can be distributed for Free.</p>
                <p>An End Product is one of the following things, both requiring an application of skill and effort.</p>
                <p>For an Item that is a template, the End Product is a customised implementation of the Item. For example: the item is a website template and the end product is the final website customised with your content.</p>
                <p>For other types of Item, an End Product is a work that incorporates the Item as well as other things, so that it is larger in scope and different in nature than the Item. For example: the item is an UI Kit and the end product is a website.</p>
                <p>You can create one End Product for a client, and you can transfer that single End Product to your client for any fee. This license is then transferred to your client.</p>
                <p>You can make any number of copies of the single End Product, as long as the End Product is distributed for Free.</p>
                <p>You can modify or manipulate the Item. You can combine the Item with other works and make a derivative work from it. The resulting works are subject to the terms of this license. You can do these things as long as the End Product you then create is one that’s permitted under clause 3.</p>
                <p>You can’t Sell the End Product, except to one client. (If you or your client want to Sell the End Product, you will need the Extended License.)</p>
                <p>You can’t re-distribute the Item as stock, in a tool or template, or with source files. You can’t do this with an Item either on its own or bundled with other items, and even if you modify the Item. You can’t re-distribute or make available the Item as-is or with superficial modifications.</p>
                <p>For example: You can’t purchase an HTML template, convert it to a WordPress theme and sell or give it to more than one client. You can’t license an item and then make it available as-is on your website for your users to download.</p>
                <p>You can’t use the Item in any application allowing an end user to customise a digital or physical product to their specific needs, such as an “on demand”, “made to order” or “build it yourself” application. You can use the Item in this way only if you purchase a separate license for each final product incorporating the Item that is created using the application. For example: You can’t purchase an HTML template, convert it to a WordPress theme and sell or give it to more than one client. You can’t license an item and then make it available as-is on your website for your users to download.</p>
                <p>You must not permit an end user of the End Product to extract the Item and use it separately from the End Product.</p>
                <p>You can’t use an Item in a logo, trademark, or service mark.</p>
                <p>For some Items, a component of the Item will be sourced from elsewhere and different license terms may apply to the component, such as someone else’s license or an open source or creative commons license. If so, you will find the component identified in the Item’s description page or in the Item’s downloaded files. The other license will apply to that component instead of this license. This license will apply to the rest of the Item.</p>
                <p>For example: A theme might contain images licensed under a Creative Commons CCBY license. The CCBY license applies to those specific images. This license applies to the rest of the theme.</p>
                <p>You can only use the Item for lawful purposes. Also, if an Item contains an image of a person, even if the Item is model-released you can’t use it in a way that creates a fake identity, implies personal endorsement of a product by the person, or in a way that is defamatory, obscene or demeaning, or in connection with sensitive subjects.</p>
                <p>This license applies in conjunction with the <span className="csyes-span">CSYES<span className="terms-span">!</span> Team</span> Terms and Conditions. If there is an inconsistency between this license and the Terms and Conditions, this license will apply to the extent necessary to resolve the inconsistency.</p>
                <p>This license can be terminated if you breach it. If that happens, you must stop making copies of or distributing the End Product until you remove the Item from it.</p>
                <p><span className="csyes-span">CSYES<span className="terms-span">!</span> Team</span> retains ownership of the Item but grants you the license on these terms. This license is between <span className="csyes-span">CSYES<span className="terms-span">!</span> Team</span> and you.</p>


                <div className="terms-subtitle"><h4>Startup License</h4></div>
                <p>The Startup License grants you, the purchaser, an ongoing, non-exclusive, worldwide license to make use of the digital work (*Item*) you have selected. Read the rest of this license for the details that apply to your use of the Item.</p>
                <p>You are licensed to use the Item to create multiple End Products for yourself or for clients, and the End Product may be Sold.</p>
                <p>An End Product is one of the following things, both requiring an application of skill and effort.</p>
                <p>For an Item that is a template, the End Product is a customised implementation of the Item.</p>
                <p>For example: the item is a website template and the end product is the final website customised with your content.</p>
                <p>For other types of Item, an End Product is something that incorporates the Item as well as other things, so that it is larger in scope and different in nature than the Item.</p>
                <p>For example: the item is an UI Kit and the end product is a website.</p>
                <p>You can create the End Product for a client, and this license is then transferred from you to your client.</p>
                <p>You can Sell and make any number of copies of the End Product.</p>
                <p>You can modify or manipulate the Item. You can combine the Item with other works and make a derivative work from it. The resulting works are subject to the terms of this license. You can do these things as long as the End Product you then create is one that’s permitted under clause 3.</p>
                <p>You can’t re-distribute the Item as stock, in a tool or template, or with source files. You can’t do this with an Item either on its own or bundled with other items. You can’t re-distribute or make available the Item as-is or with superficial modifications.</p>
                <p>For example: You can’t license an item and then make it available as-is on your website for your users to download.</p>
                <p>You can’t use the Item in any application allowing an end user to customise a digital or physical product to their specific needs, such as an “on demand”, “made to order” or “build it yourself” application. You can use the Item in this way only if you purchase a separate license for each final product incorporating the Item that is created using the application.</p>
                <p>For example: you can't create a website builder.</p>
                <p>You must not permit an end user of the End Product to extract the Item and use it separately from the End Product.</p>
                <p>You can’t use an Item in a logo, trademark, or service mark.</p>
                <p>For some Items, a component of the Item will be sourced from elsewhere and different license terms may apply to the component, such as someone else’s license or an open source or creative commons license. If so, you will find the component identified in the Item’s description page or in the Item’s downloaded files. The other license will apply to that component instead of this license. This license will apply to the rest of the Item.</p>
                <p>For example: A theme might contain images licensed under a Creative Commons CCBY license. The CCBY license applies to those specific images. This license applies to the rest of the theme.</p>
                <p>You can only use the Item for lawful purposes. Also, if an Item contains an image of a person, even if the Item is model-released you can’t use it in a way that creates a fake identity, implies personal endorsement of a product by the person, or in a way that is defamatory, obscene or demeaning, or in connection with sensitive subjects.</p>
                <p>This license applies in conjunction with the <span className="csyes-span">CSYES<span className="terms-span">!</span> Team</span> Terms and Conditions. If there is an inconsistency between this license and the Terms and Conditions, this license will apply to the extent necessary to resolve the inconsistency.</p>
                <p>This license can be terminated if you breach it. If that happens, you must stop making copies of or distributing the End Product until you remove the Item from it.</p>
                <p><span className="csyes-span">CSYES<span className="terms-span">!</span> Team</span> retains ownership of the Item but grants you the license on these terms. This license is between <span className="csyes-span">CSYES<span className="terms-span">!</span> Team</span> and you.</p>
                <p>The Startup License allows you to create only one End Product you want and deploy it on only one domain like endproduct.com.</p>
                <p>This license allows you access to the product's designer files.</p>


                <div className="terms-subtitle"><h4>Company License</h4></div>
                <p>The Company License grants you, the purchaser, an ongoing, non-exclusive, worldwide license to make use of the digital work (Item) you have selected. Read the rest of this license for the details that apply to your use of the Item.</p>
                <p>You are licensed to use the Item to create multiple End Products for yourself or for clients, and the End Product may be Sold.</p>
                <p>An End Product is one of the following things, both requiring an application of skill and effort.</p>
                <p>For an Item that is a template, the End Product is a customised implementation of the Item.</p>
                <p>For example: the item is a website template and the end product is the final website customised with your content.</p>
                <p>For other types of Item, an End Product is something that incorporates the Item as well as other things, so that it is larger in scope and different in nature than the Item.</p>
                <p>For example: the item is an UI Kit and the end product is a website.</p>
                <p>You can create the End Product for a client, and this license is then transferred from you to your client.</p>
                <p>You can Sell and make any number of copies of the End Product.</p>
                <p>You can modify or manipulate the Item. You can combine the Item with other works and make a derivative work from it. The resulting works are subject to the terms of this license. You can do these things as long as the End Product you then create is one that’s permitted under clause 3.</p>
                <p>This license is a “multi-use” license and not a “single application” license, which means that you can use the Item to create more than one unique End Product.</p>
                <p>You can’t re-distribute the Item as stock, in a tool or template, or with source files. You can’t do this with an Item either on its own or bundled with other items. You can’t re-distribute or make available the Item as-is or with superficial modifications.</p>
                <p>For example: You can’t license an item and then make it available as-is on your website for your users to download.</p>
                <p>You can’t use the Item in any application allowing an end user to customise a digital or physical product to their specific needs, such as an “on demand”, “made to order” or “build it yourself” application. You can use the Item in this way only if you purchase a separate license for each final product incorporating the Item that is created using the application.</p>
                <p>For example: you can't create a website builder.</p>
                <p>You must not permit an end user of the End Product to extract the Item and use it separately from the End Product.</p>
                <p>You can’t use an Item in a logo, trademark, or service mark.</p>
                <p>For some Items, a component of the Item will be sourced from elsewhere and different license terms may apply to the component, such as someone else’s license or an open source or creative commons license. If so, you will find the component identified in the Item’s description page or in the Item’s downloaded files. The other license will apply to that component instead of this license. This license will apply to the rest of the Item.</p>
                <p>For example: A theme might contain images licensed under a Creative Commons CCBY license. The CCBY license applies to those specific images. This license applies to the rest of the theme.</p>
                <p>You can only use the Item for lawful purposes. Also, if an Item contains an image of a person, even if the Item is model-released you can’t use it in a way that creates a fake identity, implies personal endorsement of a product by the person, or in a way that is defamatory, obscene or demeaning, or in connection with sensitive subjects.</p>
                <p>This license applies in conjunction with the <span className="csyes-span">CSYES<span className="terms-span">!</span> Team</span> Terms and Conditions. If there is an inconsistency between this license and the Terms and Conditions, this license will apply to the extent necessary to resolve the inconsistency.</p>
                <p>This license can be terminated if you breach it. If that happens, you must stop making copies of or distributing the End Product until you remove the Item from it.</p>
                <p><span className="csyes-span">CSYES<span className="terms-span">!</span> Team</span> retains ownership of the Item but grants you the license on these terms. This license is between <span className="csyes-span">CSYES<span className="terms-span">!</span> Team</span> and you.</p>
                <p>The Company License allows you to create unlimited End Products you want and deploy them on how many domains like endproduct1.com, endproduct2.com, endproductx.com.</p>
                <p>This license allows you access to the product's designer files.</p>


                <div className="terms-subtitle"><h4>Enterprise License</h4></div>

                <p>The Enterprise License grants you, the purchaser, an ongoing, non-exclusive, worldwide license to make use of the digital work (Item) you have selected. Read the rest of this license for the details that apply to your use of the Item.</p>
                <p>You are licensed to use the Item to create multiple End Products for yourself or for clients, and the End Product may be Sold.</p>
                <p>An End Product is one of the following things, both requiring an application of skill and effort.</p>
                <p>For an Item that is a template, the End Product is a customised implementation of the Item.</p>
                <p>For example: the item is a website template and the end product is the final website customised with your content.</p>
                <p>For other types of Item, an End Product is something that incorporates the Item as well as other things, so that it is larger in scope and different in nature than the Item.</p>
                <p>For example: the item is an UI Kit and the end product is a website.</p>
                <p>You can create the End Product for a client, and this license is then transferred from you to your client.</p>
                <p>You can Sell and make any number of copies of the End Product.</p>
                <p>You can modify or manipulate the Item. You can combine the Item with other works and make a derivative work from it. The resulting works are subject to the terms of this license. You can do these things as long as the End Product you then create is one that’s permitted under clause 3.</p>
                <p>This license is a “multi-use” license and not a “single application” license, which means that you can use the Item to create more than one unique End Product.</p>
                <p>You can’t re-distribute the Item as stock, in a tool or template, or with source files. You can’t do this with an Item either on its own or bundled with other items. You can’t re-distribute or make available the Item as-is or with superficial modifications.</p>
                <p>For example: You can’t license an item and then make it available as-is on your website for your users to download.</p>
                <p>You can’t use the Item in any application allowing an end user to customise a digital or physical product to their specific needs, such as an “on demand”, “made to order” or “build it yourself” application. You can use the Item in this way only if you purchase a separate license for each final product incorporating the Item that is created using the application.</p>
                <p>For example: you can't create a website builder.</p>
                <p>You must not permit an end user of the End Product to extract the Item and use it separately from the End Product.</p>
                <p>You can’t use an Item in a logo, trademark, or service mark.</p>
                <p>For some Items, a component of the Item will be sourced from elsewhere and different license terms may apply to the component, such as someone else’s license or an open source or creative commons license. If so, you will find the component identified in the Item’s description page or in the Item’s downloaded files. The other license will apply to that component instead of this license. This license will apply to the rest of the Item.</p>
                <p>For example: A theme might contain images licensed under a Creative Commons CCBY license. The CCBY license applies to those specific images. This license applies to the rest of the theme.</p>
                <p>You can only use the Item for lawful purposes. Also, if an Item contains an image of a person, even if the Item is model-released you can’t use it in a way that creates a fake identity, implies personal endorsement of a product by the person, or in a way that is defamatory, obscene or demeaning, or in connection with sensitive subjects.</p>
                <p>This license applies in conjunction with the <span className="csyes-span">CSYES<span className="terms-span">!</span> Team</span> Terms and Conditions. If there is an inconsistency between this license and the Terms and Conditions, this license will apply to the extent necessary to resolve the inconsistency.</p>
                <p>This license can be terminated if you breach it. If that happens, you must stop making copies of or distributing the End Product until you remove the Item from it.</p>
                <p><span className="csyes-span">CSYES<span className="terms-span">!</span> Team</span> retains ownership of the Item but grants you the license on these terms. This license is between <span className="csyes-span">CSYES<span className="terms-span">!</span> Team</span> and you.</p>
                <p>The Enterprise License allows you to create unlimited End Products you want and deploy them on how many domains like endproduct1.com, endproduct2.com, endproductx.com.</p>
                <p>This license allows you access to the product's designer files.</p> */}


            </div>
        )
    }
}


export default Licence;