const initState = {
    handleSideBar:false
};

const stripeReducer = (state = initState, action) => {
    switch (action.type) {
        case 'STRIPE_SETUP':
          return {
            ...state,
            stripeSetup: action.payload,
          };
       
        default:
          return state;
      }
    };
    
export default stripeReducer;