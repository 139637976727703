import React, { useEffect, useState } from 'react';
// import ReactDom from 'react-dom';

// PACKEGES
import styled from 'styled-components';
import Select from 'react-select';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { addPosterAndContent, reciveAllContent } from '../../../../Store/Actions/contentActions';

// SHARE STYLE
import {
    ModalWrapper,
    DialogBox,
    H3,
    Hr,
    LabelCss,
    InputCss,
    Fields,
    InputWrapper,
    BtnWrapper,
    Btn,
    AddNewBtn,
    // ActionBtn,
    colourStyles,
    UploadPozWrapper,
    UploadWrapper,
    UploadFileInput,
} from './ModalStyle';


const ActionBtn = styled.div`
    /* position: fixed;
    bottom: 70px;
    right: 15px;
    z-index: 1; */
    width: 50px;
    height:50px;
    margin:5px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: var(--background4);
    backdrop-filter: blur(10px);
    /* box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%); */
        >i{
            color:  var(--text5);
        }
        &:hover{
                >i{
                    color: ${props => props.color};
                    transition:color var(--transition) ease-in-out;
                }
           }
`;


const Modal = ({ props, emp, reset }) => {
    const dispatch = useDispatch();
    const cont = useSelector((state) => state.content.allContent);
    const [openModal, setOpenModal] = useState(false);
    const [more, setMore] = useState({
        tech: '',
        theme: '',
        componentName: '',
        link: '',
        category: '',
        newcategory: '',
        keywords: '',
        poster: '',
    });
    const [newCat, setNewCat] = useState(false);
    const [options, setOptions] = useState([]);
    const [imgName, setImgName] = useState()
    const [imagePreview, setImagePreview] = useState();

    useEffect(() => {
        dispatch(reciveAllContent())
    }, [dispatch])

    useEffect(() => {
        if (cont !== undefined) {
            let arr = [];
            let createOptions = [];

            function capitalizeFirstLetter(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            };

            for (let i = 0; i < cont.length; i++) {
                arr.push(cont[i].Category);
            };

            let uniq = [...new Set(arr)].sort();

            uniq.forEach((op) => createOptions.push({ value: op, label: capitalizeFirstLetter(op) }));

            setOptions(createOptions);

            // console.log('FMM',createOptions);
        }
    }, [cont]);


    // console.log(options)

    // useEffect(() => {
    //     let arrOpt = [];
    //     function capitalizeFirstLetter(string) {
    //         return string.charAt(0).toUpperCase() + string.slice(1);
    //     }

    //     // const arrSup = props.core.[props.id].supervisors
    //     const arrCont = cont
    //     for (let i = 0; i < arrSup.length; i++) {
    //         const add = {
    //             label: capitalizeFirstLetter(arrSup[i].Surname) + ' ' + capitalizeFirstLetter(arrSup[i].Name),
    //             value: arrSup[i].Surname,
    //             idSupervisor: arrSup[i].idSupervisor
    //         }
    //         arrOpt.push(add)
    //     }
    //     setSupOpt(arrOpt)
    // }, [props.core, props.id]);

    const handleClose = (e) => {
        e.preventDefault();
        setOpenModal(false);
        setMore({
            ...more,
            tech: '',
            theme: '',
            componentName: '',
            link: '',
            category: '',
            newcategory: '',
            keywords: '',
            madeWith: '',
            poster: '',
        })
        setImgName();
        setImagePreview();
        setNewCat(false);
    };

    const handleChange = evt => {
        const name = evt.target.name;
        const value = evt.target.value;
        setMore({
            ...more,
            [name]: value
        });
    };

    const handleFile = (e) => {
        const image = e.target.files[0];
        if (image !== undefined) {
            setImgName(image.name);
        };
        // DISPLAY THE IMG/FILE
        const reader = new FileReader();
        reader.onloadend = () => {
            if (reader.readyState === 2) {
                setImagePreview(reader.result);
            }
        };
        if (image !== undefined) {
            reader.readAsDataURL(image);
        };
        // setTimeout(() => {
        //     // dispatch(changePicture(image));
        // }, 2000);
        const data = new FormData()
        data.append('file', image)
        setMore({
            ...more,
            poster: data,
        });
        // console.log(data);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(addPosterAndContent(more));
        handleClose(e);
    };



    return (
        <>
            <ActionBtn onClick={() => setOpenModal(!openModal)} color='var(--success)'>
                <i className="fas fa-plus"></i>
            </ActionBtn>

            {openModal &&
                <>
                    <ModalWrapper onClick={(e) => handleClose(e)} />

                    <DialogBox>
                        <H3>Add content</H3>
                        <Hr></Hr>


                        <form onSubmit={handleSubmit}>
                            <Fields>
                                <InputWrapper smallR>
                                    <LabelCss htmlFor='tech'>Technology used</LabelCss>
                                    <InputCss
                                        placeholder='eg. React, Vue, etc.'
                                        type='text'
                                        name='tech'
                                        id='tech'
                                        value={more.tech}
                                        onChange={handleChange}
                                    // onChange={(e) =>
                                    //     setFreeDays({
                                    //         ...freeDays,
                                    //         nr: e.target.value
                                    //     })
                                    // }
                                    ></InputCss>
                                </InputWrapper>

                                <InputWrapper smallL>
                                    <LabelCss htmlFor='theme'>Theme name</LabelCss>
                                    <InputCss
                                        placeholder='eg. Classic, Stylish'
                                        type='text'
                                        name='theme'
                                        id='theme'
                                        value={more.theme}
                                        onChange={handleChange}
                                    // value={freeDays.nr}
                                    // onChange={(e) =>
                                    //     setFreeDays({
                                    //         ...freeDays,
                                    //         nr: e.target.value
                                    //     })
                                    // }
                                    ></InputCss>
                                </InputWrapper>
                            </Fields>


                            <LabelCss htmlFor='componentName'>Component name</LabelCss>
                            <InputCss
                                placeholder='eg. Classic textarea, Grouped Buttons'
                                type='text'
                                name='componentName'
                                id='componentName'
                                value={more.componentName}
                                onChange={handleChange}
                            // value={freeDays.nr}
                            // onChange={(e) =>
                            //     setFreeDays({
                            //         ...freeDays,
                            //         nr: e.target.value
                            //     })
                            // }
                            ></InputCss>

                            <LabelCss htmlFor='link'>Link to code</LabelCss>
                            <InputCss
                                placeholder='Put the link here'
                                type='text'
                                name='link'
                                id='link'
                                value={more.link}
                                onChange={handleChange}
                            // value={freeDays.nr}
                            // onChange={(e) =>
                            //     setFreeDays({
                            //         ...freeDays,
                            //         nr: e.target.value
                            //     })
                            // }
                            ></InputCss>


                            <LabelCss htmlFor='category'>Category
                                <AddNewBtn onClick={() => setNewCat(!newCat)}>
                                    {newCat === false ?
                                        <i className="fas fa-plus-circle"></i>
                                        :
                                        <i className="fas fa-times-circle"></i>
                                    }
                                </AddNewBtn>
                            </LabelCss>
                            {newCat === false ?
                                <Select
                                    isMulti={false}
                                    isClearable={true}
                                    isSearchable={true}
                                    closeMenuOnSelect={true}
                                    // value={supervisor.serv}
                                    onChange={(e) =>
                                        setMore({
                                            ...more,
                                            category: e === null ? '' : e.value,
                                        })
                                    }
                                    name='category'
                                    id='category'
                                    options={options}
                                    styles={colourStyles}
                                    placeholder='eg. Buttons, Avatars, Alerts'
                                />
                                :
                                <InputCss
                                    placeholder='Add new category'
                                    type='text'
                                    name='category'
                                    id='category'
                                    value={more.category}
                                    onChange={handleChange}
                                ></InputCss>
                            }

                            <Fields>
                                <InputWrapper smallR>
                                    <LabelCss htmlFor='keywords'>Keywords</LabelCss>
                                    <InputCss
                                        placeholder='Keywords'
                                        type='text'
                                        name='keywords'
                                        id='keywords'
                                        value={more.keywords}
                                        onChange={handleChange}
                                    // value={freeDays.nr}
                                    // onChange={(e) =>
                                    //     setFreeDays({
                                    //         ...freeDays,
                                    //         nr: e.target.value
                                    //     })
                                    // }
                                    ></InputCss>
                                </InputWrapper>
                                <InputWrapper smallL>
                                    <LabelCss htmlFor='madeWith'>Made with</LabelCss>
                                    <InputCss
                                        placeholder='Made with'
                                        type='text'
                                        name='madeWith'
                                        id='madeWith'
                                        value={more.madeWith}
                                        onChange={handleChange}
                                    // value={freeDays.nr}
                                    // onChange={(e) =>
                                    //     setFreeDays({
                                    //         ...freeDays,
                                    //         nr: e.target.value
                                    //     })
                                    // }
                                    ></InputCss>
                                </InputWrapper>
                            </Fields>

                            <LabelCss htmlFor='upload'>Add poster</LabelCss>
                            <UploadPozWrapper>
                                <UploadWrapper>
                                    <h4>{imgName}</h4>
                                    {imagePreview === undefined ?
                                        <span ><i className="fas fa-upload"></i></span>
                                        :
                                        <div>
                                            <img src={imagePreview} alt=""></img>
                                        </div>
                                    }

                                    <UploadFileInput
                                        type="file"
                                        name="upload"
                                        id="upload"
                                        placeholder="Upload File"
                                        onChange={(e) => handleFile(e)} />
                                </UploadWrapper>
                            </UploadPozWrapper>

                            <BtnWrapper>
                                <Btn disable onClick={handleClose}>Close</Btn>
                                <Btn type='submit'>Add</Btn>
                            </BtnWrapper>
                        </form>

                    </DialogBox>

                </>
            }

        </>

    );
}
export default Modal;