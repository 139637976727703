import React, { useState, useRef, useEffect } from 'react';
import { Link } from "react-router-dom";

// PACKAGES
import styled from 'styled-components';

// ASSETS
// import logo from '../../Assets/img/logoPPi.png';

// COMPONENTS
import HamburgerMenu from './NavBarComponents/Ham';
// import Refresh from './Components/Refresh';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from '../../../Store/Actions/authActions';
import { openSearch } from '../../../Store/Actions/otherActions';
// import { fetchProfile } from '../../Store/Actions/dashboardActions';

const NavBarWrapperCss = styled.div`
    position: fixed;
    top:0;
    z-index: 2;
    width: 100%;
    background: var(--background5);
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ffffff0d;
    /* box-shadow: 0 19px 26px -13px rgb(228 228 228 / 75%);
    -webkit-box-shadow: 0 19px 26px -13px rgb(228 228 228 / 75%); */
`;

const Nav = styled.div`
    /* width: 100%; */
    width: calc(100% - 250px);
    margin-left: 250px;
    height: 70px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
        @media (max-width: 775px) {
                width:100%;
                margin-left:0px;
            };
`;

// const LogoNavBar = styled.img`
//     position: absolute;
//     top:50%;
//     transform:translate(0,-50%);
//     width: 150px;
//     margin-left: 25px;
//     @media (max-width: 775px) {
//         left: 50%;
//         transform:translate(-50%, -50%);
//         margin-left: 0px;
//     };
// `;


// const List = styled.li`
//     color: var(--color3);
//     @media (max-width: 775px) {
//         display: none;
//     };
// `;

// const LinkCss = styled(Link)`
//     margin-right: 20px;
//     color: var(--text4);
//     text-decoration: none;
//     padding-bottom:${props => props.active ? '3px' : '0'};
//     border-bottom:${props => props.active ? '2.5px solid var(--color3)' : '0'};
//     transition: var(--transition) ease;
//     &:hover {
//         color: var(--color1);
//         padding-bottom: 3px;
//         border-bottom: 2.5px solid var(--color1);
//     }
// `;

// const Anchor = styled.a`
//     margin-right: 20px;
//     color: var(--text4);
//     text-decoration: none;
//     transition: var(--transition) ease;
//     &:hover {
//         color: var(--color1);
//         padding-bottom: 3px;
//         border-bottom: 2.5px solid var(--color1);
//     }
// `;

// LOGO
const ProfileWrapper = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    transition: var(--transition) ease;
    cursor: pointer;
        &:hover{
            >h4{
                transition: var(--transition) ease-in-out;
                color: var(--text3);      
            }    
        }
`;

const LogoDisplay = styled.div`
    position:relative;
    height: 50px;
    width: 50px;
    border-radius: 100px;
    /* display:flex;
    align-items:center;
    justify-content:center; */
    overflow:hidden;
    /* background: var(--background3); */
    color: var(--text4);
    margin-left: 5px;
    /* background-image: linear-gradient( 45deg, #8eff5f 5%, #55f94a 15%, #38f44d 30%, #51e771 45%, #2dd972 70%, #2acc7a 80%, #2eb0c3 95% ); */
    /* background-image: linear-gradient( 45deg,#fa5fff 5%,#f94ae3 15%,#ce38f4 30%,#be51e7 45%,#6f2dd9 70%,#432acc 80%,#3e2ec3 95% ); */
    background: #7F00FF;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #E100FF, #7F00FF);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #E100FF, #7F00FF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background-image: linear-gradient(to bottom left,#da13f5,#ba54f5,#da13f5);
    background-size: 210% 210%;
    background-position: 100% 0;
    background-color: #da13f5;
        >i{
            font-size: 20px;
            color: var(--text4);
            transition: var(--transition) ease;
        } 
        >h4{
            /* border:3px solid var(--background3); */
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: 46px;
            height: 46px;
            border-radius: 46px;
            text-align: center;
            padding: 5px;
            border: 4px solid var(--background2);
            background-image: linear-gradient(to top,#28283e 0%,#52527b);
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;   
        }
        >img{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            height: 46px;
            width: 46px;
            border-radius: 46px;
            border: 4px solid var(--background2);
            background-image: linear-gradient(to top,#28283e 0%,#52527b);
        }
`;

const LogoDisplayInBox = styled.div`
    position:relative;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    overflow:hidden;
    color: var(--text4);
    margin-bottom: 10px;
    padding: 10px 0;
        >i{
            font-size: 20px;
            color: var(--text4);
            transition: var(--transition) ease;
        } 
        >h4{
            height: 80px;
            width: 80px;
            border-radius: 80px;
            font-size: 40px;
            display:flex;
            align-items:center;
            justify-content:center;
            background: linear-gradient(to bottom,#E100FF,#7F00FF);
            background-image: linear-gradient(to bottom left,#da13f5,#ba54f5,#da13f5);
            background-size: 210% 210%;
            background-position: 100% 0;
            background-color: #da13f5;
            box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
            /* background-image: linear-gradient(to top,#28283e 0%,#52527b); */
            /* box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%); */
        }
        >img{
            height: 80px;
            width: 80px;
            border-radius: 46px;
            box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
            /* background-image: linear-gradient(to top,#28283e 0%,#52527b); */
        }
`;

const OtherLogo = styled.div`
    position:relative;
    height: 50px;
    width: 50px;
    border-radius: 100px;
    display:flex;
    align-items:center;
    justify-content:center;
    overflow:hidden;
    background: var(--background3);
    color: var(--text4);
    margin-left: 5px;
    font-size: 22px;
    cursor: pointer;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
        &:active{
            box-shadow: inset 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%)
        }    
`;

const OtherLogoInBox = styled.div`
    height: 80px;
    width: 80px;
    border-radius: 80px;
    display:flex;
    align-items:center;
    justify-content:center;
    overflow:hidden;
    background: var(--background3);
    color: var(--text4);
    margin: 0 auto 10px;
    font-size: 22px;
    cursor: pointer;
    background: var(--backBtn);
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%); 
        >i{
            font-size: 40px;
            color: var(--text5);
            transition: var(--transition) ease;
        } 
`;

// const LogoImg = styled.img`
//     height: 100%;
//     width: 100%;
//     position:absolute;
//     top:50%;
//     left:50%;
//     transform: translate(-50%, -50%);
//     object-fit: cover;
// `;

// const H4 = styled.h4`
//     color: var(--text1);
//     padding-left:10px;
// `;

const DialogBox = styled.div`
    position: absolute;
    top: 70px;
    right: 20px;
    width: 280px;
    padding: 20px;
    z-index: 88;
    text-align: center;
    color: var(--text2);
    border-radius: var(--radius);
    background: var(--background1);
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
     /* background: #5f678293;
    backdrop-filter: blur(10px); */
    opacity: ${props => !props.open ? '0' : '1'};
    visibility: ${props => !props.open ? 'hidden' : 'visible'};
    backface-visibility: ${props => !props.open ? 'hidden' : 'visible'};;
    transform: ${props => !props.open ? 'rotate3d(1, 0, 0, -90deg)' : 'rotate3d(0, 0, 0, 0)'};
    transform-origin: 0% 0%;
    transition-property: transform, opacity, visibility;
    transition-duration: 0.1s;
    
    -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;   
        >h4{
            font-weight:bold;
            color: var(--text2);
        }
        >h5{
            color: var(--text3);
        }
`;

const Hr = styled.hr`
    border: none;
    border-bottom: 1px solid var(--color2);
    width: 90%;
    margin: 10px auto;
`;

const NavLinks = styled(Link)`
    width:100%;
    font-size: 14px;
    font-weight:bold;
    color: var(--text2);
    letter-spacing: 1px;
    padding: 15px 20px;
    text-decoration: none;
    display:flex;
    align-items: center;
    justify-content:flex-start;
    border-bottom:1px solid rgba(250, 250, 250, 0.1);
    transition: var(--transition) ease;
        >div>i{
            font-size: 18px;
        }
        &:hover {
            color: var(--color4);
            background:var(--hover);
            border-radius: var(--radius);
            font-weight:bold;
        }
`;

const Button = styled.button`
    width:100%;
    font-size: 14px;
    font-weight:bold;
    color: var(--text2);
    letter-spacing: 1px;
    padding: 15px 20px;
    display:flex;
    align-items: center;
    justify-content:flex-start;
    border:none;
    outline: none;
    transition: var(--transition) ease;
    background: transparent;
    border-radius: var(--radius);
        >div>i{
            font-size: 18px;
        }
        &:hover {
            color: var(--err);
            background: var(--hover);
            font-weight:bold;
        }
`;

const Icon = styled.div`
    margin-right: 10px;
`;


const NavBar = () => {
    const dispatch = useDispatch();
    const profile = useSelector((state) => state.auth.user);
    const [open, setOpen] = useState(false);
    const openDialogRef = useRef();
    // const salon = JSON.parse(localStorage.getItem("salon"));

    // console.log('PROFILE', profile)


    useEffect(() => {
        // dispatch(fetchProfile());
    }, [dispatch]);

    // Avatar
    const getInitials = () => {
        if (profile !== undefined) {
            let string;
            if (profile.flag === 'user' || profile.flag === 'teammate') {
                string = profile.userLastName + ' ' + profile.userFirstName;
            } else {
                string = profile.companyName;
            }
            const names = string.split(' ');
            let initials = names[0].substring(0, 1).toUpperCase();
            if (names.length > 1) {
                initials += names[names.length - 1].substring(0, 1).toUpperCase();
            }
            return initials
        }
    };

    useEffect(() => {
        let handler = (e) => {
            // console.log('haaai', e.target)
            if (openDialogRef.current && !openDialogRef.current.contains(e.target)) {

                if (openDialogRef.current.contains(e.target) === false) {
                    setOpen(false);
                    // console.log('i am false')
                } else {
                    setOpen(true);
                    // console.log('i am true')
                }

            }
        };
        document.addEventListener("mousedown", handler, true);
        return () => {
            document.removeEventListener("mousedown", handler, true);
        }
    }, [open]);

    // useEffect(() => {
    //     let handler = (e) => {

    //         if (openDialogRef.current) {
    //             if (openDialogRef.current.contains(e.target) !== true) {
    //                 console.log("AM INTRAT", openDialogRef)
    //                 setOpen(false)
    //             }
    //         }
    //     };
    //     document.addEventListener("mousedown", handler, true);
    //     return () => {
    //         document.removeEventListener("mousedown", handler, true);
    //     }
    // }, [open]);

    const handleLogOut = () => {
        dispatch(logOut())
        setOpen(!open)
    };

    const handleSearch = () => {
        dispatch(openSearch(true));
    };

    return (
        <NavBarWrapperCss>
            <HamburgerMenu />
            {/* <LinkCss to='/dashboard/profil'>
                <LogoNavBar src={logo}></LogoNavBar>
            </LinkCss> */}

            <Nav>
                {/* <Refresh/> */}

                <OtherLogo onClick={handleSearch}>
                    <i className="fab fa-sistrix"></i>
                </OtherLogo>


                <div ref={openDialogRef}>
                    <ProfileWrapper onClick={() => setOpen(!open)}>

                        {/* <i className="far fa-image"></i> */}
                        {profile !== undefined ?
                            profile.userImg === '' ?
                                <LogoDisplay>
                                    <h4>
                                        {getInitials()}
                                    </h4>
                                </LogoDisplay>
                                :
                                <LogoDisplay>
                                    <img src={profile.userImg} alt=''></img>
                                </LogoDisplay>
                            :
                            <OtherLogo>
                                <i className="fas fa-user-astronaut"></i>
                            </OtherLogo>


                            // profile.newUser.salonUrl === null ?
                            //     <i className="far fa-image"></i>
                            //     :
                            //     <LogoImg src={profile.newUser.salonUrl} alt=""></LogoImg>

                        }



                        {/* {profile !== null ?
                        profile.fname.length > 10 ?
                            <H4>{profile.fname.slice(0, 10) + '...'}</H4>
                            :
                            <H4>{profile.fname}</H4>
                        :
                        null
                    } */}


                    </ProfileWrapper>

                    {/* {open && */}
                    <DialogBox open={open}>
                        {profile ?
                            <>
                                {profile.userImg === '' ?
                                    <LogoDisplayInBox>
                                        <h4>
                                            {getInitials()}
                                        </h4>
                                    </LogoDisplayInBox>
                                    :
                                    <LogoDisplayInBox>
                                        <img src={profile.userImg} alt=''></img>
                                    </LogoDisplayInBox>
                                }
                                {(profile.flag === 'user' || profile.flag === 'teammate')&&
                                    <>
                                        <h4>  {profile.userLastName} {profile.userFirstName}</h4>
                                        <h5>  {profile.userEmail} </h5>
                                    </>
                                }
                                {profile.flag === 'company' &&
                                    <>
                                        <h4>  {profile.companyName}</h4>
                                        <h5>  {profile.companyEmail} </h5>
                                    </>
                                }

                            </>
                            :
                            <>
                                <OtherLogoInBox>
                                    <i className="fas fa-user-astronaut"></i>
                                </OtherLogoInBox>
                                <h4>Hello</h4>
                            </>
                        }
                        <Hr></Hr>

                        {profile ?
                            <>
                                <NavLinks to='/dashboard/profile' onClick={() => setOpen(!open)}>
                                    <Icon><i className="fas fa-user-astronaut"></i></Icon>
                                    My profile
                                </NavLinks>
                                <NavLinks to='/dashboard/price' onClick={() => setOpen(!open)}>
                                    <Icon><i className="fas fa-tag"></i></Icon>
                                    Prices
                                </NavLinks>
                                <Hr></Hr>
                                <Button onClick={() => handleLogOut()}>
                                    <Icon><i className="fas fa-sign-out-alt"></i></Icon>
                                    Log out
                                </Button>
                            </>
                            :
                            <>
                                <NavLinks to='/login' onClick={() => setOpen(!open)}>
                                    <Icon><i className="fas fa-fingerprint"></i></Icon>
                                    Log in
                                </NavLinks>
                                <NavLinks to='/register' onClick={() => setOpen(!open)}>
                                    <Icon><i className="fas fa-cog"></i></Icon>
                                    Register
                                </NavLinks>
                                <Hr></Hr>
                                <NavLinks to='/dashboard/price' onClick={() => setOpen(!open)}>
                                    <Icon><i className="fas fa-tag"></i></Icon>
                                    Prices
                                </NavLinks>
                            </>
                        }
                    </DialogBox>
                    {/* } */}
                </div>

            </Nav>
        </NavBarWrapperCss>
    );
};

export default NavBar;


