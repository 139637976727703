import React, { useLayoutEffect, useState } from 'react';

// PACKEGES
import styled, { keyframes } from 'styled-components';


const Wrapper = styled.div`
    position: relative;
    width: 100%;
    margin: auto;
    padding: 20px 0;
    height: calc(100vh - 200px);
    display: flex;
        >i{
            position: absolute;
            bottom: 0;
            left: 50%;
        }
        @media (max-width: 1500px) {
                height: auto;
                display: none;
            }
`;

const bounce = keyframes`
    0% { transform: translateY(10px) translateX(-50%)}
    50% { transform: translateY(10px) translateX(-50%)}
    75% { transform: translateY(0px) translateX(-50%)}
    100% { transform: translateY(10px) translateX(-50%)}
`;

const SeeMore = styled.div`
    position: absolute;
    bottom: 10px;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color:var(--color2);
    font-size:14px;
    cursor: pointer;
    animation: ${bounce} 2s infinite forwards;
        >i{
            font-size:20px;
        }

`;

const AppSectionWrapper = styled.div`
    width:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    background: ${props => props.theme.background};
        @media (max-width: 1500px) {
            flex-direction: column;
        }
`;

const Body = styled.div`
    /* min-height:${props => props.theme === 'front' ? '600px' : '550px'}; */
    width:${props => props.theme === 'front' ? '350px' : '300px'};
    border-radius: var(--radius);
    margin: ${props => props.theme === 'front' ? '0px' : '5px'};
    padding: 10px;
    text-align: center;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    background: ${props => props.theme === 'front' ? 'linear-gradient(87deg,#de7c8a 0,#da13f5 100%)' : 'var(--background3)'};
    /* color: var(--text3); */
        >h1{
            /* font-size: 60px; */
            font-size: ${props => props.theme === 'front' ? '60px' : '50px'};
        }
        @media (max-width: 1500px) {
            width:400px;
        }
        @media (max-width: 400px) {
            width:300px;
        }
`;

const H2 = styled.h2`
    position:relative;
    font-weight:bold;
    color: var(--text5);
    font-size: ${props => props.theme === 'front' ? '50px' : '40px'};
    text-align: center;
        >span:nth-child(1){
            margin-right:10px;
            font-size: 24px;
            color: ${props => props.theme === 'front' ? 'var(--text5)' : 'var(--text4)'};
        }
        >span:nth-child(2){
            font-size: 12px;
            color: ${props => props.theme === 'front' ? 'var(--text5)' : 'var(--text4)'};
        }
        @media (max-width: 400px) {
                >span{
                    left: 60px;
                }
            }
`;

// const H3 = styled.h3`
//     font-weight:bold;
//     padding: 20px 0;
//     color: ${props => props.theme.color};
// `;

const H6 = styled.h6`   
    padding: 0;
    color: ${props => props.theme === 'front' ? 'var(--text5)' : 'var(--text3)'};
    margin: 0 0 20px;
    letter-spacing:2px;
    @media (max-width: 1000px) {
                font-size:12px;
                margin: 0;
            }
`;

const H5 = styled.h5`
    font-weight:bold;
    /* margin:0 0 10px; */
    padding: 5px 0;
    color: ${props => props.theme.color}; 
`;

const Hr = styled.hr`
    margin:20px 0;
    border: none;
    width: 80%;
    border-bottom: 1px solid ${props => props.theme.hr};
    margin: 10px auto;
    color: ${props => props.theme === 'front' ? '#80008a66' : 'var(--background4)'};
`;

const Btn = styled.button`
    width: 250px;
    height:50px;
    display:flex;
    align-items: center;
    justify-content:center;
    color:var(--text5);
    font-weight:bold;
    letter-spacing:2px;
    border-radius: var(--radius);
    /* margin-top:10px; */
    margin:10px auto;
    cursor: pointer;
    border: none;
    outline:none;
    transition: filter var(--transition) ease-in-out;
    /* background: linear-gradient(60deg,#f3a81f,#e08b2a); */
    background-color: ${props => !props.less ? 'var(--color4)' : 'var(--background4)'};
    box-shadow:${props => !props.less ?
        '0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px #da13f5ad'
        :
        '0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08)'
    };
        &:active {
            -webkit-transform: translateY(3px);
            -moz-transform: translateY(3px);
            transform: translateY(3px);
            box-shadow: none;
        } 
        &:hover{
            filter: brightness(0.9);
            color:var(--text5)
        }
`;

const card = [
    {
        plan: 'Single',
        for: 'suitable for Freelancers',
        price1: '4.99',
        price2: '49.99',
        obs: ['Unlimited Domains',
            'Responsive Designe',
            'Update Daily',
            'Team size 1 person',
            'Suport H24/7'],
        value1: 'solo-year-plan',
        value2: 'solo-month-plan',
    },
    {
        plan: 'Squad',
        for: 'suitable for Startups',
        price1: '19.99',
        price2: '199.99',
        obs: ['Unlimited Domains',
            'Responsive Designe',
            'Update Daily',
            'Team size 5 people',
            'Suport H24/7'],
        value1: 'squad-year-plan',
        value2: 'squad-month-plan',
    },
    {
        plan: 'Team',
        for: 'suitable for Companys',
        price1: '89.99',
        price2: '899.99',
        obs: ['Unlimited Domains',
            'Responsive Designe',
            'Update Daily',
            'Team size 25 people',
            'Suport H24/7'],
        value1: 'team-year-plan',
        value2: 'team-month-plan',
        theme: 'front',
    },
    {
        plan: 'Big Team',
        for: 'suitable for Enterprizes',
        price1: '199.99',
        price2: '1999.99',
        obs: ['Unlimited Domains',
            'Responsive Designe',
            'Update Daily',
            'Team size 60 people',
            'Suport H24/7'],
        value1: 'bigteam-year-plan',
        value2: 'bigteam-month-plan',
    },

];

const PriceCard = ({ executeScroll, checked, handlePlan }) => {
    const [seeMore, setSeeMore] = useState(false);


    useLayoutEffect(() => {
        function updateSize() {
            if (window.scrollY > 80) {
                setSeeMore(true);
            } else {
                setSeeMore(false);
            }
        }
        window.addEventListener('scroll', updateSize);
        updateSize();
        return (() => window.removeEventListener('scroll', updateSize));
    }, []);


    return (
        <Wrapper>
            <AppSectionWrapper >
                {card.map((item, i) =>
                    <Body key={i} theme={item.theme} >
                        <h1 theme={item.theme}>{item.plan}</h1>
                        <H6 theme={item.theme}>{item.for}</H6>
                        <H2 theme={item.theme}>
                            <span >$</span>
                            {!checked ?
                                item.price1
                                :
                                item.price2
                            }
                            <span>
                                {!checked ?
                                    '/month'
                                    :
                                    '/year'
                                }
                            </span>
                        </H2>
                        <div>
                            {item.obs.map((ob, i) =>
                                <div key={i}>
                                    <Hr theme={item.theme} />
                                    <H5 >{ob}</H5>
                                </div>
                            )}
                        </div>
                        {checked ?
                            <Btn onClick={() => handlePlan(item.value1)}>Buy now!</Btn>
                            :
                            <Btn onClick={() => handlePlan(item.value2)}>Buy now!</Btn>
                        }
                    </Body>
                )}
            </AppSectionWrapper>

            {!seeMore &&
                <SeeMore
                    onClick={executeScroll}
                >
                    See more
                    <i className="fas fa-chevron-down"></i>
                </SeeMore>
            }
        </Wrapper>
        // <Wrapper>
        //     <AppSectionWrapper >
        //         {card.map((item, i) =>
        //             <Body key={i} theme={{ main: item.theme, background: item.background, color: item.color }} >
        //                 <h1 theme={{ color: item.color }}>{item.plan}</h1>
        //                 <H4 theme={{ color: item.color }}>{item.for}</H4>
        //                 <H2 theme={{ fontSize: '50px', txtColor: item.txtColor }}>
        //                     <span theme={{ colorSpan: 'var(--text3)' }}>€</span>
        //                     {item.price}
        //                 </H2>
        //                 <div>
        //                     {item.obs.map((ob, i) =>
        //                         <div key={i}>
        //                             <Hr theme={{ hr: item.hr }} />
        //                             <H5 theme={{ color: item.color }}>{ob}</H5>
        //                         </div>
        //                     )}
        //                 </div>
        //             </Body>
        //         )}
        //     </AppSectionWrapper>
        // </Wrapper>
    );
}

export default PriceCard;