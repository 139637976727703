import React from 'react'

// PACKEGES
import styled from 'styled-components'

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  position:relative;
  margin-top: 50px;
`

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  width: 320px;
  height: 40px;
  opacity: 0;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  z-index:20;
  cursor: pointer;
  /* border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px; */
`

const Icon = styled.div`
  width: 160px;
  height:30px;
  border-radius: var(--radius);
  background: var(--background2);
  color: var(--text5);
  display:flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 20px 0 rgb(0 0 0 / 10%);
    /* >i{
        font-size:10px;
        color: var(--text5);
        position:absolute;
        top:50%;
        left: 50%;
        transform:translate(-45%, -50%);
    }   */
`

const StyledCheckbox = styled.div`
    position: relative;
    width: 320px;
    height: 40px;
    border-radius: var(--radius);
    padding:4px;
    background: ${props => !props.checked ? 'var(--background3)' : 'var(--background3)'};    
    box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
    display:flex;
    align-items: center;
    transition: all 250ms;
      ${Icon} {
        transition: all .2s ease;
        transform: ${props => (props.checked ? 'translateX(150px)' : 'translateX(0px)')}
      }
      >span{
        position: absolute;
        color: var(--text2);
        left: ${props => (props.checked === true && '50px')};
        right: ${props => (props.checked === false && '60px')};
        /* transform: ${props => (props.checked === false ? 'translateX(150px)' : 'translateX(0px)')} */
      }
`;

// const Save = styled.div`
//     position: absolute;
//     top: 20px;
//     right: -50px;
//     font-family: 'Caveat', cursive;
//     font-size: 20px;
//     color: var(--text3);
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//       >svg{
//           transform: scaleY(-1);
//       }
// `;


const Checkbox = ({ onChange, checked }) => {
  // console.log(checked)
  return (
    <CheckboxContainer>
      <HiddenCheckbox
        checked={checked}
        onChange={(e) => onChange(e)}
      />

      <StyledCheckbox checked={checked}>
        <span checked={checked}>
          {checked ?
            'Personal'
            :
            'Team'
          }
        </span>

        <Icon >
          {checked ?
            'Team'
            :
            'Personal '
          }
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  );
};

export default Checkbox;
