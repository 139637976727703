import React, { useState } from 'react'
// import { Link } from 'react-router-dom';

// PACKAGES
import styled from 'styled-components';

const FooterWrapper = styled.div`
   width: calc(100% - 250px);
   margin-left: 250px;
        @media (max-width: 775px) {
                        width:100%;
                        margin-left:0;
        };
`;

const FooterBody = styled.div`
   width: 100%;
   text-align: center;
        >ul{
            padding: 0;
            >li{
                list-style: none;
                padding: 16px 15px;
                >a{
                    text-decoration: none;
                    color:var(--text4);
                    transition: all .3s;
                        &:hover{
                            color:var(--color4);
                        }
                }
            }
        }
`;

const SubFooter = styled.div`
   display: flex;
   justify-content: center;
   padding: 16px 15px;
   color:var(--text2);
        >a{
            position: relative;
            display: flex;
            margin: 0 15px 0 10px;
            text-decoration: none;
            color:var(--text2);
            font-weight: bold;
            transition: all .3s;
                &:hover{
                    transform: scale(1.3);
                    color:var(--color4);
                }
        >div{
            position: absolute;
            right:-10px;
            top:-3px;
            margin-bottom:0px;
            font-size: 20px;
            font-weight: bold;
            width: 10px;
            transform: rotate(10deg);
        }
    }
`;

const Hr = styled.hr`
    width: 90%;
    border:none;
    border-top: 1px solid var(--background4);
    margin:auto;
`;


const Footer = () => {
    const today = new Date();
    const [year] = useState(today.getFullYear());

    return (
        <FooterWrapper>
            <Hr></Hr>

            <FooterBody id='footer'>
                {/* <ul>
                    <li><Link to='/dashboard/aboutUs'>About Us</Link></li>
                    <li><Link to='/dashboard/contactUs'>Contact Us</Link></li>
                    <li><Link to='/dashboard/legal'>Legal</Link></li>
                </ul>
                <Hr></Hr> */}

                <SubFooter> © 2020-{year}<a href="https://csyes-team.com" target="_blank" rel="noreferrer">
                    CSYES<div>!</div> </a>Team, all rights reserved.
                </SubFooter>

            </FooterBody>
        </FooterWrapper>
    );
}

export default Footer;