import React from 'react'

// PACKEGES
import styled from 'styled-components'

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  position:relative;
`

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  width: 320px;
  height: 40px;
  opacity: 0;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  z-index:20;
  cursor: pointer;
  /* border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px; */
`

const Icon = styled.div`
  width: 160px;
  height:30px;
  border-radius: var(--radius);
  background: var(--background2);
  color: var(--text5);
  display:flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 20px 0 rgb(0 0 0 / 10%);
    /* >i{
        font-size:10px;
        color: var(--text5);
        position:absolute;
        top:50%;
        left: 50%;
        transform:translate(-45%, -50%);
    }   */
`

const StyledCheckbox = styled.div`
    position: relative;
    width: 320px;
    height: 40px;
    border-radius: var(--radius);
    padding:4px;
    background: ${props => !props.checked ? 'var(--background3)' : 'var(--background3)'};    
    box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
    display:flex;
    align-items: center;
    transition: all 250ms;
      ${Icon} {
        transition: all .2s ease;
        transform: ${props => (props.checked ? 'translateX(150px)' : 'translateX(0px)')}
      }
      >span{
        position: absolute;
        color: var(--text2);
        left: ${props => (props.checked === true && '30px')};
        right: ${props => (props.checked === false && '30px')};
        /* transform: ${props => (props.checked === false ? 'translateX(150px)' : 'translateX(0px)')} */
      }
`;

const Save = styled.div`
    position: absolute;
    top: 20px;
    right: -50px;
    font-family: 'Caveat', cursive;
    font-size: 20px;
    color: var(--text3);
    display: flex;
    flex-direction: column;
    align-items: center;
      >svg{
          transform: scaleY(-1);
      }
`;


const Checkbox = ({ onChange, checked }) => {
  // console.log(checked)
  return (
    <CheckboxContainer>
      <HiddenCheckbox
        checked={checked}
        onChange={(e) => onChange(e)}
      />

      <StyledCheckbox checked={checked}>
        <span checked={checked}>
          {checked ?
            'Billed monthly'
            :
            'Billed annually'
          }
        </span>

        <Icon >
          {checked ?
            'Billed annually'
            :
            'Billed monthly'
          }
        </Icon>
      </StyledCheckbox>

      <Save >
        <svg width='26px' height='36px' fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M.18 35.264a41.65 41.65 0 0 0 1.897-1.367l-.41-.055c-.51-.069-.478-.773 0-.865a56.137 56.137 0 0 0 2.094-.432 36.346 36.346 0 0 0 5.61-5.959c.361-.483 1.238-.046.892.45a30.513 30.513 0 0 1-4.276 4.961c7.862-2.103 15.305-6.06 18.116-13.118 2.964-7.426-1.77-15.249-9.898-18.267-.387-.143-.111-.722.293-.593 8.255 2.6 13.19 10.624 10.965 18.009-2.561 8.494-11.752 13.109-20.948 15.271l-.032.028 9.021 1.228c.595.083.42.865-.138.88l-12.299.358c-.053.037-.1.074-.149.106-.526.35-1.264-.28-.738-.635zm2.322-.41c1.811-.05 3.617-.105 5.43-.156-1.473-.198-2.939-.4-4.41-.603-.335.258-.675.511-1.02.76z" fill="var(--text3)"></path>
        </svg>
        Save 20%
      </Save>

    </CheckboxContainer>
  );
};

export default Checkbox;
