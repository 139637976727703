import React, { useState } from 'react';

// PACKEGES
import styled from 'styled-components';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { deleteGradient } from '../../../Store/Actions/gradientActions';

// SHARE STYLE
import {
    // IconWrapper,
    // PopUp,
    ModalWrapper,
    DialogBox,
    H3,
    Hr,
    BtnWrapper,
    Btn,
} from './ModalStyle';

const H4 = styled.h4`
    width:100%;
    text-align: center;
    color: var(--text2);
    padding: 10px;
`;

const ActionBtn = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    width: 40px;
    height:40px;
    margin-left: 5px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: var(--background5);
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    backdrop-filter: blur(10px);
        >i{
            color:  var(--text5);
        }
        &:hover{
                >i{
                    color: ${props => props.color};
                    transition:color var(--transition) ease-in-out;
                }
           }
`;


const DeleteModal = ({ gradient }) => {
    const dispatch = useDispatch();
    const profile = useSelector((state) => state.auth.user);
    const [openModal, setOpenModal] = useState(false);

    const handleClose = (e) => {
        e.preventDefault();
        setOpenModal(false);
    };

    // console.log('DELTE MODAL', content)

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(deleteGradient(gradient.GradientID, profile.accesLevel));
        handleClose(e);
    };

    return (
        <>
            <ActionBtn color='var(--err)' onClick={() => setOpenModal(!openModal)}><i className="fas fa-trash-alt"></i></ActionBtn>

            {openModal &&
                <>
                    <ModalWrapper onClick={(e) => handleClose(e)} />

                    <DialogBox>
                        <H3>Delete gradient</H3>
                        <Hr></Hr>

                        <H4> Are you sure you want to delete gradient - {gradient.Name}?</H4>
                        {/* <H4>- {content.lname + ' ' + content.fname} -</H4> */}

                        <BtnWrapper>
                            <Btn disable onClick={handleClose}>Cancel</Btn>
                            <Btn onClick={handleSubmit}>Delete</Btn>
                        </BtnWrapper>
                    </DialogBox>

                </>
            }

        </>

    );
}
export default DeleteModal;