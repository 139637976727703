import React, { useState } from 'react';

// PACKEGES
import styled from 'styled-components';

// REDUX
import { useDispatch } from 'react-redux';
import { deleteTeammate } from '../../../../Store/Actions/teammateActions';

// SHARE STYLE
import {
    ModalWrapper,
    DialogBox,
    H3,
    Hr,
    BtnWrapper,
    Btn,
} from './ModalStyle';

const H4 = styled.h4`
    width:100%;
    text-align: center;
    color: var(--text5);
    padding: 10px;
`;

const ActionBtn = styled.button`
     padding: 5px 10px;
     display:flex;
     align-items: center;
     justify-content:center;
     border:none;
     color: var(--text2);
     border-radius: var(--radius);
     cursor: pointer;
     transition: all var(--transition) ease;
     background: transparent;
     outline: none;
     font-weight: bold;
     font-size: 12px;
        >i{
            font-size: 15px;
            padding-right: 10px;
            color: var(--text2);
            transition: color var(--transition) ease-in-out;
        }
        &:hover{
            color: var(--text4);
             >i{
                color: var(--text4); 
             }
        }
`;


const TeamDeleteModal = (props) => {
    const dispatch = useDispatch();
    // const profile = useSelector((state) => state.auth.user);
    const [openModal, setOpenModal] = useState(false);

    // console.log('DELTE MODAL', props);

    const handleClose = (e) => {
        e.preventDefault();
        setOpenModal(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(deleteTeammate(props.id));
        handleClose(e);
    };

    return (
        <>
            <ActionBtn
                onClick={() => setOpenModal(!openModal)}
            >
                Delete
            </ActionBtn>

            {openModal &&
                <>
                    <ModalWrapper onClick={(e) => handleClose(e)} />

                    <DialogBox>
                        <H3>Delete teammate</H3>
                        <Hr></Hr>

                        <H4> Are you sure you want to delete teammate {props.name}?</H4>
                        {/* <H4>- {content.lname + ' ' + content.fname} -</H4> */}

                        <BtnWrapper>
                            <Btn  onClick={handleClose}>Cancel</Btn>
                            <Btn disable onClick={handleSubmit}>Delete</Btn>
                        </BtnWrapper>
                    </DialogBox>
                </>
            }
        </>

    );
}
export default TeamDeleteModal;