import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

// COMPONENTS
// import AdminNav from '../Components/AdminNav';
// import Other from '../Components/OtherLog';
// import Checkbox from '../Components/Checkbox';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { resetPsw } from '../../Store/Actions/authActions';

// SHARE STYLE
import {
    AuthWrapper,
    RightSideAuth,
    FormWrapper,
    H1,
    H5,
    // InputWrapper,
    // PswWrapper,
    // EyeWrapper,
    LabelCss,
    InputCss,
    // Label,
    // CheckTxt,
    BtnWrapper,
    Btn,
    LinkCss
} from '../ShareStyle/ShareStyle';

// COMPONENTS
import Title from '../../Dashboard/DashboardComponents/PagesTitles/PagesTitles';


const ResendEmail = () => {
    const dispatch = useDispatch();
    let history = useHistory();
    const reset = useSelector((state) => state.auth.resendEmail);
    const [access, setAccess] = useState({
        email: '',
    });
    const [waitBtn, setWaitBtn] = useState(false);
    const [timeLeft, setTimeLeft] = useState();

    const submitForm = (e) => {
        e.preventDefault();
        dispatch(resetPsw(access));
        setWaitBtn(true);
        setTimeout(() => {
            setWaitBtn(false);
            setAccess({
                ...access,
                email: ''
            })
        }, 5000)
        setTimeLeft(5);
    };

    if (reset === 'The password has been changed!') {
        setTimeout(() => {
            history.push('/login')
        }, 5000)
    };

    useEffect(() => {
        // exit early when we reach 0
        if (!timeLeft) return;
        // save intervalId to clear the interval when the
        // component re-renders
        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
        // add timeLeft as a dependency to re-rerun the effect
        // when we update it
    }, [timeLeft]);

    return (
        <AuthWrapper>
            <Title
                first='Hey,'
                second='You will receive an e-mail in aprox. 60 seconds'
                third='after resetting the password.'
            />

            <RightSideAuth style={{ background: 'var(--background2)' }}>

                <FormWrapper signin onSubmit={submitForm}>
                    <H1>Reset Password</H1>

                    <LabelCss htmlFor="email">E-mail</LabelCss>
                    <InputCss
                        placeholder="Enter your e-mail"
                        id='email'
                        type="mail"
                        name='email'
                        required
                        value={access.email}
                        onChange={(e) =>
                            setAccess({
                                ...access,
                                email: e.target.value
                            })
                        }
                    >
                    </InputCss>

                    {/* <BtnWrapper>
                        <Btn type='submit'>Reset</Btn>
                    </BtnWrapper> */}

                    <BtnWrapper>
                        {waitBtn ?
                            reset === undefined ?
                                <Btn disabled>Try again in {timeLeft}s</Btn>
                                :
                                <Btn disabled>Redirect in {timeLeft}s</Btn>
                            :
                            <Btn type='submit'>Reset</Btn>
                        }
                    </BtnWrapper>

                    <H5>
                        Go to
                        <LinkCss to='/login' >Log in!</LinkCss >
                    </H5>
                </FormWrapper>

            </RightSideAuth>

        </AuthWrapper>
    );
}
export default ResendEmail;