const initState = {

};

const dashboardReducer = (state = initState, action) => {
  switch (action.type) {
    case 'UPDATE_PROFILE':
      return {
        ...state,
        updatePofile: action.payload,
      };

    case 'NOTIF_HANDLER_DASH':
      return {
        ...state,
        handlerNotifDash: action.payload,
      };

    default:
      return state;
  }
};

export default dashboardReducer;