import axios from 'axios';
axios.defaults.withCredentials = true;

export const reciveGradient = (reset) => (dispatch, getState) => {
    // console.log('I`M IN');
    dispatch({ type: 'RECIVING_GRADIENT' });

    // if (reset === true) {
    //     dispatch({
    //         type: 'RECIVED_GRADIENT',
    //         payload: [],
    //     });
    //     dispatch({ type: 'RECIVING_GRADIENT' });
    //     dispatch({
    //         type: 'HAS_MORE_GRADIENT',
    //         payload: true,
    //     });
    //     dispatch({
    //         type: 'SET_PAGE_NUMBER_GRADIENT',
    //         payload: 1,
    //     });
    //     dispatch({
    //         type: 'SET_PREV_PAGE_NUMBER_GRADIENT',
    //         payload: 1,
    //     });
    // }

    const gradient = getState().gradient.gradient;
    const pageNumberGradient = getState().gradient.pageNumberGradient;
    const articlesPerPage = 5;
    const body = {
        pageNumberGradient: pageNumberGradient,
        articlesPerPage: articlesPerPage,
    };

    // console.log(pageNumber);
    // console.log('RECIVING_CONTENT', gradient, pageNumberGradient);
    // console.log('LOADING',getState().gradient.loading)
    // console.log('CONTENT', cgradient);

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/reciveGradient`, body)
        .then((res) => {
            // console.log("RECIVE_GRADIENT", res.data);
            dispatch({
                type: 'RECIVED_GRADIENT',
                payload: gradient === undefined ? res.data : [...gradient, ...res.data]
            });
            if (res.data.arrLength <= gradient.length || res.data.length < articlesPerPage) {
                dispatch({
                    type: 'HAS_MORE_GRADIENT',
                    payload: false,
                });
            }

            // dispatch(notifHandler('success', 'Successfully logged in!'));
        })
        .catch((err) => {
            console.log(err);
            // dispatch(notifHandler('error', err.response.data.message));
        });
};

// export const reciveGradient = (flag, reset) => (dispatch, getState) => {

//     axios
//         .post(`${process.env.REACT_APP_URL_BACKEND}/reciveGradient`)
//         .then((res) => {
//             // console.log("RECIVE_GRADIENT", res.data);
//             dispatch({
//                 type: 'RECIVED_GRADIENT',
//                 payload: res.data
//             });
//         })
//         .catch((err) => {
//             console.log(err);
//         });
// };

export const addGradient = (more) => (dispatch, getState) => {
    // console.log('ADD_GRADIENT', more)

    const body = {
        gradientName: more.gradientName,
        bk: more.bk,
        bkColor: more.bkColor,
        bkImage: more.bkImage,
        bkBlend: more.bkBlend,
        keywords: more.keywords,
        madeWith: more.madeWith,
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/addGradient`, body)
        .then((res) => {
            console.log("ADD_GRADIENT", res);
            // dispatch({
            //     type: 'ADD_GRADIENT',
            //     payload: res.data,
            // });
            dispatch(notifHandler('success', res.data.message));
            // dispatch(reciveContent())
        })
        .catch((err) => {
            console.log(err);
            dispatch(notifHandler('error', err.response.data.message));
        });
};

export const editGradient = (more, id, role) => (dispatch, getState) => {
    // console.log('EDIT_GRADIENT', more, poster, id, role)

    const body = {
        gradientName: more.gradientName,
        bk: more.bk,
        bkColor: more.bkColor,
        bkImage: more.bkImage,
        bkBlend: more.bkBlend,
        keywords: more.keywords,
        madeWith: more.madeWith,
        id: id,
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/editGradient`, body)
        .then((res) => {
            // console.log("ADD_GRADIENT", res)
            // dispatch({
            //     type: 'ADD_GRADIENT',
            //     payload: res.data,
            // });
            dispatch(notifHandler('success', res.data.message));
            // dispatch(reciveContent());
        })
        .catch((err) => {
            console.log(err);
            dispatch(notifHandler('error', err.response.data.message));
        });
};

export const deleteGradient = (id, role) => (dispatch, getState) => {
    // console.log('DELETE_GRADIENT', id, role)

    const body = {
        id: id,
        role: role,
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/deleteGradient`, body)
        .then((res) => {
            // console.log("DELETE_GRADIENT", res)
            dispatch({
                type: 'DELETE_GRADIENT',
                payload: res.data,
            });
            dispatch(notifHandler('success', res.data.message));
            // dispatch(reciveContent());
        })
        .catch((err) => {
            console.log(err);
            dispatch(notifHandler('error', err.response.data.message));
        });
};

export const setPageNumber = (props) => (dispatch) => {
    dispatch({
      type: 'SET_PAGE_NUMBER_GRADIENT',
      payload: props,
    });
  };
  
  export const setPrevLocation = (props) => (dispatch) => {
    dispatch({
      type: 'SET_PREV_PAGE_NUMBER_GRADIENT',
      payload: props,
    });
  };

const notifHandler = (flag, msg) => (dispatch) => {
    const obj = {
        flag: flag,
        msg: msg,
    };
    dispatch({
        type: 'NOTIF_HANDLER_GRADIENT',
        payload: obj,
    });
    setTimeout(() => {
        dispatch({
            type: 'NOTIF_HANDLER_GRADIENT',
            payload: undefined,
        });
    }, 3000);
};