// import { fetchUser } from './authActions';
import axios from 'axios';
axios.defaults.withCredentials = true;

export const updateProfile = (access, flag) => (dispatch, getState) => {

    let body;

    if (flag === 'user') {
        body = {
            fname: access.fname,
            lname: access.lname,
            email: access.email,
            psw: access.psw,
        }
    } else if (flag === 'company') {
        body = {
            compName: access.compName,
            compEmail: access.compEmail,
            compVAT: access.compVAT,
            psw: access.psw,
        }
    }

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/updateProfile`, body)
        .then((res) => {
            // console.log("UPDATE USER", res.data);
            dispatch({
                type: 'FETCHED_USER',
                payload: res.data,
            });
            // dispatch(fetchUser());
        })
        .catch((err) => {
            console.log(err);
        });
};

export const changeProfilePicture = (pic) => (dispatch, getState) => {

    const body = new FormData();
    body.append('file', pic);

    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/changeProfilePicture`, body, config)
        .then((res) => {
            // console.log("CHECK USER", res)
            dispatch({
                type: 'FETCHED_USER',
                payload: res.data,
            });
            // dispatch(fetchUser());
        })
        .catch((err) => {
            console.log(err);
        });
};

export const deleteProfilePicture = () => (dispatch, getState) => {

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/deleteProfilePicture`)
        .then((res) => {
            // console.log("CHECK USER", res)
            dispatch({
                type: 'FETCHED_USER',
                payload: res.data,
            });
            // dispatch(fetchUser());
        })
        .catch((err) => {
            console.log(err);
        });
};

export const sentMsg = (props) => (dispatch, getState) => {
    const body = {
        email: props.email,
        msg: props.msg,
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/sentToUs`, body)
        .then((res) => {
            // console.log("CHECK USER", res)
            // dispatch({
            //     type: 'FETCHED_USER',
            //     payload: res.data,
            // });
            // dispatch(notifHandler('success', res.data.message));
        })
        .catch((err) => {
            console.log(err);
            // dispatch(notifHandler('error', err.response.data.message));
        });
};

// const notifHandler = (flag, msg) => (dispatch) => {
//     const obj = {
//         flag: flag,
//         msg: msg,
//     };
//     dispatch({
//         type: 'NOTIF_HANDLER_DASH',
//         payload: obj,
//     });
//     setInterval(() => {
//         dispatch({
//             type: 'NOTIF_HANDLER_DASH',
//             payload: undefined,
//         });
//     }, 3000);
// };