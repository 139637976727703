import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// COMPONENTS
// import AdminNav from '../Components/AdminNav';
// import Other from '../Components/OtherLog';
import Checkbox from '../AuthComponents/Checkbox';
// import RadioCheckbox from './RadioCheckbox';

// ASSETS
import spin from '../../Assets/loadingBtn2.gif';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { registerUser } from '../../Store/Actions/authActions';

// SHARE STYLE
import {
    AuthWrapper,
    RightSideAuth,
    FormWrapper,
    H1,
    H5,
    H6,
    Fields,
    InputWrapper,
    PswWrapper,
    EyeWrapper,
    LabelCss,
    InputCss,
    Label,
    CheckTxt,
    BtnWrapper,
    Btn,
    ChooseBtn,
    LinkCss,
    Hr,
    Err,
} from '../ShareStyle/ShareStyle';

// COMPONENTS
import Title from '../../Dashboard/DashboardComponents/PagesTitles/PagesTitles';


const Register = () => {
    const dispatch = useDispatch()
    const [showPassword, setShowPassword] = useState(true);
    const loading = useSelector((state) => state.auth.authLoading);
    const [access, setAccess] = useState({
        fname: '',
        lname: '',
        company: '',
        vat: '',
        email: '',
        psw: '',
        confirmPsw: '',
    });
    const [checked, setChecked] = useState(false);
    const [type, setType] = useState({
        company: false,
        user: false,
    });
    const [checkErr, setCheckErr] = useState(false);
    const [matchErr, setMatchErr] = useState(false);

    const handleShowPassword = () => {
        setShowPassword(false);
        setTimeout(() => {
            setShowPassword(true);
        }, 4000)
    };

    const handleCheckboxChange = event => {
        setChecked(event.target.checked)
    };

    const submitForm = (e) => {
        e.preventDefault();
        if (access.psw === access.confirmPsw) {
            if (checked) {
                dispatch(registerUser(access, checked));
            } else {
                setCheckErr(true);
                setMatchErr(false);
                setTimeout(() => {
                    setCheckErr(false);
                }, 4000);
            }
        } else {
            setMatchErr(true);
            setCheckErr(false);
            setTimeout(() => {
                setMatchErr(false);
            }, 4000);
        }
    }

    const choose = (e) => {
        if (e === '1') {
            setType({
                ...type,
                company: true,
                user: false
            })
        } else if (e === '2') {
            setType({
                ...type,
                company: false,
                user: true
            })
        }
    };

    return (
        <AuthWrapper>
            <Title
                first='Welcome!'
                second='Thank you for choosing us to be with you'
                third='on this journey!'
            />

            <RightSideAuth style={{ background: 'var(--background2)' }}>



                {(!type.company && !type.user) &&
                    <FormWrapper>
                        <H1>Register as a</H1>
                        <Hr></Hr>

                        <ChooseBtn onClick={() => choose('1')}>
                            <i className="fas fa-building" ></i>
                            Company
                        </ChooseBtn>
                        <H5 style={{ textAlign: 'center' }}>or</H5>
                        <ChooseBtn onClick={() => choose('2')}>
                            <i className="fas fa-user-astronaut"></i>
                            Person
                        </ChooseBtn>

                    </FormWrapper>
                }

                {type.company &&
                    <FormWrapper onSubmit={submitForm}>
                        <H1>Register</H1>
                        <H5 style={{ textAlign: 'center', marginTop: '0' }}>as a company</H5>
                        <Hr></Hr>

                        {/* <H5 style={{ textAlign: 'center' }}>Register with:</H5>
                        <Other />
                        <H5 style={{ textAlign: 'center', margin: '0' }}>Or with:</H5> */}

                        {/* <Fields>
                            <InputWrapper smallR>
                                <LabelCss htmlFor="company">Company<span>*</span></LabelCss>
                                <InputCss
                                    placeholder="Enter your company name"
                                    id='company'
                                    type="text"
                                    name='company'
                                    required
                                    value={access.company}
                                    onChange={(e) =>
                                        setAccess({
                                            ...access,
                                            company: e.target.value
                                        })
                                    }
                                >
                                </InputCss>
                            </InputWrapper>

                            <InputWrapper smallL>
                                <LabelCss htmlFor="vat">VAT<span>*</span></LabelCss>
                                <InputCss
                                    placeholder="Enter your VAT number"
                                    id='vat'
                                    type="text"
                                    name='vat'
                                    required
                                    value={access.vat}
                                    onChange={(e) =>
                                        setAccess({
                                            ...access,
                                            vat: e.target.value
                                        })
                                    }
                                >
                                </InputCss>
                            </InputWrapper>
                        </Fields> */}

                        <InputWrapper full>
                            <LabelCss htmlFor="company">Company<span>*</span></LabelCss>
                            <InputCss
                                placeholder="Enter your company name"
                                id='company'
                                type="text"
                                name='company'
                                required
                                value={access.company}
                                onChange={(e) =>
                                    setAccess({
                                        ...access,
                                        company: e.target.value
                                    })
                                }
                            >
                            </InputCss>
                        </InputWrapper>

                        <InputWrapper full>
                            <LabelCss htmlFor="email">E-mail<span>*</span></LabelCss>
                            <InputCss
                                placeholder="Enter your e-mail"
                                id='email'
                                type="mail"
                                name='email'
                                required
                                value={access.email}
                                onChange={(e) =>
                                    setAccess({
                                        ...access,
                                        email: e.target.value
                                    })
                                }
                            >
                            </InputCss>
                        </InputWrapper>

                        <InputWrapper full>
                            <PswWrapper>
                                <LabelCss htmlFor='psw'>Password<span>*</span></LabelCss>
                                <InputCss
                                    placeholder="Enter your password"
                                    id='psw'
                                    type={showPassword ? 'password' : 'text'}
                                    name='password'
                                    required
                                    value={access.psw}
                                    onChange={(e) =>
                                        setAccess({
                                            ...access,
                                            psw: e.target.value
                                        })
                                    }
                                ></InputCss>
                                <EyeWrapper onClick={() => handleShowPassword()}>
                                    {showPassword ?
                                        <i className="far fa-eye-slash"></i>
                                        :
                                        <i className="far fa-eye"></i>
                                    }
                                </EyeWrapper>
                            </PswWrapper>
                        </InputWrapper>


                        <InputWrapper full>
                            <PswWrapper>
                                <LabelCss htmlFor='pswc'>Confirm Password<span>*</span></LabelCss>
                                <InputCss
                                    placeholder="Enter your password again"
                                    id='pswc'
                                    type={showPassword ? 'password' : 'text'}
                                    name='password'
                                    required
                                    value={access.confirmPsw}
                                    onChange={(e) =>
                                        setAccess({
                                            ...access,
                                            confirmPsw: e.target.value
                                        })
                                    }
                                ></InputCss>
                                <EyeWrapper onClick={() => handleShowPassword()}>
                                    {showPassword ?
                                        <i className="far fa-eye-slash"></i>
                                        :
                                        <i className="far fa-eye"></i>
                                    }
                                </EyeWrapper>
                            </PswWrapper>
                        </InputWrapper>

                        <Label>
                            <Checkbox
                                checked={checked}
                                onChange={handleCheckboxChange}
                            />
                            <CheckTxt>I agree with the
                                <Link to='/dashboard/legal'>Terms</Link> and the
                                <Link to='/dashboard/legal'>Privacy Policy</Link>
                            </CheckTxt>
                        </Label>

                        {checkErr &&
                            <Err>Please accept the <span>Terms</span> and <span>Privacy Policy!</span></Err>
                        }
                        {matchErr &&
                            <Err>Passwords does't <span>match!</span></Err>
                        }

                        <BtnWrapper>
                            {/* <Btn type='submit'>Register</Btn> */}
                            {loading ?
                                <Btn disabled>
                                    <img src={spin} alt='loading'></img>
                                    Loading...
                                </Btn>
                                :
                                <Btn type='submit'>Register</Btn>
                            }
                        </BtnWrapper>

                        <H5>
                            You already have an account?
                            <LinkCss to='/login' >Log in here!</LinkCss >
                        </H5>
                        <H6>all fields marked with * are required</H6>
                    </FormWrapper>
                }

                {type.user &&
                    <FormWrapper onSubmit={submitForm}>
                        <H1>Register</H1>
                        <H5 style={{ textAlign: 'center', marginTop: '0' }}>as a person</H5>
                        <Hr></Hr>


                        {/* <H5 style={{ textAlign: 'center' }}>Register with:</H5>
                        <Other />
                        <H5 style={{ textAlign: 'center', margin: '0' }}>Or with:</H5> */}

                        <Fields>
                            <InputWrapper smallR>
                                <LabelCss htmlFor="fname">First Name<span>*</span></LabelCss>
                                <InputCss
                                    placeholder="Enter your first name"
                                    id='fname'
                                    type="text"
                                    name='fname'
                                    required
                                    value={access.fname}
                                    onChange={(e) =>
                                        setAccess({
                                            ...access,
                                            fname: e.target.value
                                        })
                                    }
                                >
                                </InputCss>
                            </InputWrapper>

                            <InputWrapper smallL>
                                <LabelCss htmlFor="lname">Last Name<span>*</span></LabelCss>
                                <InputCss
                                    placeholder="Enter your last name"
                                    id='lname'
                                    type="text"
                                    name='lname'
                                    required
                                    value={access.lname}
                                    onChange={(e) =>
                                        setAccess({
                                            ...access,
                                            lname: e.target.value
                                        })
                                    }
                                >
                                </InputCss>
                            </InputWrapper>
                        </Fields>

                        <InputWrapper full>
                            <LabelCss htmlFor="email">E-mail<span>*</span></LabelCss>
                            <InputCss
                                placeholder="Enter your e-mail"
                                id='email'
                                type="mail"
                                name='email'
                                required
                                value={access.email}
                                onChange={(e) =>
                                    setAccess({
                                        ...access,
                                        email: e.target.value
                                    })
                                }
                            >
                            </InputCss>
                        </InputWrapper>

                        <InputWrapper full>
                            <PswWrapper>
                                <LabelCss htmlFor='psw'>Password<span>*</span></LabelCss>
                                <InputCss
                                    placeholder="Enter your password"
                                    id='psw'
                                    type={showPassword ? 'password' : 'text'}
                                    name='password'
                                    required
                                    value={access.psw}
                                    onChange={(e) =>
                                        setAccess({
                                            ...access,
                                            psw: e.target.value
                                        })
                                    }
                                ></InputCss>
                                <EyeWrapper onClick={() => handleShowPassword()}>
                                    {showPassword ?
                                        <i className="far fa-eye-slash"></i>
                                        :
                                        <i className="far fa-eye"></i>
                                    }
                                </EyeWrapper>
                            </PswWrapper>
                        </InputWrapper>


                        <InputWrapper full>
                            <PswWrapper>
                                <LabelCss htmlFor='pswc'>Confirm Password<span>*</span></LabelCss>
                                <InputCss
                                    placeholder="Enter your password again"
                                    id='pswc'
                                    type={showPassword ? 'password' : 'text'}
                                    name='password'
                                    required
                                    value={access.confirmPsw}
                                    onChange={(e) =>
                                        setAccess({
                                            ...access,
                                            confirmPsw: e.target.value
                                        })
                                    }
                                ></InputCss>
                                <EyeWrapper onClick={() => handleShowPassword()}>
                                    {showPassword ?
                                        <i className="far fa-eye-slash"></i>
                                        :
                                        <i className="far fa-eye"></i>
                                    }
                                </EyeWrapper>
                            </PswWrapper>
                        </InputWrapper>

                        <Label>
                            <Checkbox
                                checked={checked}
                                onChange={handleCheckboxChange}
                            />
                            <CheckTxt>I agree with the
                                <Link to='/dashboard/legal'>Terms</Link> and the
                                <Link to='/dashboard/legal'>Privacy Policy</Link>
                            </CheckTxt>
                        </Label>

                        {checkErr &&
                            <Err>Please accept the <span>Terms</span> and <span>Privacy Policy!</span></Err>
                        }
                        {matchErr &&
                            <Err>Passwords does't <span>match!</span></Err>
                        }

                        <BtnWrapper>
                            {loading ?
                                <Btn disabled>
                                    <img src={spin} alt='loading'></img>
                                    Loading...
                                </Btn>
                                :
                                <Btn type='submit'>Register</Btn>
                            }
                        </BtnWrapper>

                        <H5>
                            You already have an account?
                            <LinkCss to='/login' >Log in here!</LinkCss >
                        </H5>
                        <H6>all fields marked with * are required</H6>
                    </FormWrapper>
                }

            </RightSideAuth>

        </AuthWrapper>
    );
}
export default Register;