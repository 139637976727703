import React, { memo, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useLocation } from 'react-router';

// PACKAGES
import styled from 'styled-components';

// MIDDLEWARES
import {
  NonPrivateRoute,
  PrivateRoute,
  RedirectToSignIn
} from './Utils';

// COMPONENTS
import NavBar from '../Dashboard/DashboardComponents/NavBar/NavBar';
import Search from '../Dashboard/DashboardComponents/NavBar/NavBarComponents/Search';
import SideBar from '../Dashboard/DashboardComponents/SideBar/SideBar';
import Footer from '../Dashboard/DashboardComponents/Footer/Footer';
import CookieBanner from '../Dashboard/DashboardComponents/CookieBanner/CookieBanner';
import GoogleAds from '../Dashboard/DashboardComponents/GoogleAds/GoogleAds';

// PAGES
import LandingPage from '../LandingPage/LandingPage';
import LogIn from '../Auth/LogIn/LogIn';
import Register from '../Auth/Register/Register';
import ResetPsw from '../Auth/ResetPsw/ResetPsw';
import ConfirmEmail from '../Auth/ConfirmEmail/ConfirmEmail';
import ResendConfirmEmail from '../Auth/ResendEmail/ResendEmail';
import Home from '../Dashboard/Pages/Home/Home';
import SearchPage from '../Dashboard/Pages/Search/SearchPage';
import Profile from '../Dashboard/Pages/Profile/Profile';
import AboutUs from '../Dashboard/DashboardComponents/Footer/FooterLinks/AboutUs';
import ContactUs from '../Dashboard/DashboardComponents/Footer/FooterLinks/ContactUs';
import Legal from '../Dashboard/DashboardComponents/Footer/FooterLinks/Legal';
import Prices from '../Dashboard/Pages/Prices/Prices';
import CodeComponent from '../Dashboard/Pages/CodeComponent/CodeComponent';
// import Email from '../Dashboard/EmailTemplates/NewUser/NewUser';
import Canceled from '../Dashboard/Pages/Prices/Canceled';
import Success from '../Dashboard/Pages/Prices/Success';
import Gradients from '../Dashboard/Pages/Gradients/Gradients';

//LAZY PAGES
// import {
//   LandingPage,
//   LogIn,
//   Register,
//   ResetPsw,
//   ConfirmEmail,
//   ResendConfirmEmail,
//   Home,
//   SearchPage,
//   Profile,
//   AboutUs,
//   ContactUs,
//   Legal,
//   Prices,
//   CodeComponent,
//   Canceled,
//   Success,
// } from './LazyImports';


// REDUX
import { useSelector } from 'react-redux';

const GeneralWrapper = styled.div`
    background: var(--background2);
`;

const WrapperRightSide = styled.div`
    width:calc(100% - 250px);
    /* height:calc(100vh - 80px); */
    min-height: calc(100vh - 135px);
    margin-left:250px;
    margin-top: 80px;
    padding:40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
        @media (max-width: 775px) {
            width:100%;
            margin-left:0;
        };
`;


const Routes = memo(function Routes() {
  const location = useLocation();
  const isAuthentificated = useSelector(({ auth }) => auth.isAuthentificated);
  // const loading = useSelector(({ auth }) => auth.isLoading);
  // const profile = useSelector((state) => state.auth.user);
  const [goTo, setGoTo] = useState(false);

  // console.log('AUTH isAuth', isAuthentificated)
  // console.log('AUTH Loading', loading)
  // console.log('AUTH Profile', profile)
  // console.log('REDIRECT', goTo)

  const NonPrivate = [
    {
      exact: true,
      path: '/',
      component: <Home />,
    },
    {
      path: '/login',
      component: <LogIn />
    },
    // {
    //   path: '/register',
    //   component: <Register />
    // },
    {
      path: '/forgotPsw',
      component: <ResetPsw />
    },
    {
      path: '/confirmEmail',
      component: <ConfirmEmail />
    },
    {
      path: '/resendConfirmEmail',
      component: <ResendConfirmEmail />
    },
  ];

  const Private = [
    {
      path: '/dashboard/profile',
      component: <Profile />,
      exact: false
    },
    // {
    //   path: '/dashboard/code',
    //   component: <CodeComponent props={location.state} />,
    //   exact: false
    // },
  ];

  const General = [
    {
      exact: true,
      path: '/',
      component: <Home />,
    },
    {
      exact: true,
      path: '/dashboard',
      component: <Home flag={location.state} />,
    },
    {
      path: '/dashboard/content/:path',
      component: <Home flag={location.state} />,
    },
    {
      path: '/dashboard/code/:path',
      component: <CodeComponent props={location.state} />,
    },
    {
      path: '/dashboard/search',
      component: <SearchPage flag={location.state} />,
    },
    {
      path: '/dashboard/price',
      component: <Prices />,
    },
    {
      path: '/dashboard/aboutUs',
      component: <AboutUs />,
    },
    {
      path: '/dashboard/contactUs',
      component: <ContactUs />,
    },
    {
      path: '/dashboard/legal',
      component: <Legal />,
    },
    {
      path: '/dashboard/success.html',
      component: <Success />,
    },
    {
      path: '/dashboard/canceled.html',
      component: <Canceled />,
    },
    {
      path: '/dashboard/free/gradients',
      component: <Gradients />,
    },
  ];

  const changeStatus = () => {
    setGoTo(true)
  };


  // if (!goTo && !isAuthentificated) {
  if (goTo && !isAuthentificated) {
    return <LandingPage change={changeStatus} />
  } else {
    return (
      <Switch>
        <Route>
          <NavBar />
          <Search />
          <SideBar />
          <GoogleAds />

          <Switch>
            {NonPrivate.map((route, i) =>
              <NonPrivateRoute exact={route.exact} key={i} path={route.path}>
                {route.component}
              </NonPrivateRoute>
            )}

            {Private.map((route, i) =>
              <PrivateRoute exact={route.exact} key={i} path={route.path}>
                {route.component}
              </PrivateRoute>
            )}

            {General.map((route, i) =>
              <Route key={i} exact={route.exact} path={route.path}>
                {route.component}
              </Route>
            )}

            <RedirectToSignIn path="/register">
              <Register />
            </RedirectToSignIn>

            <Route path="*">
              <GeneralWrapper>
                <WrapperRightSide>
                  <h1 style={{ fontSize: "200px", color: 'var(--text2)' }}> 404 </h1><br></br>
                  <h1 style={{ fontSize: "20px", color: 'var(--text2)' }}> Page not found </h1><br></br>
                </WrapperRightSide>
              </GeneralWrapper>
            </Route>
          </Switch>

          <Footer />
          <CookieBanner />
        </Route >

        <Route path="*">
          <h1 style={{ fontSize: "200px", color: 'var(--text2)' }}>404</h1>
        </Route>
      </Switch >
    );
  }
});

export default Routes;
