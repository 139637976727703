import React from 'react';
import { Link } from 'react-router-dom';

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
import Admin from '../../Pages/Admin/Admin';

// REDUX
import { useSelector } from 'react-redux';

const BtnLink = styled(Link)`
    /* position: absolute;
    bottom: 10px;
    width: calc(100% - 40px); */
    padding: 10px 30px;
    border:none;
    outline: none;
    background: var(--color4);
    color: var(--text5);
    font-weight: bold;
    text-decoration: none;
    margin: 40px 0;
    opacity: 0;
    border-radius: var(--radius);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
        >i{
            /* margin-left: 20px;
            font-size:20px; */
            color: var(--text5);   
        }
        &:hover{
            opacity: .7;
            transition: .5s ease-in-out;
        }
        &:active{
                transform:translateY(2px) ;
        }  
`;

const Shadow = styled.div`
    position: absolute;
    /* bottom: -30%; */
    /* top: 0; */
    left: 0;
    width: 100%;
    /* height: 55%; */
    height: 60%;
    padding: 10px 20px;
    opacity: 0;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; */
    /* transition: all calc(700ms * 1.5) cubic-bezier(0.19, 1, 0.22, 1); */
    transition: 0.25s;
    background: var(--background3);
    /* background: #292d38d6; */
    /* backdrop-filter: var(--blur); */
    box-shadow: 0 0 20px rgb(0 0 0 / 40%);
    /* clip-path: polygon(0 0, 100% 8%, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 16%, 100% 100%, 0 100%); */
    /* clip-path: polygon(3% 0, 100% 14%, 100% 100%, 0% 100%); */
        >h2:nth-child(1){
            text-align: center;
            /* position: absolute;
            top:30px;
            transform:translate(0,-50%); */
            margin-top: 20px;
            opacity: 1;
            transition: opacity .4s;
            transition-delay: .1s;
            /* text-shadow: 2px 2px 3px rgb(0 0 0 / 90%); */
        }
        >h3:nth-child(2){
            /* margin:10px;
            position: absolute;
            top:20%;
            transform:translate(0,-50%); */
                /* margin-bottom: 15px; */
            opacity: 0;
        }
`;

const Image = styled.img`
    width: 100%;
    transition: 0.25s;
`;

const Info = styled.div`
   margin-bottom: 10px;
   opacity: 0;
`;

const CardWrapper = styled.div`
    position: relative;
    width: 500px;
    height: 280px;
    /* border-radius: var(--radius); */
    margin: 10px 0px;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    /* box-shadow: -50px 50px 150px -30px #101117; */
    /* box-shadow: -30px 30px 40px -10px rgba(16,17,23,0.6); */
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    overflow: hidden;
        >h1{
            position: absolute;
            top: 10px;
            left: 10px;
            color: black;
        }
        &:hover{
            /* transform: scale(1.25);
            z-index:1; */
            >h1{
                display: none;
            }
            ${Shadow} {
                bottom: 0;
                opacity: 1;
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
                background: var(--background5);
                backdrop-filter: var(--blur);
            }
            ${BtnLink} {
                opacity: 1;
                transition: opacity .4s;
                transition-delay: .1s;
            }
            ${Info} {
                opacity: 1;
                transition: opacity .4s;
                transition-delay: .1s;
            }
            >div>h2:nth-child(1){
                display: none;
                opacity: 0;
                transition: opacity .4s;
                transition-delay: .1s;
            }
            >div>h3:nth-child(2){
                opacity: 1;
                transition: opacity .4s;
                transition-delay: .1s;
                font-weight: bold;
            }
            ${Image} {
                transition: all 0.3s cubic-bezier(.25,.8,.25,1);
                transform: scale(1.25);
            }
        }  
`;


const CardComponent = ({ content, createRef }) => {
    const profile = useSelector((state) => state.auth.user);

    // OPEN A NEW TAB WITH PERSISTENT STATE
    // const openInNewTab = (url) => {
    //     const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    //     if (newWindow) newWindow.opener = null
    // }

    // console.log('IN CARD',content.ContentID)

    return (
        <CardWrapper ref={createRef}>
            {(profile !== undefined && profile.accesLevel === 'admin') &&
                <Admin
                    content={content}
                />
            }
            <Image src={content.PosterURL} alt={content.Name}></Image>
            {/* <h1>{content.Name}</h1> */}
            <Shadow>
                <h2>{content.Name.length > 18 ? content.Name.slice(0, 18) + '...' : content.Name} </h2>
                <h3>{content.Name}</h3>

                <Info>
                    <h5><span>For:</span> {content.Technology}</h5>
                    <h5><span>Theme:</span> {content.Theme}</h5>
                    <h5><span>Made with:</span> {content.MadeWith}</h5>
                </Info>

                {profile === undefined ?
                    <BtnLink to='/login'>
                        See the code
                        {/* <i className="fas fa-arrow-circle-right"></i> */}
                    </BtnLink>
                    :
                    profile.Stripe[0].payStatus === 'paid' ?
                        <BtnLink
                            to={{
                                pathname: '/dashboard/code/' + content.ContentID,
                                state: { content, flag: 'content' }
                            }}
                            // target="_blank"
                            rel="noopener noreferrer"
                        //    onClick={() => openInNewTab('/dashboard/code/' + content.ContentID)}
                        >
                            See the code
                            {/* <i className="fas fa-arrow-circle-right"></i> */}
                        </BtnLink>
                        :
                        <BtnLink to='/dashboard/price'>
                            Choose a plan
                            {/* <i className="fas fa-arrow-circle-right"></i> */}
                        </BtnLink>
                }

            </Shadow>
        </CardWrapper>

    );
};

export default CardComponent;