import React from 'react';

// PACKAGES
import styled, { keyframes } from 'styled-components';


const loading = keyframes`
    0% {
        background: var( --background4);
    }

    100% {
        background: var( --background3);
    }
`;

const CardWrapper = styled.div`
    position: relative;
    width: 300px;
    height: 350px;
    /* border-radius: var(--radius); */
    margin: 10px 0px;
    padding: 10px;
    background: var(--background3);
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    /* box-shadow: -50px 50px 150px -30px #101117; */
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
        >h1{
            position: absolute;
            top: 10px;
            left: 10px;
            color: black;
        }
`;

const Sample = styled.div`
    position: relative;
    width: 250px;
    height: 250px;
    border-radius: 250px;
    background-color: ${props => props.color};
    background-image: ${props => props.image};
    background-blend-mode: ${props => props.blend};
    box-shadow: inset 6px 4px 3px 5px rgb(0 0 0 / 40%);
    cursor: zoom-in;
    animation: ${loading} 1s linear infinite alternate;
`;

const Info = styled.div`
    position: relative;
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
        >div:nth-child(1){
            width: 150px;
            border-radius:var(--radius);
            height: 20px;
            margin-top: 20px;
            opacity: 1;
            transition: opacity .4s;
            transition-delay: .1s;
            background: var(--background4);
        }
        >div:nth-child(2){
            width: 100px;
            border-radius:var(--radius);
            height: 15px;
            margin-top: 10px;
            opacity: 1;
            transition: opacity .4s;
            transition-delay: .1s;
            background: var(--background4);
        }
        >div:nth-child(3){
            position: absolute;
            right: 10px;
            bottom: 5px;
            width: 80px;
            border-radius:var(--radius);
            height: 15px;
            margin-top: 10px;
            opacity: 1;
            transition: opacity .4s;
            transition-delay: .1s;
            background: var(--background4);
        }
`;


const GradientCardSkeleton = () => {

    return (
        <CardWrapper>
            <Sample />
            <Info>
                <div></div>
                <div></div>
                <div></div>
            </Info>
        </CardWrapper>

    );
};

export default GradientCardSkeleton;