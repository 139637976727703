import React, { useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// PACKAGES
import styled from 'styled-components';

// ASSETS

// COMPONENTS
import SideBarButton from './Components/SideBarNavLinks';
// import Anchor from './Components/SideBarAnchor';
// import LogOutBtn from './Components/LogOutBtn';
import MainLogo from '../../../LandingPage/LandingPageComponents/MainLogo/MainLogo';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { handleSideBar } from '../../../Store/Actions/otherActions';


const NegativSidebar = styled.div`
    z-index: 2;
    position: fixed;
    left: 0;
    right: 0;
    top:0;
    width: 100vw;
    height: 100vh;
    pointer-events: auto;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: ${({ open }) => open ? '1' : '0'};
    transition: opacity 224ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        @media (max-width: 775px) {
            pointer-events: ${({ open }) => open ? 'auto' : 'none'};
            };
        @media (min-width: 775px) {
                background-color: none;
                pointer-events: none;
            };
`;

const SideBarWrapper = styled.div`
    position: fixed;
    /* margin: 20px; */
    /* top: 20px;
    left: 20px; */
    top: 0;
    width:250px;
    /* height:calc(100vh - 80px); */
    height:100vh;
    padding: 80px 0 0 10px;
    display:block;
    z-index: 2;
    transition: transform 0.3s ease-in-out;
        @media (max-width: 775px) {
            transform: ${({ open }) => open ? 'translateX(0%)' : 'translateX(-100%)'};
            /* box-shadow: 0 16px 38px -12px rgba(0, 0, 0, .56), 
            0 4px 25px 0 rgba(0, 0, 0, .12), 
            0 8px 10px -5px rgba(0, 0, 0, .2); */
            padding: 10px 0 0 10px;
        };
`;

const SideBarBody = styled.div`
    border-radius: var(--radius);
    /* background: var(--color5); */
    /* background: var(--background3); */
    background-image: linear-gradient(to top,#28283e 0%,#52527b);
    height:calc(100vh - 90px);
    overflow:hidden;
            @media (max-width: 775px) {
                box-shadow: 0 16px 38px -12px rgba(0, 0, 0, .56), 
                0 4px 25px 0 rgba(0, 0, 0, .12), 
                0 8px 10px -5px rgba(0, 0, 0, .2);
                height:calc(100vh - 20px);
            };
`;

const LogoWrapper = styled.div`
    width:100%;
    padding: 0px 20px;
    display:flex;
    align-items:center;
    justify-content:center;
        >a{
            text-decoration: none;
        }
`;

// const Logo = styled.img`
//     height:100px;
//     width:200px;
// `;

const UnorderList = styled.ul`
    position: relative;
    height:calc(100vh - 280px);
    list-style:none;
    padding:0;
    display:block;
    overflow-y: hidden;
    transition: all .5s ease;
        &::-webkit-scrollbar-thumb{
            visibility: hidden;
        };
        &:hover {
            overflow-y: auto;
            overflow-y: overlay;
            &::-webkit-scrollbar-thumb{
                visibility: visible;
            };
        };
        @media (max-width: 775px) {
            height:calc(100vh - 220px);
        };
`;

const Footer = styled.ul`
    list-style:none;
    margin:0;
    padding: 10px 0 0;
    display:flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    -webkit-box-shadow: 0px -10px 14px -10px rgba(0,0,0,0.22); 
    box-shadow: 0px -10px 14px -10px rgba(0,0,0,0.22);
        >li>a{
            text-decoration: none;
            color: var(--text2);
            font-size: var(--fontM);
                &:hover{
                    color: var(--text3);
                }
        }
        >span{
            color: var(--text2);
            padding: 0 5px;
        }
`;

const SubFooter = styled.div`
   display: flex;
   justify-content: center;
   padding-top: 15px;
   color: var(--text2);
   font-size: var(--fontS);
`;

const HamWrapper = styled.div`
    position: absolute;
    top: 20px;
    right: 15px;
    cursor: pointer;
        >i{
            font-size: 18px;
            color: #2a2a40;
            margin-left: 25px;
        }
        @media (min-width: 775px) {
            display: none;
        };
`;

// const ResponsiveLinks = styled.div`
//     @media (min-width: 775px) {
//             display: none;
//         };
// `;

const makeButtons = [
    {
        to: '/dashboard',
        icon: <i className="fas fa-bullhorn"></i>,
        title: 'Latests',
        span: 'New'
    },
    {
        to: '/dashboard/free',
        icon: <i className="fab fa-freebsd"></i>,
        title: 'Free stuff',
        subBtn: [
            'Gradients',
            'Fonts',
        ]
    },
    {
        to: '/dashboard/content/components',
        icon: <i className="fas fa-cubes"></i>,
        title: 'Components',
        subBtn: [
            'Alerts',
            'Avatars',
            'Buttons',
            'Badges',
            'Cards',
            'Carousels',
            'Checkboxes',
            'Dropdowns',
            'Forms',
            'Footers',
            'Images',
            'Inputs',
            'Loading',
            'Menus',
            'Modals',
            'Navbar',
            'Notifications',
            'Popovers',
            'Progress',
            'Radios',
            'Scrollbar',
            'Search',
            'Shadows',
            'Sidebars',
            'Sliders',
            'Toggle Buttons',
            'Tooltips',
            'Text'
        ]
    },
    {
        to: '/dashboard/content/pages',
        icon: <i className="far fa-images"></i>,
        title: 'Pages',
        subBtn: [
            'Error',
            'Landing',
            'LogIn',
            'Pricing',
            'Profile',
            'Register'
        ]
    },
    {
        to: '/dashboard/content/animations',
        icon: <i className="fas fa-rocket"></i>,
        title: 'Animations'
    },
    {
        to: '/dashboard/content/charts',
        icon: <i className="far fa-chart-bar"></i>,
        title: 'Charts'
    },
    {
        to: '/dashboard/content/calendar',
        icon: <i className="far fa-calendar-alt"></i>,
        title: 'Calendar'
    },
    {
        to: '/dashboard/content/grid system',
        icon: <i className="fas fa-grip-horizontal"></i>,
        title: 'Grid System'
    },
    {
        to: '/dashboard/content/icons',
        icon: <i className="fas fa-icons"></i>,
        title: 'Icons'
    },
    {
        to: '/dashboard/content/maps',
        icon: <i className="fas fa-map-marker-alt"></i>,
        title: 'Maps'
    },
    {
        to: '/dashboard/content/pagination',
        icon: <i className="fas fa-sort-numeric-down"></i>,
        title: 'Pagination'
    },
    {
        to: '/dashboard/content/tables',
        icon: <i className="fas fa-table"></i>,
        title: 'Tables'
    },
    // {
    //     to: '/dashboard/success',
    //     icon: <i className="fas fa-table"></i>,
    //     title: 'Success'
    // },
    // {
    //     to: '/dashboard/email',
    //     icon: <i className="fas fa-table"></i>,
    //     title: 'E-mail'
    // },
];

// const makeAnchor = [
//     {
//         href: 'https://nissolinocorsi.com/corsi-online/',
//         icon: '<HomeIcon />',
//         title: 'Videocorsi',
//     },
// ];

// const navBarAnchor = [
//     {
//         href: "https://nissolinocorsi.com/membri/",
//         png: <i className="fas fa-users"></i>,
//         title: 'Membri',
//     },
//     {
//         href: "https://www.nissolinocorsi.it/notizie/",
//         png: <i className="far fa-newspaper"></i>,
//         title: 'News',
//     },
//     {
//         href: "https://www.nissolinocorsi.it/contatti/",
//         png: <i className="far fa-id-card"></i>,
//         title: 'Contatti',
//     },
// ];


const SideBar = () => {
    const dispatch = useDispatch();
    const open = useSelector(({ other }) => other.handleSideBar);
    const today = new Date();
    const [year] = useState(today.getFullYear());

    const handleClick = () => {
        dispatch(handleSideBar(!open));
    };

    useLayoutEffect(() => {
        function updateSize() {
            if (window.innerWidth > 775) {
                if (open === true) {
                    dispatch(handleSideBar(false));
                }
            }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, [dispatch, open]);

    return (
        <>
            <NegativSidebar
                open={open}
                onClick={handleClick}
            />

            <SideBarWrapper
                open={open}
            >
                <SideBarBody>
                    <HamWrapper onClick={handleClick}>
                        <i className="fas fa-align-center"></i>
                    </HamWrapper>

                    <LogoWrapper>
                        {/* <Logo src={logo} alt='logo'></Logo> */}
                        <Link to='/dashboard'><MainLogo /></Link>
                    </LogoWrapper>

                    {/* <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '20px', color: '#fff', fontSize: '30px', margin: '10px 0 10px 0' }}>
                        <i style={{ color: '#fff', marginRight: '10px' }} className="fab fa-react"></i>
                        <h3 style={{ fontWeight: 'bold' }}>React</h3>
                    </div> */}


                    <UnorderList>
                        {makeButtons.map((btn, i) =>
                            <SideBarButton
                                key={i}
                                index={i}
                                to={btn.to}
                                icon={btn.icon}
                                title={btn.title}
                                span={btn.span}
                                subBtn={btn.subBtn}
                                handleClick={handleClick}
                            />
                        )}

                        {/* {makeAnchor.map((anchor, i) =>
                        <Anchor
                            key={i}
                            href={anchor.href}
                            icon={anchor.icon}
                            title={anchor.title}
                        />
                    )} */}

                        {/* <ResponsiveLinks>
                            {navBarAnchor.map((anchor, i) =>
                                <Anchor
                                    key={i}
                                    href={anchor.href}
                                    png={anchor.png}
                                    title={anchor.title}
                                />
                            )}
                        </ResponsiveLinks> */}

                        {/* <LogOutBtn
                        name={'Delogare'}
                        icon={<i className="fas fa-power-off"></i>}
                        onClick={(handleModal) => handleModal}
                    /> */}
                    </UnorderList>

                    <Footer>
                        <li><Link to='/dashboard/aboutUs'>About Us</Link></li><span>|</span>
                        <li><Link to='/dashboard/contactUs'>Contact Us</Link></li><span>|</span>
                        <li><Link to='/dashboard/legal'>Legal</Link></li>
                    </Footer>

                    <SubFooter>
                        © 2020-{year}, all rights reserved
                    </SubFooter>
                </SideBarBody>
            </SideBarWrapper>
        </>
    );
};

export default SideBar;
