import axios from 'axios';
axios.defaults.withCredentials = true;

export const fetchUser = () => (dispatch, getState) => {
  dispatch({ type: 'FETCH_USER' });

  axios
    .get(`${process.env.REACT_APP_URL_BACKEND}/auth/checkUser`,)
    .then((res) => {
      // console.log("CHECK USER", res);
      dispatch({
        type: 'FETCHED_USER',
        payload: res.data,
      });
    })
    .catch((err) => {
      // console.log(err);
      dispatch(logOut());
    });
};

export const registerUser = (access, checked) => (dispatch, getState) => {
  // console.log("REGISTER", access, checked)

  dispatch({ type: 'LOADING_REGISTER' });

  const body = {
    access, checked
  };

  axios
    .post(`${process.env.REACT_APP_URL_BACKEND}/auth/register`, body)
    .then((res) => {
      // console.log("REGISTER_SUCCESFUL", res);
      dispatch({
        type: 'REGISTER_SUCCESFUL',
        payload: res.data.message,
      });
      dispatch(notifHandler('success', 'Successfully registered!'));
    })
    .catch((err) => {
      // console.log("REGISTER ERR", err.response.data.message);
      if (err) {
        dispatch({
          type: 'REGISTER_ERROR',
          payload: err.response.data.message,
        });
        dispatch(notifHandler('error', err.response.data.message));
      }
    });
};

export const loginUser = (access) => (dispatch) => {
  dispatch({ type: 'LOADING_SIGNIN' });

  const loginCredentials = {
    email: access.email,
    psw: access.psw,
  };

  // console.log('LOGIN', loginCredentials)
  axios
    .post(`${process.env.REACT_APP_URL_BACKEND}/auth/login`, loginCredentials)
    .then((res) => {
      // console.log("LOGIN RES", res);
      dispatch({
        type: 'LOGIN_SUCCESFUL',
        payload: res.data.user,
      });
      // dispatch(fetchUser());
      // dispatch(notifHandler('success', 'Successfully logged in!'));
    })
    .catch((err) => {
      // console.log("LOGIN ERR", err.response.data.message);
      // console.log("LOGIN ERR", err);
      if (err) {
        // console.log("LOGIN ERR", err.response.data.message);
        dispatch({
          type: 'LOGIN_ERROR',
          payload: err.response.data.message,
        });
        setTimeout(() => {
          dispatch({ type: 'LOGIN_ERROR' });
        }, 15000);
        if (err.response.data.message === 'This e-mail is not activated!') {
          dispatch(notifHandler('warning', err.response.data.message));
        } else {
          dispatch(notifHandler('error', err.response.data.message));
        }
      }
    });
};

export const confirmEmail = (access) => (dispatch, getState) => {
  dispatch({ type: 'LOADING' });

  const loginCredentials = {
    email: access.email,
    nr: access.nr,
  };

  axios
    .post(`${process.env.REACT_APP_URL_BACKEND}/auth/confirmEmail`, loginCredentials)
    .then((res) => {
      // console.log("CONFIRM_EMAIL", res)
      dispatch({
        type: 'CONFIRM_EMAIL',
        payload: res.data.message,
      });
      setTimeout(() => {
        dispatch({ type: 'CONFIRM_EMAIL' });
      }, 5000);
      dispatch(notifHandler('success', res.data.message));
    })
    .catch((err) => {
      // console.log(err.response.data);
      dispatch({ type: 'ERROR' });
      dispatch(notifHandler('error', err.response.data.message));
    });
};

export const resendEmail = (access) => (dispatch, getState) => {
  // dispatch({ type: 'LOADING' });

  const loginCredentials = {
    email: access.email
  };

  axios
    .post(`${process.env.REACT_APP_URL_BACKEND}/auth/resendConfirmEmail`, loginCredentials)
    .then((res) => {
      // console.log("RESEND_EMAIL", res)
      dispatch({
        type: 'RESEND_EMAIL',
        payload: res.data.message
      });
      setTimeout(() => {
        dispatch({ type: "RESEND_EMAIL" });
      }, 5000);
      // dispatch({ type: 'SUCCESS' });
      dispatch(notifHandler('success', res.data.message));
    })
    .catch((err) => {
      // dispatch({ type: 'ERROR' });
      // console.log('RESEND_EMAIL_ERR', err.response.data);
      if (err.response.data.message === 'This e-mail is already active!') {
        dispatch(notifHandler('warning', err.response.data.message));
      } else {
        dispatch(notifHandler('error', err.response.data.message));
      }
    });
};

export const resetPsw = (access) => (dispatch, getState) => {
  const loginCredentials = {
    email: access.email,
  };

  axios
    .post(`${process.env.REACT_APP_URL_BACKEND}/auth/resetPassword`, loginCredentials)
    .then((res) => {
      // console.log("RESET_PSW", res);
      dispatch({
        type: 'RESET_PSW',
        payload: res.data.message,
      });
      dispatch(notifHandler('success', res.data.message));
      setTimeout(() => {
        dispatch({ type: 'RESET_PSW' });
      }, 4000);
    })
    .catch((err) => {
      // console.log(err.response.data);
      dispatch(notifHandler('error', err.response.data.message));
    });
};

export const openModal = (props) => (dispatch) => {
  dispatch({
    type: 'OPEN_MODAL',
    payload: props,
  });
};

export const logOut = () => (dispatch, getState) => {
  const profile = getState().auth.user;

  const body = {
    profile: profile
  }
  axios
    .post(`${process.env.REACT_APP_URL_BACKEND}/auth/logOut`, body)
    .then((res) => {
      // console.log("FETCH COMP", res.data)
      dispatch({
        type: 'LOGOUT_SUCCESFUL',
        // payload: res.data,
      });
    })
    .catch((err) => {
      // console.log(err);
    });
  // return {
  //   type: 'LOGOUT_SUCCESFUL',
  // };
};

const notifHandler = (flag, msg) => (dispatch) => {
  const obj = {
    flag: flag,
    msg: msg,
  };
  dispatch({
    type: 'NOTIF_HANDLER_AUTH',
    payload: obj,
  });
  setTimeout(() => {
    dispatch({
      type: 'NOTIF_HANDLER_AUTH',
      payload: undefined,
    });
  }, 3000);
};

// export const tokenConfig = (getState) => {
//   const token = getState().auth.token;

//   // Headers
//   const config = {
//     headers: {
//       'Content-type': 'application/json',
//     },
//   };

//   if (token) {
//     config.headers['x-access-token'] = token;
//   }

//   return config;
// };
