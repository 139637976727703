import React from 'react';

// COMPONENTS
import EditModal from './AdminComponents/EditModal';
import DeleteModal from './AdminComponents/DeleteModal';


const Admin = ({ content }) => {

    return (
        <>
            <EditModal content={content} />
            <DeleteModal content={content} />
        </>
    );
};

export default Admin;
