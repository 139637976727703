import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';

// PACKEGES
import styled from 'styled-components';
// import Select from 'react-select';

// ASSETS
// import loadingSpinner from '../../../Assets/loadingBtn2.gif';

// COMPONENTS
// import Title from '../../DashboardComponents/PagesTitles/PagesTitles';
import AddModal from './ProfileComponents/AddModal';
import DeleteModal from './ProfileComponents/DeleteModal';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
// import { updateProfile, changeProfilePicture, deleteProfilePicture } from '../../../Store/Actions/dashboardActions';
// import { stripeBillingPortal } from '../../../Store/Actions/stripeActions';
import { reciveTeammates, deleteOldTeam } from '../../../Store/Actions/teammateActions';

// SHARE STYLE
// import { WrapperRightSide } from '../ShareStyle/ShareStyle';
import {
    Hr,
    // Btn
} from '../ShareStyle/FormStyle';


const TeamWrapper = styled.div`
    width: 100%;
    max-width: 1040px;
    background-image: linear-gradient(to top,#28283e 0%,#52527b);
    border-radius: var(--radius);
    padding: 20px;
    margin: 20px auto;
    text-align: left;
    color: var(--text4);
        >hr{
            width: 100%;
            margin: 10px auto;
        }
        @media (max-width: 1320px) {
            max-width: 600px;
        }
`;

const TeamHeader = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
        >h5{
            font-weight: bold;
            >span{
                margin-left: 5px;
                color: var(--text3);
            }
        }
`;

const TeamBody = styled.div`
    position: relative;
    width: 100%;
    max-height: calc(100vh - 300px);
    overflow-y: hidden;
    transition: all .5s ease;
        &::-webkit-scrollbar-thumb{
            visibility: hidden;
        };
        &:hover {
            overflow-y: auto;
            overflow-y: overlay;
            &::-webkit-scrollbar-thumb{
                visibility: visible;
            };
        };
    
`;

const EmptyTeam = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 40px auto;
        >i{
            font-size: 60px;
            color: var(--text2);
        }
        >h3{
            color: var(--text2);
        }
`;

const Team = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin: 40px auto; */
        >i{
            font-size: 60px;
            color: var(--text2);
        }
        >h3{
            color: var(--text2);
        }
`;

const TeamRow = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 10px 10px;
    border-radius: var(--radius);

        &:hover{
            background-color: var(--backLanding);
        }
`;

const Info = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
        >h4{
            font-weight: bold;
            font-size: 18px;
        }
        >h5{
            font-weight: 100;
            font-size: 14px;
        }
`;

// const Btn = styled.button`
//      /* width: 25%; */
//      /* height:30px; */
//      padding: 5px 10px;
//      display:flex;
//      align-items: center;
//      justify-content:center;
//      border:none;
//      /* color:var(--text5); */
//      color: var(--text2);
//      border-radius: var(--radius);
//      /* margin-top: 40px;
//      margin-right:10px;
//      margin-bottom:10px; */
//      cursor: pointer;
//      transition: all var(--transition) ease;
//      /* background: var(--color4); */
//      /* background: var(--background2); */
//      background: transparent;
//      outline: none;
//      font-weight: bold;
//      /* letter-spacing: 2px; */
//      font-size: 12px;
//         >i{
//             font-size: 15px;
//             padding-right: 10px;
//             color: var(--text2);
//             transition: color var(--transition) ease-in-out;
//         }
//          &:hover{
//             /* opacity: 0.7; */
//             color: var(--text4);
//              >i{
//                 color: var(--text4); 
//              }
//          }
//  `;

const OtherLogo = styled.div`
    position:relative;
    height: 50px;
    width: 50px;
    border-radius: var(--radius);
    display:flex;
    align-items:center;
    justify-content:center;
    overflow:hidden;
    background: var(--background3);
    color: var(--text4);
    margin-right: 10px;
    font-size: 22px;
    cursor: pointer;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);    
`;

const LogoDisplay = styled.div`
    position:relative;
    height: 50px;
    width: 50px;
    border-radius: var(--radius);
    overflow:hidden;
    color: var(--text4);
    margin-right: 10px;
        >img{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            height: 50px;
            width: 50px;
            border-radius: var(--radius);
            border: none;
            background-image: linear-gradient(to top,#28283e 0%,#52527b);
        }
`;

// const team = [
//     {
//         img: '',
//         firstName: 'John',
//         lastName: 'Coco',
//         email: 'john@gmail.com',
//     },
//     {
//         img: '',
//         firstName: 'Traga',
//         lastName: 'Mecior',
//         email: 'traga@gmail.com',
//     },
//     {
//         img: '',
//         firstName: 'Ijvan',
//         lastName: 'Macadmia',
//         email: 'macadmia@gmail.com',
//     },
//     {
//         img: '',
//         firstName: 'John',
//         lastName: 'Travolta',
//         email: 'johnt@gmail.com',
//     },
// ];


const ManageTeam = (onClick) => {
    const dispatch = useDispatch();
    const profile = useSelector((state) => state.auth.user);
    const team = useSelector((state) => state.team.allTeammates);
    const [subscriptionType, setSubscriptionType] = useState();
    const [nrTeamMate, setNrTeamMate] = useState();

    // console.log('PROFILE', profile);
    // console.log('HAIDAA', subscriptionType);
    // console.log('NR', nrTeamMate);
    // console.log('Team', team);

    useEffect(() => {
        dispatch(reciveTeammates())
    }, [dispatch]);

    useEffect(() => {
        if (profile !== undefined) {
            if (profile.Stripe[0].payPlan !== null) {
                const plan = profile.Stripe[0].payPlan
                // console.log('HATA BALERINA', plan.split('×').length <= 1);
                if (plan.split('×').length <= 1) {
                    if (plan.split('on').length <= 1) {
                        const arr = plan.split('on');
                        return (
                            setSubscriptionType(arr[1])
                        )
                    } else {
                        const arr = plan.split('on')[1].split(' after');
                        const arrEdit = arr[0].slice(0, arr[0].length - 2);
                        // console.log('HERE', arr[0].slice(0, arr[0].length - 2));
                        // console.log('arr', arrEdit)
                        return (
                            setSubscriptionType(arrEdit)
                        )
                    }
                    // const arr = plan.split('on');
                    // return (
                    //     setSubscriptionType(arr[1])
                    // )
                } else {
                    const arr = plan.split(/[×&|()<>{})\r\n]+/);
                    return (
                        setSubscriptionType(arr[1].slice(0, arr[1].length - 3))
                    )
                }
            }
        }
    }, [profile]);

    useEffect(() => {
        if (subscriptionType === ' My VIP Plan') {
            setNrTeamMate(888)
        }
        if (subscriptionType === ' Single Plan') {
            setNrTeamMate(0)
        }
        if (subscriptionType === ' Squad Plan') {
            setNrTeamMate(5)
        }
        if (subscriptionType === ' Team Plan') {
            setNrTeamMate(25)
        }
        if (subscriptionType === ' Big Team Plan') {
            setNrTeamMate(60)
        }
    }, [subscriptionType]);

    // const deleteOld = () => {
    //     dispatch(deleteOldTeam());
    // };

    useEffect(() => {
        // if (subscriptionType === ' Single Plan' && team.length > 0) {
        //     dispatch(deleteOldTeam());
        // }
        if (nrTeamMate !== undefined) {
            if (team.length > nrTeamMate) {
                dispatch(deleteOldTeam());
            }
        }
    }, [dispatch, nrTeamMate, team])


    return (
        (subscriptionType !== ' Single Plan' && subscriptionType !== undefined && subscriptionType !== ' has been clos') &&
        <TeamWrapper>
            <TeamHeader >
                <h5>Your team
                    <span>{team.length}/{nrTeamMate}</span>
                </h5>
                {team.length < nrTeamMate &&
                    <AddModal />
                }
            </TeamHeader>

            <Hr></Hr>

            <TeamBody>
                {
                    // team.length <= nrTeamMate ?
                    team.length > 0 ?
                        <Team>
                            {team.map((one, i) =>
                                <TeamRow key={i}>
                                    <div style={{ display: 'flex' }}>
                                        {one.UserTeamImage !== '' ?
                                            <LogoDisplay>
                                                <img src={one.UserTeamImage} alt=''></img>
                                            </LogoDisplay>
                                            :
                                            <OtherLogo>
                                                <i className="fas fa-user-astronaut"></i>
                                            </OtherLogo>
                                        }

                                        <Info >
                                            <h4>{one.UserTeamFirstName} {one.UserTeamLastName}</h4>
                                            <h5>{one.UserTeamEmail}</h5>
                                        </Info>
                                    </div>

                                    <DeleteModal
                                        name={`${one.UserTeamFirstName} ${one.UserTeamLastName}`}
                                        id={one.UserTeamID}
                                    />
                                </TeamRow>
                            )
                            }
                        </Team>
                        :
                        <EmptyTeam>
                            <i className="fa-solid fa-user-group"></i>
                            <h3>Create your team</h3>
                        </EmptyTeam>
                    // :
                    // <EmptyTeam>
                    //     <i className="fa-solid fa-eraser"></i>
                    //     <h3>First delete the old team</h3>
                    //     <Btn onClick={deleteOld}>
                    //         Delete
                    //     </Btn>
                    // </EmptyTeam>
                }
            </TeamBody>
        </TeamWrapper>
    );
};

export default ManageTeam;