import axios from 'axios';
axios.defaults.withCredentials = true;

export const stripeSetup = () => (dispatch, getState) => {

  axios
    .get(`${process.env.REACT_APP_URL_BACKEND}/stripeSetup`,)
    .then((res) => {
      // console.log("STRIPE_SETUP", res)
      dispatch({
        type: 'STRIPE_SETUP',
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const stripeOpenSession = (plan, email, publicKey) => (dispatch, getState) => {

  const body = {
    planId: plan,
    email: email
  };

  // console.log(body);

  axios
    .post(`${process.env.REACT_APP_URL_BACKEND}/create-checkout-session`, body)
    .then(async (res) => {
      // console.log("STRIPE_SETUP", res);
      // dispatch({
      //   type: 'STRIPE_SETUP',
      //   payload: res.data,
      // });
      const stripe = await window.Stripe(publicKey);
      stripe.redirectToCheckout({ sessionId: res.data.sessionId })
    })
    .catch((err) => {
      console.log(err);
    });
};

export const stripeOpenSessionWithExisting = (plan, id, publicKey) => (dispatch, getState) => {

  const body = {
    planId: plan,
    customerId: id
  };

  axios
    .post(`${process.env.REACT_APP_URL_BACKEND}/create-checkout-session_existing`, body)
    .then(async (res) => {
      // console.log("STRIPE_SETUP", res);
      // dispatch({
      //   type: 'STRIPE_SETUP',
      //   payload: res.data,
      // });
      const stripe = await window.Stripe(publicKey);
      stripe.redirectToCheckout({ sessionId: res.data.sessionId })
    })
    .catch((err) => {
      console.log(err);
    });
};

export const stripeBillingPortal = (id) => (dispatch, getState) => {

  const body = {
    stripeId: id,
  }

  axios
    .post(`${process.env.REACT_APP_URL_BACKEND}/create_billing_portal`, body)
    .then(async (res) => {
      // console.log("BILLING_PORTAL", res);
      // dispatch({
      //   type: 'STRIPE_SETUP',
      //   payload: res.data,
      // });
      // const stripe = await window.Stripe(publicKey);
      // stripe.redirectToCheckout({ sessionId: res.data.sessionId })
      window.location.href = res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

// const notifHandler = (flag, msg) => (dispatch) => {
//   const obj = {
//     flag: flag,
//     msg: msg,
//   };
//   dispatch({
//     type: 'NOTIF_HANDLER_AUTH',
//     payload: obj,
//   });
//   setTimeout(() => {
//     dispatch({
//       type: 'NOTIF_HANDLER_AUTH',
//       payload: undefined,
//     });
//   }, 3000);
// };
