import React from 'react';

// REDUX
import styled from 'styled-components';


const MsgTostify = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* color: ${props => props.colored}; */
    >i{
        margin-right: 10px;
        font-size: 24px;
        color: ${props => props.colored}
    }
`;

const Msg = ({ icon, msg, color, closeToast, toastProps }) => {
    return (
        <MsgTostify colored={color}>
            {icon}
            {msg}
        </MsgTostify>
    );
}
export default Msg;
