import React from 'react';

// COMPONENTS
import EditModal from './EditGradientModal';
import DeleteModal from './DeleteGradientModal';


const Admin = ({ gradient }) => {

    return (
        <>
            <EditModal gradient={gradient} />
            <DeleteModal gradient={gradient} />
        </>
    );
};

export default Admin;
