import React, { useState } from 'react';
// import ReactDom from 'react-dom';

// PACKEGES
import styled from 'styled-components';
// import Select from 'react-select';

// REDUX
import { useDispatch } from 'react-redux';
import { addGradient } from '../../../Store/Actions/gradientActions';

// SHARE STYLE
import {
    ModalWrapper,
    DialogBox,
    H3,
    Hr,
    LabelCss,
    InputCss,
    // Fields,
    // InputWrapper,
    BtnWrapper,
    Btn,
    // AddNewBtn,
    // ActionBtn,
    // colourStyles,
    // UploadPozWrapper,
    // UploadWrapper,
    // UploadFileInput,
} from '../Admin/AdminComponents/ModalStyle';


const ActionBtn = styled.div`
    /* position: fixed;
    bottom: 70px;
    right: 15px;
    z-index: 1; */
    width: 50px;
    height:50px;
    margin:5px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: var(--background4);
    backdrop-filter: blur(10px);
    /* transform: rotate(-10deg); */
    /* box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%); */
        >i{
            color:  var(--text5);
            font-size: 20px;
        }
        &:hover{
                >i{
                    color: ${props => props.color};
                    transition:color var(--transition) ease-in-out;
                }
           }
`;


const Modal = () => {
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);
    const [more, setMore] = useState({
        gradientName: '',
        bk: '',
        bkColor: '',
        bkImage: '',
        bkBlend: '',
        keywords: '',
        madeWith: '',
    });


    const handleClose = (e) => {
        e.preventDefault();
        setOpenModal(false);
        setMore({
            ...more,
            gradientName: '',
            bk: '',
            bkColor: '',
            bkImage: '',
            bkBlend: '',
            keywords: '',
            madeWith: '',
        })
    };

    const handleChange = evt => {
        const name = evt.target.name;
        const value = evt.target.value;
        setMore({
            ...more,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(addGradient(more));
        handleClose(e);
    };


    return (
        <>
            <ActionBtn onClick={() => setOpenModal(!openModal)} color='var(--color4)'>
                <i className="fa-solid fa-brush"></i>
            </ActionBtn>

            {openModal &&
                <>
                    <ModalWrapper onClick={(e) => handleClose(e)} />

                    <DialogBox>
                        <H3>Add gradient</H3>
                        <Hr></Hr>

                        <form onSubmit={handleSubmit}>
                            <LabelCss htmlFor='gradientName'>Gradient name</LabelCss>
                            <InputCss
                                placeholder='eg. Lady Lips, Young Passion'
                                type='text'
                                name='gradientName'
                                id='gradientName'
                                value={more.gradientName}
                                onChange={handleChange}
                                required
                            ></InputCss>

                            <LabelCss htmlFor='bk'>Background</LabelCss>
                            <InputCss
                                placeholder='Put the background here'
                                type='text'
                                name='bk'
                                id='bk'
                                value={more.bk}
                                onChange={handleChange}
                            ></InputCss>

                            <LabelCss htmlFor='bkColor'>Background color</LabelCss>
                            <InputCss
                                placeholder='Put the background-color here'
                                type='text'
                                name='bkColor'
                                id='bkColor'
                                value={more.bkColor}
                                onChange={handleChange}
                            ></InputCss>

                            <LabelCss htmlFor='bkImage'>Background image</LabelCss>
                            <InputCss
                                placeholder='Put the background-image here'
                                type='text'
                                name='bkImage'
                                id='bkImage'
                                value={more.bkImage}
                                onChange={handleChange}
                            ></InputCss>

                            <LabelCss htmlFor='bkBlend'>Background blend</LabelCss>
                            <InputCss
                                placeholder='Put the background-blend here'
                                type='text'
                                name='bkBlend'
                                id='bkBlend'
                                value={more.bkBlend}
                                onChange={handleChange}
                            ></InputCss>

                            <LabelCss htmlFor='madeWith'>Made for</LabelCss>
                            <InputCss
                                placeholder='Made with'
                                type='text'
                                name='madeWith'
                                id='madeWith'
                                value={more.madeWith}
                                onChange={handleChange}
                                required
                            ></InputCss>

                            <LabelCss htmlFor='keywords'>Keywords</LabelCss>
                            <InputCss
                                placeholder='Keywords'
                                type='text'
                                name='keywords'
                                id='keywords'
                                value={more.keywords}
                                onChange={handleChange}
                                required
                            ></InputCss>

                            <BtnWrapper>
                                <Btn disable onClick={handleClose}>Close</Btn>
                                <Btn type='submit'>Add</Btn>
                            </BtnWrapper>
                        </form>

                    </DialogBox>
                </>
            }
        </>
    );
}
export default Modal;