import React, { useState } from 'react';
import { useHistory } from "react-router-dom";

// PACKAGES
import styled, { keyframes } from 'styled-components';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { openSearch } from '../../../../Store/Actions/otherActions.js';

const SearchWrapper = styled.div`
    position:fixed;
    width: 100%;
    height:100vh;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index:88;
    pointer-events: auto;
    cursor: pointer;
    background: #2525256e;
    backdrop-filter: blur(10px);
    padding: 20px;
    transition: background-color .1s;
        >i{
            position: absolute;
            right: 20px;
            top: 20px;
            font-size: 20px;
            color: var(--text3);
            z-index:88888;
                &:hover {
                    color: var(--text5);
                }
        }
`;

const H1 = styled.h1`
    width:100%;
    text-align: left;
    color: var(--text5);
    /* padding: 10px 0px; */
`;

const H6 = styled.h6`
    width:100%;
    text-align: left;
    color: var(--text4);
    padding: 0px 0px 10px;
`;

const enter = keyframes`
    0% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(1.4);
    }

    100% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
`;

const DialogBox = styled.div`
    opacity: 0;
    position:fixed;
    top:50%;
    left:50%;
    width: 50%;
    max-width: 800px;
    min-width: 350px;
    max-height: 800px;
    overflow: auto;
    border-radius: var(--radius);
    z-index: 888;
    padding: 10px;
    animation: ${enter} .1s ease 0s 1 normal forwards;
    display: block!important;
`;

const InputWrapper = styled.div`
    position: relative;
`;

const InputCss = styled.input`
    width:100%;
    height: 80px;
    font-size: var(--fontXXL);
    padding: 10px 80px 10px 20px;
    border:1px solid var(--border);
    border-radius: var(--radius);
    color: var(--text1);
        &:focus{
            outline:none;
            border:1px solid var(--color4);
        }
        &::placeholder { 
                color: var(--text3);
                font-size: var(--fontXXL);
        }
`;

const Btn = styled.button`
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translate(0, -50%);
    border: none;
    border-radius: 50px;
    padding: 15px;
    background-color: transparent;
        >i{
            font-size:var(--fontXXL);
            color: var(--text3);
        }
        &:hover {
                background: var(--backLanding);
            }
`;

const Search = () => {
    const dispatch = useDispatch();
    let history = useHistory();
    const handleSearch = useSelector((state) => state.other.handleSearch);
    const [search, setSearch] = useState('');

    const handleClose = (e) => {
        e.preventDefault();
        dispatch(openSearch(false));
        setSearch('');
    };

    const escFunction = (event) => {
        // console.log(event);
        if (event.keyCode === 27) {
            handleClose(event);
        }
    };

    const handleChange = (evt) => {
        setSearch(evt.target.value);
        // const name = evt.target.name;
        // const value = evt.target.value;
        // setMore({
        //     ...more,
        //     [name]: value
        // });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // dispatch(addPosterAndContent(more));
        if (search !== '') {
            history.push({
                pathname: '/dashboard/search',
                state: { flag: 'Search', search: search }
            })
        }
        handleClose(e);
    };


    return (
        handleSearch &&
        <>
            <SearchWrapper onClick={(e) => handleClose(e)}>
                <i className="fas fa-times"></i>
            </SearchWrapper>

            <DialogBox>
                <H1>Search</H1>
                <H6>You can search by Name, Type, Theme, Created with etc.</H6>

                <form onKeyDown={escFunction} onSubmit={handleSubmit}>
                    <InputWrapper>
                        <InputCss
                            placeholder='eg. Avatars, Buttons, CSS etc.'
                            type='text'
                            name='search'
                            id='search'
                            autoFocus
                            autoComplete='off'
                            value={search}
                            onChange={handleChange}
                        ></InputCss>

                        <Btn type='submit'><i className="fab fa-sistrix"></i></Btn>
                    </InputWrapper>
                </form>

            </DialogBox>
        </>
    );
};

export default Search;