import React from 'react';

// PACKAGES
import styled, { keyframes } from 'styled-components';

const dash = keyframes`
     0% {
       stroke-dashoffset: 1000;
     }
     100% {
       stroke-dashoffset: 0;
     }
`;

const dashCheck = keyframes`
     0% {
       stroke-dashoffset: -100;
     }
     100% {
       stroke-dashoffset: 900;
     }
`;

const stroke = keyframes`
     100% {
    stroke-dashoffset: 0;
  }
`;
const scale = keyframes`
    0% {
        transform: none;
    }
   100% {
    transform: none;
    }
    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
`;
const fill = keyframes`
    100% {
    box-shadow: inset 0px 0px 0px 30px var(--background2);
  }
`;

const WrapperRightSide = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
        >svg{
            width: 60px;
            stroke-dasharray: 300;  
        }
        .checkmark__circle {
            stroke-dasharray: 166;
            stroke-dashoffset: 166;
            stroke-width: 2;
            stroke-miterlimit: 10;
            /* stroke: green; */
            fill: none;
            animation: ${stroke} 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
            }

        .checkmark {
            width: 56px;
            height: 56px;
            border-radius: 50%;
            display: block;
            stroke-width: 3;
            stroke: var(--color4);
            stroke-miterlimit: 10;
            box-shadow: inset 0px 0px 0px #db13f5a4;
            animation: ${fill} .4s ease-in-out .4s forwards, ${scale} .3s ease-in-out .9s both;
            }

        .checkmark__check {
            transform-origin: 50% 50%;
            stroke-dasharray: 48;
            stroke-dashoffset: 48;
            animation: ${stroke} 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
            }
        /* .path {
            stroke-dasharray: 1000;
            stroke-dashoffset: 0;
            &.circle {
            -webkit-animation: ${dash} .9s ease-in-out;
            animation: ${dash} .9s ease-in-out;
            }
            &.line {
            stroke-dashoffset: 1000;
            -webkit-animation: ${dash} .9s .35s ease-in-out forwards;
            animation: ${dash} .9s .35s ease-in-out forwards;
            }
            &.check {
            stroke-dashoffset: -100;
            -webkit-animation: ${dashCheck} .9s .35s ease-in-out forwards;
            animation: ${dashCheck} .9s .35s ease-in-out forwards;
            }
        } */
        >p{
            color: var(--text2);
            padding-top: 10px;
        }
`;


const Animation = () => {


    return (
        <WrapperRightSide>
            {/* <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                <circle className="path circle" fill="none" stroke="var(--color4)" strokeWidth="4" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                <polyline className="path check" fill="none" stroke="var(--color4)" strokeWidth="4" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
            </svg> */}
            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <defs>
                    <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" stopColor="#E100FF" />
                        <stop offset="100%" stopColor="#ba54f5" />
                    </linearGradient>
                </defs>
                <circle className="checkmark__circle" stroke="url(#gradient)" cx="26" cy="26" r="25" fill="none" />
                <path className="checkmark__check" fill="none" strokeLinecap="round" strokeLinejoin="round" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
            <p>You have seen everything</p>
        </WrapperRightSide >
    );
};

export default Animation;