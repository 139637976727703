import React, { useState, useLayoutEffect, useEffect } from 'react';
import { useHistory } from "react-router-dom";

// PACKAGES
import styled from 'styled-components';

// SHARE STYLE
import {
    WrapperRightSide,
    // RightSide,
    // RightSideSmall
} from '../ShareStyle/ShareStyle';

// SHARE COMPONENTS
import Title from '../../DashboardComponents/PagesTitles/PagesTitles';
import Cards from './PricesCards';
import Checkbox from './Checkbox';


// REDUX
import { useDispatch, useSelector } from 'react-redux';
import {
    stripeSetup,
    stripeOpenSession,
    stripeOpenSessionWithExisting,
    stripeBillingPortal
} from '../../../Store/Actions/stripeActions';
import { teammateNotif } from '../../../Store/Actions/teammateActions';


const Table = styled.table`
    width: 100%;
    max-width: 1500px;
    /* min-height: calc(100vh - 185px);; */
    background: var(--background3);
    border-radius: var(--radius);
    /* padding: 30px; */
    margin: auto;
    margin-top: 20px;
    /* overflow: auto; */
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
`;

// const TableSmall = styled.table`
//     width: 100%;
//     max-width: 1500px;
//     min-height: 50vh;
//     background: var(--background3);
//     border-radius: var(--radius);
//     /* padding: 30px; */
//     margin: auto;
//     margin-top: 20px;
//     /* overflow: auto; */
//     box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
//     /* display: flex;
//     flex-wrap: wrap;
//     justify-content: center; */
//     display: grid;
//     grid-template-columns: repeat(4,auto);
//     grid-column: 50px;
//     grid-row-gap: 0px;
//     justify-content: space-evenly;
//         @media (max-width: 1500px) {
//             width: 500px;
//             margin-bottom: 10px;
//                 grid-template-columns: repeat(1,auto);
//         }
//     `;


const CheckboxContainer = styled.div`
    position: relative;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Tbody = styled.tbody`
    border-radius: var(--radius);
    overflow:hidden;
        >tr:nth-child(odd){
            background-color: var(--background5);
        }
`;

const Th = styled.th`
   padding: 10px 15px;
   color:var(--text4);
   min-width: 150px;
   font-size: var(--fontXXL);
        @media (max-width: 1500px) {
                    font-size: 50px;
        };
        @media (max-width: 775px) {
            font-size: 40px;
        };
`;

const Popup = styled.div`
    visibility: hidden;
    width: 160px;
    font-size: 12px;
    background-color: var(--backLanding);
    backdrop-filter: blur(10px);
    color: var(--text4);
    letter-spacing: 1px;
    /* text-align: center; */
    border-radius: var(--radius);
    padding: 10px;
    position: absolute;
    z-index: 10;
    top: 0px;
    left: 30px;
`;

const Td = styled.td`
    padding: 15px 10px;
    color:var(--text4);
    min-width: 150px;
    font-size: var(--fontL);
        >span{
            position: relative;
            cursor: pointer;
            &:hover ${Popup} {
                    opacity: 1;
                    visibility: visible;
                } 
            >i{
                margin-left:10px;
                font-size:14px;
                color:var(--color2);
            }  
        }  
`;

const Span = styled.span`
    padding: 2px 5px;
    border-radius: var(--radius);
    color:var(--text5);
    /* background: linear-gradient(60deg,#66bb6a,#43a047); */
    background: var(--color6);
`;

const Btn = styled.button`
    width: 250px;
    height:50px;
    display:flex;
    align-items: center;
    justify-content:center;
    color:var(--text5);
    font-weight:bold;
    letter-spacing:2px;
    border-radius: var(--radius);
    margin:10px auto;
    cursor: pointer;
    border: none;
    outline:none;
    transition: filter var(--transition) ease-in-out;
    /* background: linear-gradient(60deg,#f3a81f,#e08b2a); */
    background-color: ${props => !props.less ? 'var(--color4)' : 'var(--color6)'};
    box-shadow:${props => !props.less ?
        '0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px #da13f5ad'
        :
        '0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08)'
    };
        &:hover{
            filter: brightness(0.9);
            color:var(--text5)
        }
        &:active {
            -webkit-transform: translateY(3px);
            -moz-transform: translateY(3px);
            transform: translateY(3px);
            box-shadow: none;
        } 
    /* background: ${props => props.type === 'submit' ? 'var(--color4)' : 'var(--background4)'}; */
        /* >img{
            width:35px;
            margin-right: 10px;
        }
        &:hover{
            filter: brightness(0.9);
            color:var(--text5)
        }
        &:active {
            -webkit-transform: translateY(3px);
            -moz-transform: translateY(3px);
            transform: translateY(3px);
            box-shadow: none;
            } */
`;

const Secure = styled.div`
    width: 100%;
    padding-top:30px;
    font-size: var(--fontM);
    display: flex;
    /* align-items: center; */
    justify-content: center;
    color:var(--text3);
            >span{
                font-weight: bold;
                margin-left: 5px;
                margin-right: 5px;
            }
`;

const plansNames = [
    {
        name: 'Single Plan',
        span1: 'Save 20%',
        span2: 'with',
        btn: 'Annual plan for $49.99',
        value: 'solo-year-plan',
    },
    {
        name: 'Squad Plan',
        span1: 'Save 20%',
        span2: 'with',
        btn: 'Annual plan for $199.99',
        value: 'squad-year-plan',
    },
    {
        name: 'Team Plan',
        span1: 'Save 20%',
        span2: 'with',
        btn: 'Annual plan for $899.99',
        value: 'team-year-plan',
    },
    {
        name: 'Big Team Plan',
        span1: 'Save 20%',
        span2: 'with',
        btn: 'Annual plan for $1999.99',
        value: 'bigteam-year-plan',
    },
];

const choosePlan = [
    {
        btn1: 'Buy Now for $49.99/year',
        btn2: 'Buy Now for $4.99 /month',
        value1: 'solo-year-plan',
        value2: 'solo-month-plan',
    },
    {
        btn1: 'Buy Now for $199.99/year',
        btn2: 'Buy Now for $19.99 /month',
        value1: 'squad-year-plan',
        value2: 'squad-month-plan',
    },
    {
        btn1: 'Buy Now for $899.99/year',
        btn2: 'Buy Now for $89.99 /month',
        value1: 'team-year-plan',
        value2: 'team-month-plan',
    },
    {
        btn1: 'Buy Now for $1999.99 /year',
        btn2: 'Buy Now for $199.99 /month',
        value1: 'bigteam-year-plan',
        value2: 'bigteam-month-plan',
    },
];

const rowHeader = [
    {
        title: 'Updates',
        info: '',
        status: ['free', 'free', 'free', 'free'],
    },
    {
        title: 'Full Code',
        info: '',
        status: [
            <i className="fas fa-check"></i>,
            <i className="fas fa-check"></i>,
            <i className="fas fa-check"></i>,
            <i className="fas fa-check"></i>
        ],
    },
    {
        title: 'Documentation',
        info: 'We will describe each component separately with several code examples.',
        status: [
            <i className="fas fa-check"></i>,
            <i className="fas fa-check"></i>,
            <i className="fas fa-check"></i>,
            <i className="fas fa-check"></i>
        ],
    },
    {
        title: 'Daily Updates',
        info: '',
        status: [
            <i className="fas fa-check"></i>,
            <i className="fas fa-check"></i>,
            <i className="fas fa-check"></i>,
            <i className="fas fa-check"></i>
        ],
    },
    {
        title: 'Domains',
        info: `You can create and deploy an unlimited number of domains as long as you have an active subscription.`,
        status: ['Unlimited', 'Unlimited', 'Unlimited', 'Unlimited'],
    },
    {
        title: 'Team Size',
        // info: `Subscriptions are purchased depending on the number of employees.
        //     \nFor Companies with more than 180 employees we will be able to negotiate the terms and conditions.`,
        info: '',
        status: ['1 person', 'max. 5 people', 'max. 25 people', 'max. 60 people'],
    },
    {
        title: 'Access',
        // info: `Unlimited access for the entire contractual period. 
        //     \nAfter the expiration of the contract, if you do not want the renewal, you can use our products only for another 1 months.
        //     \n-for Freelancer and Startup licence and 3 months 
        //     \n-for Company and Enterprise licence but without access to the platform.`,
        info: `Unlimited access for the entire contractual period.`,
        status: ['Unlimited', 'Unlimited', 'Unlimited', 'Unlimited'],
    },
    {
        title: 'Tech Support',
        // info: 'You will receive bug fixes from us in 3-5 business days. \nBug fix assistance is available: \n-only in the first month for 1 month subscription \n-6/ 12 months for 1 year subscription.',
        // info: `With the Priority Support you receive bug fixes and responses directly from the creators of the products in a couple of hours during business days. 
        //     \nWith Regular Support meaning that you will get responses and bug fixes in 3-5 business days.`,
        info: 'You will receive bug fixes and responses directly from product creators in 3-5 business days.',
        status: ['1 months', '6 months', '12 months', '12 months'],
    },
    {
        title: 'Close anytime',
        info: `If you choose, for any reason, to close an active plan, you can do as follows:  
            \n -within the first 30 days with the possibility of a refund;
            \n -after the first 30 days according to the contractual terms;`,
        status: [
            <i className="fas fa-check"></i>,
            <i className="fas fa-check"></i>,
            <i className="fas fa-check"></i>,
            <i className="fas fa-check"></i>
        ],
    },
];


const Prices = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const profile = useSelector(({ auth }) => auth.user);
    const stripeSet = useSelector(({ stripe }) => stripe.stripeSetup);
    const [size, setSize] = useState();
    const [checked, setChecked] = useState(false);

    // console.log(profile);
    // console.log(stripeSet);

    useEffect(() => {
        dispatch(stripeSetup());
    }, [dispatch]);


    const handlePlan = (e) => {
        // console.log(e);
        let plan;
        if (e === "solo-month-plan") {
            plan = stripeSet.soloPlanM
        } else if (e === "solo-year-plan") {
            plan = stripeSet.soloPlanY
        } else if (e === "squad-month-plan") {
            plan = stripeSet.squadPlanM
        } else if (e === "squad-year-plan") {
            plan = stripeSet.squadPlanY
        } else if (e === "team-month-plan") {
            plan = stripeSet.teamPlanM
        } else if (e === "team-year-plan") {
            plan = stripeSet.teamPlanY
        } else if (e === "bigteam-month-plan") {
            plan = stripeSet.bigTeamPlanM
        } else if (e === "bigteam-year-plan") {
            plan = stripeSet.bigTeamPlanY
        }

        // console.log(plan);CryptoHipsters

        if (profile !== undefined) {
            if (profile.flag !== 'teammate') {
                if (profile.Stripe[0].stripeId === null) {
                    let email;
                    if (profile.userEmail !== 'undefined' || profile.userEmail !== undefined) {
                        email = profile.userEmail
                    } else { email = profile.companyEmail }
                    dispatch(stripeOpenSession(plan, email, stripeSet.publicKey));
                } else {
                    if (profile.Stripe[0].payStatus === 'paid') {
                        dispatch(stripeBillingPortal(profile.Stripe[0].stripeId))
                    } else if (profile.Stripe[0].payStatus === 'failed') {
                        dispatch(stripeBillingPortal(profile.Stripe[0].stripeId))
                    } else {
                        dispatch(stripeOpenSessionWithExisting(plan, profile.Stripe[0].stripeId, stripeSet.publicKey));
                    }
                }
            } else {
                dispatch(teammateNotif())
            }

        } else {
            history.push('/login')
        }
    };

    const onChange = (e) => {
        setChecked(e.target.checked);
    };

    const executeScroll = () => {
        // myRefScroll.current.scrollIntoView({ behavior: 'smooth', block: "start", inline: "nearest" })
        const element = document.getElementById('targetElement');
        const offset = 8;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });

    };

    useLayoutEffect(() => {
        function updateSize() {
            if (window.innerWidth < 1500) {
                setSize(true);
            } else {
                setSize(false);
            }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return (() => window.removeEventListener('resize', updateSize));
    }, []);

    // console.log('SIZEEE', size);

    return (
        <WrapperRightSide>
            <Title
                first='Prices'
                second='Choose the plan that suits '
                third='you best!'
            />

            {!size &&
                <CheckboxContainer>
                    <Checkbox
                        onChange={onChange}
                        checked={checked}
                    />
                </CheckboxContainer>
            }

            <Cards
                executeScroll={executeScroll}
                checked={checked}
                handlePlan={handlePlan}
            />

            {!size ?
                <Table
                    style={{ borderCollapse: 'collapse' }}
                    id={'targetElement'}
                >
                    <thead>
                        <tr>
                            <Th></Th>
                            {plansNames.map((th, i) =>
                                <Th key={i}>{th.name}</Th>
                            )}
                        </tr>
                    </thead>
                    <Tbody>
                        <tr>
                            <Td></Td>
                            {plansNames.map((th, i) =>
                                <Td style={{ textAlign: 'center' }} key={i}>
                                    <Span>{th.span1}</Span> <span>{th.span2}</span>
                                    <Btn onClick={() => handlePlan(th.value)}>{th.btn}</Btn>
                                </Td>
                            )}

                        </tr>
                        {rowHeader.map((row, i) =>
                            <tr key={i}>
                                <Td style={{ fontWeight: 'bold' }}>
                                    {row.title}
                                    {row.info !== '' &&
                                        <span>
                                            <i className="fas fa-info-circle"></i>
                                            <Popup>{row.info}</Popup>
                                        </span>
                                    }
                                </Td>
                                {row.status !== undefined &&
                                    row.status.map((cell, j) =>
                                        <Td style={{ textAlign: 'center' }} key={j}>{cell}</Td>
                                    )}
                            </tr>
                        )
                        }
                        <tr>
                            <Td style={{ fontWeight: 'bold' }}>Choose a plan</Td>
                            {choosePlan.map((btns, i) =>
                                <Td style={{ textAlign: 'center' }} key={i}>
                                    <Btn onClick={() => handlePlan(btns.value1)}>{btns.btn1}</Btn>
                                    <Btn less onClick={() => handlePlan(btns.value2)}>{btns.btn2}</Btn>
                                </Td>
                            )}

                        </tr>
                    </Tbody>
                </Table>
                :
                plansNames.map((tab, i) =>
                    <Table
                        key={i}
                        style={{ borderCollapse: 'collapse', maxWidth: '600px' }}
                    >
                        <thead>
                            <tr>
                                <Th colSpan='2'>{tab.name}</Th>
                            </tr>
                        </thead>
                        <Tbody>
                            <tr>
                                <Td colSpan='2' style={{ textAlign: 'center' }} >
                                    <span>{tab.span1}</span> <span>{tab.span2}</span>
                                    <Btn onClick={() => handlePlan(tab.value)}>{tab.btn}</Btn>
                                </Td>
                            </tr>

                            {rowHeader.map((row, j) =>
                                <tr key={j}>
                                    <Td style={{ fontWeight: 'bold' }}>
                                        {row.title}
                                        {row.info !== '' &&
                                            <span>
                                                <i className="fas fa-info-circle"></i>
                                                <Popup>{row.info}</Popup>
                                            </span>
                                        }
                                    </Td>
                                    {row.status !== undefined &&
                                        // row.status.map((cell, k) =>
                                        <Td style={{ textAlign: 'center' }} >{row.status[i]}</Td>
                                        // )
                                    }
                                </tr>
                            )}

                            <tr>
                                {/* <Td style={{ fontWeight: 'bold' }}>Choose a plan</Td> */}
                                <Td colSpan='2' style={{ textAlign: 'center' }} >
                                    <Btn onClick={() => handlePlan(choosePlan[i].value1)}>{choosePlan[i].btn1}</Btn>
                                    <Btn less onClick={() => handlePlan(choosePlan[i].value2)}>{choosePlan[i].btn2}</Btn>
                                </Td>
                            </tr>

                        </Tbody>
                    </Table>
                )
            }
            <Secure>
                Secure Payment with <span> Stripe </span> Checkout.
            </Secure>
            <Secure>
                <span>Info:</span> If you are a Registered Company you will be able to add your VAT ID after your Press "Buy Now"
            </Secure>

        </WrapperRightSide>
    );
};

export default Prices;
