import React from 'react';

// PACKAGES
import styled, { keyframes } from 'styled-components';


const loading = keyframes`
    0% {
        background: var( --background4);
    }

    100% {
        background: var( --background3);
    }
`;

const CardWrapper = styled.div`
    position: relative;
    width: 500px;
    height: 280px;
    /* border-radius: var(--radius); */
    margin: 10px 0px;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    /* box-shadow: -50px 50px 150px -30px #101117; */
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    overflow: hidden;
    animation: ${loading} 1s linear infinite alternate;
    display: flex;
    align-items: center;
    justify-content: center;
        >h1{
            position: absolute;
            top: 10px;
            left: 10px;
            color: black;
        }
`;

const Image = styled.div`
    >i{
        font-size: 80px;
        color: var(--background2);
    }
 `;

const Shadow = styled.div`
    position: absolute;
    bottom: -30%;
    left: 0;
    width: 100%;
    height: 60%;
    padding: 10px 20px;
    transition: 0.25s;
    background: var(--background2);
    box-shadow: 0 0 20px rgb(0 0 0 / 40%);
    transition: 0.25s;
    clip-path: polygon(3% 0, 100% 14%, 100% 100%, 0% 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
        >div{
            width: 50%;
            border-radius:var(--radius);
            text-align: center;
            height:35px;
            margin-top: 25px;
            opacity: 1;
            transition: opacity .4s;
            transition-delay: .1s;
            background: var(--background4);
            animation: ${loading} 1s linear infinite alternate;
        }
`;


const CardSkeleton = () => {

    return (
        <CardWrapper>
            <Image><i className="far fa-image"></i></Image>
            <Shadow>
                <div></div>
            </Shadow>
        </CardWrapper>

    );
};

export default CardSkeleton;
