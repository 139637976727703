import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// PACKEGES
import styled from 'styled-components';
// import Select from 'react-select';

// ASSETS
import loadingSpinner from '../../../Assets/loadingBtn2.gif';

// COMPONENTS
import Title from '../../DashboardComponents/PagesTitles/PagesTitles';
import ManageTeam from './ManageTeam';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { updateProfile, changeProfilePicture, deleteProfilePicture } from '../../../Store/Actions/dashboardActions';
import { editTeammate, changeTeammateProfilePicture, deleteTeammateProfilePicture } from '../../../Store/Actions/teammateActions';
import { stripeBillingPortal } from '../../../Store/Actions/stripeActions';

// SHARE STYLE
import { WrapperRightSide } from '../ShareStyle/ShareStyle';
import {
    // GeneralWrapper,
    LeftSide,
    RightSide,
    FormWrapperCss,
    Fields,
    InputWrapper,
    LabelCss,
    InputCss,
    PswWrapper,
    EyeWrapper,
    BtnWrapper,
    Btn,
    UploadPozWrapper,
    UploadWrapper,
    UploadFileInput,
    LogoDisplay,
    LogoImg,
    Hr,
    DeleteBtn,
} from '../ShareStyle/FormStyle';

const FormWrapper = styled.div`
    position:relative;
    /* min-height:100vh; */
    margin-top: 100px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
        @media (max-width: 1320px) {
            flex-wrap: wrap-reverse;
            };
`;

const Info = styled.div`
    margin-top: 80px;
    color: var(--text4);
    text-align: start;
        >h2{
            text-align:center;
            font-weight: bold;
        }
        >h3{
            text-align:center;
            font-weight: bold;
        }
        >h4{
            text-align: center;
            color: var(--text3);
            margin-top:20px;
        }
        >h5{
            padding:10px 0 0 20px;
            >span{
                font-weight: bold;
            }
        }
        >div{
            width:100%;
            text-align: center;
            font-size: var(--fontS);
        }
`;

const BtnLink = styled(Link)`
    position: relative;
    height:50px;
    padding: 0 35px;
    display:flex;
    align-items: center;
    justify-content: center;
    background-color:var(--color4);
    border:none;
    color: var(--text5);
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 2px;
    outline: none;
    text-decoration: none;
    margin-top: 40px;
    margin-right:10px;
    margin-bottom:10px;
    border-radius: var(--radius);
        &:hover{
                transition: var(--transition) ease;
                opacity: 0.7;
                color: var(--text4);
        }
`;


const Profile = (onClick) => {
    const dispatch = useDispatch();
    const profile = useSelector((state) => state.auth.user);
    const [imagePreview, setImagePreview] = useState();
    const [showPassword, setShowPassword] = useState(true);
    const [access, setAccess] = useState({
        fname: profile.flag === 'user' || profile.flag === 'teammate' ? profile.userFirstName : '',
        lname: profile.flag === 'user' || profile.flag === 'teammate' ? profile.userLastName : '',
        email: profile.flag === 'user' || profile.flag === 'teammate' ? profile.userEmail : '',
        psw: '',
        compName: profile.flag === 'company' ? profile.companyName : '',
        compEmail: profile.flag === 'company' ? profile.companyEmail : '',
        compVAT: profile.flag === 'company' ? profile.companyVAT : '',
    });
    const [makeAChange, setMakeAChange] = useState(false);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);

    // console.log('PROFILE', profile);

    // useEffect(() => {
    //     // dispatch(fetchProfile());
    // }, [dispatch]);

    // useEffect(() => {
    //     if (confirmStatus === true) {
    //         // dispatch(changeProfile(access, gender, category, days, hours));
    //         setConfirmStatus(false);
    //         // setEditProg(false);
    //     }
    // }, [dispatch, confirmStatus, access]);

    // useEffect(() => {
    //     if (profile !== undefined) {
    //         setAccess({
    //             ...access,
    //             fname: profile.userFirstName,
    //             lname: profile.userLastName,
    //             email: profile.userEmail,
    //         });
    //     }
    // }, [profile]);

    // const handleChange = evt => {
    //     const name = evt.target.name;
    //     const value = evt.target.value;
    //     setMore({
    //         ...more,
    //         [name]: value
    //     });
    // };

    const handleChange = evt => {
        const name = evt.target.name;
        const value = evt.target.value;
        setAccess({
            ...access,
            [name]: value
        });
        setMakeAChange(true);
    };

    // Handle the Picture
    const handleFile = (e) => {
        const image = e.target.files[0];
        // DISPLAY THE IMG/FILE
        const reader = new FileReader();
        reader.onloadend = () => {
            if (reader.readyState === 2) {
                setImagePreview(reader.result);
            }
        };
        if (image !== undefined) {
            reader.readAsDataURL(image);
        };
        setTimeout(() => {
            if (profile.flag === 'teammate') {
                dispatch(changeTeammateProfilePicture(image));
            } else {
                dispatch(changeProfilePicture(image));
            }
        }, 2000);
    };

    const handleDelete = () => {
        if (profile.flag === 'teammate') {
            dispatch(deleteTeammateProfilePicture());
        } else {
            dispatch(deleteProfilePicture());
        }
    };

    const getInitials = () => {
        if (profile !== undefined) {
            let string;
            if (profile.flag === 'user' || profile.flag === 'teammate') {
                string = profile.userLastName + ' ' + profile.userFirstName;
            } else {
                string = profile.companyName;
            }
            const names = string.split(' ');
            let initials = names[0].substring(0, 1).toUpperCase();
            if (names.length > 1) {
                initials += names[names.length - 1].substring(0, 1).toUpperCase();
            }
            return initials
        }
    };

    // Click to show the password
    const handleShowPassword = () => {
        setShowPassword(false);
        setTimeout(() => {
            setShowPassword(true);
        }, 4000);
    };

    const handleBillingPortal = () => {
        setLoading(true);
        dispatch(stripeBillingPortal(profile.Stripe[0].stripeId));
        // setTimeout(() => {
        //     setLoading(false);
        // }, 2000)
    };

    const displayPayPlan = (plan) => {
        // console.log('HATA BALERINA', plan.split('×').length <= 1);
        if (plan.split('×').length <= 1) {
            if (plan.split('on').length <= 1) {
                const arr = plan.split('on');
                return (
                    <h3> {arr[1]} </h3>
                )
            } else {
                const arr = plan.split('on')[1].split(' after');
                const arrEdit = arr[0].slice(0, arr[0].length - 2);
                return (
                    <>
                        <h3> {arrEdit} </h3>
                        <div>{plan}</div>
                    </>
                )
            }
            // const arr = plan.split('on');
            // return (
            //     <h3> {arr[1]} </h3>
            // )
        } else {
            // const arr = plan.split(/[×&|()<>{}\(\)\r\n]+/);
            const arr = plan.split(/[×&|()<>{})\r\n]+/);
            // console.log(arr);
            return (
                <>
                    <h2> {arr[1].slice(0, arr[1].length - 3)}</h2>
                    <div>{arr[2]}</div>
                </>
            )
        }
    };

    const submitForm = (e) => {
        e.preventDefault();
        setSaving(true);
        if (profile.flag === 'teammate') {
            dispatch(editTeammate(access, profile.flag));
        } else {
            dispatch(updateProfile(access, profile.flag));
        }
        setMakeAChange(true);
        setTimeout(() => {
            setSaving(false);
            setMakeAChange(false);
        }, 2000)
    };

    return (

        // <GeneralWrapper>
        <WrapperRightSide>
            <Title
                first='Hey,'
                second='This is your profile page.'
                third='Here you can manage all the details of your account.'
            />

            <FormWrapper>
                <LeftSide>
                    <h5>Edit Profile</h5>

                    <Hr></Hr>

                    <FormWrapperCss onSubmit={submitForm}>
                        {profile.flag === 'user' || profile.flag === 'teammate' ?
                            <>
                                <Fields>
                                    <InputWrapper full>
                                        <LabelCss htmlFor='fname'>First name</LabelCss>
                                        <InputCss
                                            placeholder='Enter your first name'
                                            type='text'
                                            name='fname'
                                            required
                                            value={access.fname}
                                            // onChange={(e) =>
                                            //     setAccess({
                                            //         ...access,
                                            //         fname: e.target.value
                                            //     })
                                            // }
                                            onChange={handleChange}
                                        >
                                        </InputCss>
                                    </InputWrapper>
                                </Fields>

                                <Fields>
                                    <InputWrapper full>
                                        <LabelCss htmlFor='fname'>Last name</LabelCss>
                                        <InputCss
                                            placeholder='Enter your last name'
                                            type='text'
                                            name='lname'
                                            required
                                            value={access.lname}
                                            // onChange={(e) =>
                                            //     setAccess({
                                            //         ...access,
                                            //         lname: e.target.value
                                            //     })
                                            // }
                                            onChange={handleChange}
                                        >
                                        </InputCss>
                                    </InputWrapper>
                                </Fields>

                                <Fields>
                                    <InputWrapper full>
                                        <LabelCss htmlFor='email'>E-mail</LabelCss>
                                        <InputCss
                                            placeholder='Enter your e-mail'
                                            type='mail'
                                            name='email'
                                            required
                                            autoComplete='off'
                                            value={access.email}
                                            // onChange={(e) =>
                                            //     setAccess({
                                            //         ...access,
                                            //         email: e.target.value
                                            //     })
                                            // }
                                            onChange={handleChange}
                                        >
                                        </InputCss>
                                    </InputWrapper>
                                </Fields>
                            </>
                            :
                            <>
                                <Fields>
                                    <InputWrapper full>
                                        <LabelCss htmlFor='compName'>Company name</LabelCss>
                                        <InputCss
                                            placeholder='Enter your company name'
                                            type='text'
                                            name='compName'
                                            required
                                            value={access.compName}
                                            // onChange={(e) =>
                                            //     setAccess({
                                            //         ...access,
                                            //         compName: e.target.value
                                            //     })
                                            // }
                                            onChange={handleChange}
                                        >
                                        </InputCss>
                                    </InputWrapper>
                                </Fields>

                                {/* <Fields>
                                    <InputWrapper full>
                                        <LabelCss htmlFor='vat'>VAT</LabelCss>
                                        <InputCss
                                            placeholder='Enter your VAT number'
                                            type='text'
                                            name='compVAT'
                                            required
                                            value={access.compVAT}
                                            // onChange={(e) =>
                                            //     setAccess({
                                            //         ...access,
                                            //         compVAT: e.target.value
                                            //     })
                                            // }
                                            onChange={handleChange}
                                        >
                                        </InputCss>
                                    </InputWrapper>
                                </Fields> */}

                                <Fields>
                                    <InputWrapper full>
                                        <LabelCss htmlFor='compEmail'>E-mail</LabelCss>
                                        <InputCss
                                            placeholder='Enter your e-mail'
                                            type='mail'
                                            name='compEmail'
                                            required
                                            autoComplete='off'
                                            value={access.compEmail}
                                            // onChange={(e) =>
                                            //     setAccess({
                                            //         ...access,
                                            //         compEmail: e.target.value
                                            //     })
                                            // }
                                            onChange={handleChange}
                                        >
                                        </InputCss>
                                    </InputWrapper>
                                </Fields>
                            </>
                        }
                        {profile.flag !== 'teammate' &&
                            <Fields>
                                <InputWrapper full>
                                    <PswWrapper>
                                        <LabelCss htmlFor='psw'>New password</LabelCss>
                                        <InputCss
                                            placeholder='Enter a new password here'
                                            type={showPassword ? 'password' : 'text'}
                                            name='psw'
                                            autoComplete="off"
                                            value={access.psw}
                                            // onChange={(e) =>
                                            //     setAccess({
                                            //         ...access,
                                            //         psw: e.target.value
                                            //     })
                                            // }
                                            onChange={handleChange}
                                        ></InputCss>
                                        <EyeWrapper onClick={() => handleShowPassword()}>
                                            {showPassword ?
                                                <i className="far fa-eye-slash"></i>
                                                :
                                                <i className="far fa-eye"></i>
                                            }
                                        </EyeWrapper>
                                    </PswWrapper>
                                </InputWrapper>
                            </Fields>
                        }
                        <Fields>
                            <BtnWrapper>
                                {makeAChange ?
                                    !saving ?
                                        <Btn type='submit'>Save</Btn>
                                        :
                                        <Btn disabled><img src={loadingSpinner} alt='' />Saving</Btn>
                                    :
                                    <Btn disabled>Save</Btn>
                                }
                            </BtnWrapper>
                        </Fields>

                        {/* {openModal &&
                            <ConfirmModal
                                setOpenModal={setOpenModal}
                                setConfirmStatus={setConfirmStatus}
                                title='Cambia Profilo'
                                body='Stai per modificare i dati del profilo!'
                            />
                        } */}
                    </FormWrapperCss>
                </LeftSide>

                <RightSide>
                    <LogoDisplay>
                        {imagePreview === null || imagePreview === undefined ?
                            // profile !== undefined &&
                            //     profile.photo !== null ?
                            profile !== undefined ?
                                profile.userImg === '' ?
                                    getInitials()
                                    :
                                    < LogoImg src={profile.userImg} alt=""></LogoImg>
                                :
                                <h3>Logo</h3>
                            :
                            <LogoImg src={imagePreview} alt=""></LogoImg>
                        }

                    </LogoDisplay>

                    <UploadPozWrapper>
                        {profile !== undefined &&
                            profile.userImg !== '' ?
                            <DeleteBtn onClick={handleDelete}>
                                <i className="far fa-trash-alt"></i>
                            </DeleteBtn>
                            :
                            <UploadWrapper>
                                <span ><i className="fas fa-plus"></i></span>
                                <UploadFileInput
                                    type="file"
                                    name="upload"
                                    id="upload"
                                    placeholder="Upload File"
                                    onChange={(e) => handleFile(e)} />
                            </UploadWrapper>
                        }
                    </UploadPozWrapper>

                    <Info>
                        <h3 style={{ marginTop: '0' }}>
                            {profile !== undefined &&
                                (profile.flag === 'user' || profile.flag === 'teammate') ?
                                profile.userLastName + ' ' + profile.userFirstName
                                :
                                profile.companyName
                            }
                        </h3>
                        <Hr></Hr>

                        {profile.flag !== 'teammate' ?
                            profile.Stripe[0].payStatus === 'paid' ?
                                // <h3>- {profile.Stripe[0].payPlan} -</h3>
                                displayPayPlan(profile.Stripe[0].payPlan)
                                :
                                profile.Stripe[0].payStatus !== 'paid' && profile.Stripe[0].payStatus !== null ?
                                    <h4>{profile.Stripe[0].payPlan}</h4>
                                    :
                                    <h4>You have not yet selected a <br></br> payment plan</h4>
                            :
                            <>
                                <h4 style={{ marginBottom: '10px' }}>Team member</h4>
                                {/* {profile.Stripe[0].payStatus !== 'paid' && */}
                                <div style={{ marginBottom: '10px', color: 'var(--text3)', marginTop: '0px' }}>For more details please contact your admin</div>
                                {/* } */}
                            </>
                        }


                        <Fields>
                            <BtnWrapper>
                                {profile.flag !== 'teammate' &&
                                    <>
                                        {profile.Stripe[0].payStatus !== null ?
                                            // <Btn onClick={handleBillingPortal}>Manage your billing</Btn>
                                            loading ?
                                                <Btn disabled>
                                                    <img src={loadingSpinner} alt='loading'></img>
                                                    Loading...
                                                </Btn>
                                                :
                                                <Btn onClick={handleBillingPortal}>Manage your billing</Btn>
                                            :
                                            <BtnLink to='/dashboard/price'>
                                                Choose a plan here
                                            </BtnLink>
                                        }
                                    </>
                                }
                            </BtnWrapper>
                        </Fields>
                    </Info>

                </RightSide>
            </FormWrapper>

            <ManageTeam />

        </WrapperRightSide >

    );
};

export default Profile;