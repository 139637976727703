import React, { useState, useEffect } from 'react'
// import { Link } from 'react-router-dom';

// PACKAGES
import styled from 'styled-components';
// import { CookieStorage } from 'cookie-storage';

// REDUX
import { useSelector } from 'react-redux';


const CookieWrapper = styled.div`
    position: fixed;
    z-index: 8;
    bottom: 50px;
    background: transparent;
    /* backdrop-filter: blur(10px); */
    width: calc(100% - 250px);
    margin-left: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    /* border-top: 1px solid #4646691f; */
    /* height: 200px;
    width: 800px; */
        @media (max-width: 775px) {
            width:100%;
            margin-left:0;
        };
`;

const CookieBody = styled.div`
    position: relative;
    width: 80%;
    max-width: 888px;
    height: 150px;
    padding: 20px;
    background: var(--background5);
    border-radius: var(--radius);
    backdrop-filter: var(--blur);
    overflow: hidden;
    border: 1px solid #464b5a;
    border-right: 1px solid #53586847;
    border-bottom: 1px solid #53586847;
    box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
    pointer-events: auto;
`;

const BtnWrapper = styled.div`
    position: absolute;
    right: 10px;
    top: 7px;
`;

const Btn = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: var(--text2);
    
    border-radius: var(--radius);
    background: transparent;
    padding: 0;
        >i{
            font-size: var(--fontL);
            color: var(--text2);
            opacity: .7;
        }
        &:hover{
            color:var(--color4);
            transition:color .5s ease-in-out;
            >i{
                color: var(--color4);
                transition: color .3s ease-in-out;
            }
        }
        &:active{
                transform:translateY(2px) ;
        }  
`;


const GoogleAds = () => {
    // const cookieStorage = new CookieStorage();
    const profile = useSelector((state) => state.auth.user);
    const [adsOn, setAdsOn] = useState(true);

    const cancelAds = () => {
        setAdsOn(false);
        setTimeout(() => {
            setAdsOn(true);
        }, 20000)
    };

    useEffect(() => {
        if (profile !== undefined) {
            if (profile.Stripe[0].payStatus === null) {
                setAdsOn(true);
            } else if (profile.Stripe[0].payStatus === 'paid') {
                setAdsOn(false);
            } else {
                setAdsOn(true);
            }
        }
    }, [profile]);

    useEffect(() => {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, []);

    // const [accept, setAccept] = useState(cookieStorage.getItem('csyesAds') !== null ?
    //     true
    //     :
    //     JSON.parse(cookieStorage.getItem('csyesAds'))
    // );

    // const cancelAds = () => {
    //     setAccept(true);
    //     const theDate = new Date();
    //     const timeToExpires = new Date(theDate.getTime() + 60000);
    //     // const timeToExpires = new Date(the.getTime() + (90 * 24 * 60 * 60 * 1000)); fot 3 months

    //     cookieStorage.setItem('csyesAds', true, {
    //         // path: '/',
    //         // domain: 'example.com',
    //         expires: timeToExpires,
    //         // secure: true,
    //         // sameSite: 'Strict' // Can be 'Strict' or 'Lax'
    //     });
    // };

    return (
        adsOn &&
        <CookieWrapper>
            <CookieBody>
                <BtnWrapper>
                    <Btn onClick={cancelAds}>
                        <i className="fas fa-times"></i>
                    </Btn>
                </BtnWrapper>

                {/* <!-- CSYES! Ads -->  */}
                <ins className="adsbygoogle"
                    style={{ display: 'block' }}
                    data-ad-format="fluid"
                    data-ad-layout-key="-fb+5w+4e-db+86"
                    data-ad-client="ca-pub-6798339484723104"
                    data-ad-slot="3144153063">
                </ins>

                {/* <ins className="adsbygoogle"
                     style={{ display: 'block' }}
                    data-ad-client="ca-pub-6798339484723104"
                    data-ad-slot="3144153063"
                    data-ad-format="auto"
                    >
                </ins> */}
            </CookieBody>
        </CookieWrapper >
    );
}

export default GoogleAds;