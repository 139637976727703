import { Link } from 'react-router-dom';

// PACKEGES
import styled, { keyframes } from 'styled-components';


// export const AuthWrapper = styled.div`
//     width:calc(100% - 250px);
//     min-height:calc(100vh - 80px);
//     margin-top: 80px;
//     margin-left:250px;
//     background: var(--background2);
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     padding: 0px 10px;
//         @media (max-width: 775px) {
//                 width:100%;
//                 margin-left:0;
//             };
// `;

export const AuthWrapper = styled.div`
    width:calc(100% - 250px);
    /* min-height:calc(100vh - 80px); */
    min-height: calc(100vh - 135px);
    margin-left:250px;
    margin-top: 80px;
    padding: 0px 15px 30px;
    /* display: flex;
    align-items: center;
    justify-content:center; */
        @media (max-width: 775px) {
            width:100%;
            margin-left:0;
        };
`;

export const RightSideAuth = styled.div`
    width: 100%;
    /* min-height: 100vh; */
    background: var(--background3);
    margin: 40px auto;
    overflow: auto;
    display: flex;
    align-items:center;
    justify-content: center;
`;

export const FormWrapper = styled.form`
    position: relative;
    width: ${props => props.signin ? '480px' : '550px'};
    /* background: var(--background1); */
    background-image: linear-gradient(to top,#28283e 0%,#52527b);
    padding: 20px;
    border-radius: var(--radius);
`;

export const H1 = styled.h1`
    width:100%;
    text-align: center;
    color: var(--text5);
`;

export const H5 = styled.h5`
    width:100%;
    color: var(--text4);
    margin: 20px 0px 0;
    text-align: right;
`;

export const H6 = styled.h6`
    width:100%;
    color: var(--text2);
    margin: 5px 0px 0;
    text-align: right;
    letter-spacing: 1px;
`;

export const InputWrapper = styled.label`
	display: block;
    width: ${props => !props.full ? '50%' : '100%'};
    padding: ${props => (props.smallR && '0 10px 0 0') || (props.smallL && '0 0 0 10px')};
    /* padding: 0 10px; */
        @media (max-width: 775px) {
            width: 100%;
        };
`;

export const Fields = styled.div`
    width:100%;
    display: flex;
        @media (max-width: 775px) {
        display:block;
        };
`;

export const PswWrapper = styled.div`
   position:relative;
`;

export const EyeWrapper = styled.div`
   position:absolute;
   right: 15px;
   bottom: 15px;
   color: var(--text2);
   cursor: pointer;
`;

export const LabelCss = styled.label`
	display: block;
	font-size: 1rem;
	margin: 20px 10px 10px;
    color: var(--text5);
        >span{
            margin-left: 5px;
            color:var(--color4);
            font-size: 20px;
        }
`;

export const InputCss = styled.input`
    width:100%;
    height: 50px;
    padding: 6px 12px;
    border: 1px solid var(--color6);
    border-radius: var(--radius);
    background: var(--color7);
    color: var(--text5);
    transition: var(--transition) ease;
    box-shadow: 0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08)!important;
        &::placeholder { 
            color: var(--text2);
            font-size: 14px;
        }
        &:hover{
            border-color: var(--border);
        }
        &:focus{
            outline:none;
            border: 1px solid var(--color4);
        }
        &:-webkit-autofill{
            -webkit-text-fill-color: var(--text5);
            -webkit-box-shadow: 0 0 0px 1000px #fff inset;
            transition: background-color 5000s ease-in-out 0s; 
        }
`;

export const InputNumContainer = styled.div`
    display: flex;
    max-width: 350px;
`;

export const InputNum = styled.input`
    width: 50px;
    height: 50px;
    padding: 6px 12px;
    margin-right:10px;
    border: 1px solid var(--color6);
    border-radius: var(--radius);
    background: var(--color7);
    color: var(--text5);
    text-align:center;
    transition: var(--transition) ease;
    box-shadow: 0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08)!important;
        &::placeholder { 
            color: var(--text2);
            font-size: 14px;
        }
        &:hover{
            border-color: var(--border);
        }
        &:focus{
            outline:none;
            border: 1px solid var(--color4);
        }
        &:-webkit-autofill{
            -webkit-text-fill-color: var(--text5);
            -webkit-box-shadow: 0 0 0px 1000px #fff inset;
            transition: background-color 5000s ease-in-out 0s; 
        }
`;

export const Label = styled.label`
    display:flex;
    align-items: center;
    color:var(--text5);
    margin:20px 10px 0;
`;

export const CheckTxt = styled.span`
    margin-left:8px;
    >a{
        text-decoration: none;
        margin-left: 5px;
        color: var(--text5);
        transition: var(--transition) ease;
        font-weight: bold;
            &:hover{
                /* color: #5e72e4;  */
                text-decoration: underline;
            }
    }
`;

export const BtnWrapper = styled.div`
    position: relative;
    width: 100%;
`;

export const Btn = styled.button`
    width: 100%;
    height:50px;
    display:flex;
    align-items: center;
    justify-content:center;
    color:var(--text5);
    font-weight:bold;
    letter-spacing:2px;
    border-radius: var(--radius);
    margin-top: 50px;
    cursor: pointer;
    /* background: var(--color4); */
    border: none;
    outline:none;
    /* box-shadow: 0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08)!important; */
    transition: filter var(--transition) ease-in-out;
    background: ${props => props.type === 'submit' ? 'var(--color4)' : 'var(--background4)'};
    box-shadow: ${props => props.type === 'submit' ?
        '0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px #da13f5ad'
        :
        '0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08)'
    };
        >img{
            width:35px;
            margin-right: 10px;
        }
        &:hover{
            filter: brightness(0.9);
            color:var(--text5)
        }
        &:active {
            -webkit-transform: translateY(3px);
            -moz-transform: translateY(3px);
            transform: translateY(3px);
            box-shadow: none;
        }
`;

export const ChooseBtn = styled.button`
    width: 50%;
    height:50px;
    display:flex;
    align-items: center;
    justify-content:center;
    color:var(--text5);
    font-weight:bold;
    letter-spacing:2px;
    border-radius: var(--radius);
    margin:auto;
    margin-top:20px;
    cursor: pointer;
    background: var(--color4);
    border: none;
    outline:none;
    /* box-shadow: 0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08)!important; */
    transition: filter var(--transition) ease-in-out;
    box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px #da13f5ad;
        >i{
            margin-right: 10px;
            font-size: 20px;
        }
        &:hover{
            filter: brightness(0.9);
            color:var(--text5)
        }
        &:active {
            -webkit-transform: translateY(3px);
            -moz-transform: translateY(3px);
            transform: translateY(3px);
            box-shadow: none;
            }
`;

export const LinkCss = styled(Link)`
    text-decoration: none;
    color: var(--text4);
    margin-left: 5px;
    font-weight: bold;
        &:hover{
            color: var(--text5);
            text-decoration: underline;
        }
`;

export const Hr = styled.hr`
    width: 90%;
    border:none;
    border-top: 1px solid var(--color8);
    margin:10px auto 5px;
`;

const err = keyframes`
 0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
`;

export const Err = styled.div`
    position: absolute;
    padding: 5px 10px;
    width: calc(100% - 40px);
    border: none;
    margin: 5px auto;
    color: var(--text5); 
    opacity: 1;
    font-size: var(--fontM);
    /* animation: ${err} 0.2s ease 0s 1 normal forwards; */
        >span{
            padding: 2px 10px;
            background: var(--err);
            border-radius: var(--radius);
        }
`;