import React, { Component } from 'react';
import './AboutUs.css';

import { Link } from 'react-router-dom'

// Img
import Mission from '../../../../Assets/mission.png';
import Chip from '../../../../Assets/chip.png';
import Solution from '../../../../Assets/solution.png';
import Team from '../../../../Assets/team.png';
import Super from '../../../../Assets/superhero.png';
// import Tell from '../../../../Assets/megaphone.png';

// Logos
// import CSS from '../../../../Assets/techLogos/css.png';
// import SASS from '../../../../Assets/techLogos/sass.png';
// import JS from '../../../../Assets/techLogos/js.png';
// import Node from '../../../../Assets/techLogos/nodejs.png';
// import JSX from '../../../../Assets/techLogos/react.png';

// STYLES
import { WrapperRightSide } from '../../../Pages/ShareStyle/ShareStyle';


class AboutUs extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }


    render() {

        return (

            < WrapperRightSide >

                <div className="aboutUs">

                    <div className="aboutUs-title-container">
                        <div className="aboutUs-back"></div>
                        <div className="aboutUs-text">We Craft Premium UI<br></br>Elements</div>
                    </div>

                    <div className="section-container">
                        <div className="team"><img alt='team' src={Team}></img></div>
                        <div className="aboutUs-tech">Who We Are</div>
                        <div className="aboutUs-desc">We are CSYES! Team — a small but very passionate team.<br></br> We make stunning products for those who create web.</div>
                    </div>

                    <div className="section-container">
                        <div className="super"><img alt='super' src={Super}></img></div>
                        <div className="aboutUs-tech">Our Super Power</div>
                        <div className="aboutUs-desc">We have created the tool that will help you to develop beautiful website much faster and easier.</div>
                    </div>


                    <div className="section-container">
                        <div className="mission"><img alt='mission' src={Mission}></img></div>
                        <div className="aboutUs-tech">Our mission</div>
                        <div className="aboutUs-desc">Our mission is to build a solid connection between designers and developers, make their life easier and speed up their workflow.</div>
                    </div>

                    <div className="section-container">
                        <div className="chip"><img alt='chip' src={Chip}></img></div>
                        <div className="aboutUs-tech">Our technologies</div>
                        <div className="aboutUs-desc">Our products are built on top of some modern frameworks and we are constantly developing and improving our technologies. </div>

                        {/* 
                        <div className="aboutUs-tech-div">
                            <div className="aboutUs-tech-logo"><img alt='logo' src={JSX} height='50px'></img>
                                <span className="aboutUs-tooltiptext">React</span></div>
                            <div className="aboutUs-tech-logo"><img alt='logo' src={CSS} height='50px'></img>
                                <span className="aboutUs-tooltiptext">Vanilla CSS</span></div>
                            <div className="aboutUs-tech-logo"><img alt='logo' src={SASS} height='50px'></img>
                                <span className="aboutUs-tooltiptext">SASS</span></div>
                            <div className="aboutUs-tech-logo"><img alt='logo' src={JS} height='55px'></img>
                                <span className="aboutUs-tooltiptext">JavaScript</span></div>
                            <div className="aboutUs-tech-logo"><img alt='logo' src={Node} height='50px'></img>
                                <span className="aboutUs-tooltiptext">NodeJS</span></div>
                        </div> */}
                    </div>

                    <div className="section-container">
                        <div className="solution"><img alt='solution' src={Solution}></img></div>
                        <div className="aboutUs-tech">Solutions</div>
                        <div className="aboutUs-desc">Creating web design from scratch can be very costly and time consuming. Using our solutions you do not have to worry about design. Save time and money by focusing on your business model. </div>

                    </div>


                    <div className="section-container">

                        <div className="aboutUs-solution">

                            <div className="solution-container">
                                <div className="solution-title">Pre-Build Elements</div>
                                <div className="solution-txt">The easiest way to get started is to use our pre-built elements or example pages.</div>
                            </div>

                            <div className="solution-container">
                                <div className="solution-title">Easy to Use</div>
                                <div className="solution-txt">You choose the element you like and with just two clicks you can have it in your project.<br></br>It's that simple!</div>
                            </div>

                            <div className="solution-container">
                                <div className="solution-title">Fully Coded</div>
                                <div className="solution-txt">Each element is fully coded and ready to use. From headers to footers, you will be able to choose the best combination for your project.</div>
                            </div>

                        </div>
                    </div>

                    {/* <div className="tell-container">
                        <div className="tell">
                            <div className="tell-icon"><img alt='tell-icon' src={Tell}></img></div>
                            <div className="tell-txt">Tell your friends about us.<br></br> We will be grateful.</div>
                        </div>
                    </div> */}
                    {/* <div className="aboutUs-tech">Who is behind these great looking interfaces?</div>
                <div className="aboutUs-tech">Some of our awesome clients</div> */}


                    <div className="come-inside">
                        <div className="come-inside-txt">Step inside and enjoy!</div>
                        <div className="come-inside-r"><Link to='/dashboard'><button className="come-inside-btn">Let's go</button></Link></div>
                    </div>

                </div>

            </WrapperRightSide>
        )
    }
};

export default AboutUs;