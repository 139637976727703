import React, { useLayoutEffect, useState } from 'react';

// PACKAGES
import styled, { keyframes } from 'styled-components';

const topTop = keyframes`
    0%,
    20%,
    50%,
    80%,
    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
        -moz-transform: translateY(0px);
    }
    40% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
        -moz-transform: translateY(-30px);
    }
    60% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
        -moz-transform: translateY(-20px);
    }
`;


const ScrollWrapper = styled.div`
    /* position: fixed;
    bottom: 15px;
    right: 15px; */
    margin:5px;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: var(--background4);
    display: ${props => props.sts ? 'flex' : 'none'};
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;
    visibility: ${props => props.sts ? 'visible' : 'hidden'};
    transition: visibility .3s ease ,background-color .5s ease;
        >i{
            color:var(--text5);
            font-size: 22px;
        }
        &:hover{
                >i{
                    animation: ${topTop} 1s;
                }   
            }
`;


const MoveToTop = () => {
    const [isTop, setIsTop] = useState(false);

    useLayoutEffect(() => {
        function updateSize() {
            const whereIs = window.scrollY > 800;
            if (whereIs) {
                setIsTop(true);
            } else {
                setIsTop(false);
            }
        }
        window.addEventListener('scroll', updateSize);
        updateSize();
        return (() => window.removeEventListener('scroll', updateSize));
    }, []);

    const moveTop = (e) => {
        e.preventDefault();
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <ScrollWrapper sts={isTop} onClick={moveTop}>
            <i className="fas fa-hand-point-up"></i>
        </ScrollWrapper>
    );
}

export default MoveToTop;