import styled from 'styled-components';

// PAGES

export const WrapperRightSide = styled.div`
    width:calc(100% - 250px);
    /* min-height:calc(100vh - 80px); */
    min-height:calc(100vh - 135px);
    margin-left:250px;
    margin-top: 80px;
    padding: 0px 15px 30px; 
    /* display: flex;
    align-items: center;
    justify-content:center; */
        @media (max-width: 775px) {
            width:100%;
            margin-left:0;
        };
`;

export const RightSide = styled.div`
    width: 100%;
    max-width: 2200px;
    /* min-height: calc(100vh - 185px); */
    /* background: var(--background3); */
    border-radius: var(--radius);
    /* padding: 30px; */
    margin: auto;
    margin-top: 20px;
    /* overflow: auto; */
    /* box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%); */
    /* display: flex;
    flex-wrap: wrap;
    justify-content: center; */
    display: grid;
    /* grid-template-columns: repeat(4,auto); */
    grid-template-columns: ${props => props.length === 1 && `repeat(1,auto)!important`};
    grid-template-columns: ${props => props.length <= 3 ? `repeat(${props.length},auto)` : 'repeat(4,auto)'};
    grid-column: 50px;
    grid-row-gap: 0px;
    justify-content: space-evenly;
        @media (max-width: 2300px) {
                /* grid-template-columns: repeat(3,auto); */
                grid-template-columns: ${props => props.length <= 3 ? `repeat(${props.length},auto)`: 'repeat(3,auto)'};
        }
        @media (max-width: 1800px) {
                grid-template-columns: repeat(2,auto);
        }
        @media (max-width: 1300px) {
                grid-template-columns: repeat(1,auto);
        }
        
        
    /* display: grid;
        grid-template-columns: repeat(4, minmax(300px, 1fr));
        grid-gap: 30px;
        padding: 50px;
        justify-content: space-evenly;
        color: white;
        @media (max-width: 2250px) {
            /* grid-template-columns: auto; */
            /* grid-template-columns: repeat(3, minmax(300px, 1fr));
            text-align: center;
        };
        @media (max-width: 1980px) {
            /* grid-template-columns: auto; */
            /* grid-template-columns: repeat(2, minmax(300px, 1fr));
            text-align: center; */
        /* }; */
`;

export const RightSideEmpty = styled.div`
    max-width: 2200px;
    margin: auto;
    height: calc(100vh - 260px);
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
            >i{
                font-size: 80px;
                color: var(--text2);
            }
            >h3{
                color: var(--text2);
                font-size: var(--fontXXL);
                margin-top: 20px;
            }
`

// export const RightSideSmall = styled.div`
//     width: 100%;
//     max-width: 1500px;
//     min-height: 50vh;
//     background: var(--background3);
//     border-radius: var(--radius);
//     /* padding: 30px; */
//     margin: auto;
//     margin-top: 20px;
//     /* overflow: auto; */
//     box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
//     /* display: flex;
//     flex-wrap: wrap;
//     justify-content: center; */
//     display: grid;
//     grid-template-columns: repeat(4,auto);
//     grid-column: 50px;
//     grid-row-gap: 0px;
//     justify-content: space-evenly;
//         @media (max-width: 1500px) {
//             width: 500px;
//             margin-bottom: 10px;
//                 grid-template-columns: repeat(1,auto);
//         }
//     `;