import { combineReducers } from "redux";
import authReducers from "./authReducers";
import otherReducers from "./otherReducers";
import contentReducers from "./contentReducers";
import stripeReducer from "./stripeReducers";
import dashboardReducer from "./dashboardReducers";
import teammateReducer from "./teammateReducers";
import gradientReducer from "./gradientReducers";

export default combineReducers({
  auth: authReducers,
  other: otherReducers,
  content: contentReducers,
  stripe: stripeReducer,
  dash: dashboardReducer,
  team: teammateReducer,
  gradient: gradientReducer,
});
