import React from 'react';

// PACKAGES
import styled from 'styled-components';

const HamWrapper = styled.div`
    cursor: pointer;
    display:flex;
    align-items:center;
        >i{
            font-size:30px;
            color: var(--color3);
        }
        @media (min-width: 775px) {
            display: none;
        };
`;

const Ham = ({ ham, setHam }) => {

    return (
        <HamWrapper onClick={() => setHam(!ham)}>
            {!ham ? <i className="fas fa-bars"></i>
                :
                <i className="fas fa-times"></i>}
        </HamWrapper>
    );
};

export default Ham;
