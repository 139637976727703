import React, { Component } from 'react';
import './Legal.css';

import Terms from './Terms';
import Privacy from './Privacy';
import Licence from './Licence';

// PACKAGES
import styled from 'styled-components';

// import SecondLogo from '../../components/secondLogo/SecondLogo';

const WrapperRightSide = styled.div`
    width:calc(100% - 250px);
    /* height:calc(100vh - 80px); */
    margin-left:250px;
    margin-top: 80px;
    padding:40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
        @media (max-width: 775px) {
            width:100%;
            margin-left:0;
        };
`;


class Legal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: ''
        }
    }

    handleId = (e) => {
        this.setState({
            id: e.currentTarget.id
        })
    }

    render() {
        // console.log(this.state.id)
        return (
            <WrapperRightSide>

            <div className="legal">

                <nav className="legal-nav">
                    <ul className="legal-ul">
                        <li id="1" onClick={this.handleId}>Terms</li>
                        <li id="2" onClick={this.handleId}>Privacy Policy</li>
                        <li id="3" onClick={this.handleId}>Licence</li>
                    </ul>
                </nav>

                <div>
                    {this.state.id === '' && <Terms />}
                    {this.state.id === '1' && <Terms />}
                    {this.state.id === '2' && <Privacy />}
                    {this.state.id === '3' && <Licence />}
                </div>

                {/* <SecondLogo/> */}
            </div>
            </WrapperRightSide>
        )
    }
}


export default Legal;