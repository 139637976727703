import React, { useEffect } from 'react';

// STYLE
import './globalCss.scss';

// PACKEGES
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

// ROUTES
import Routes from './Router/Index';

// COMPONENTS
import Loading from './GeneralComponents/MainLoading/MainLoading';
import NotificationHandler from './Dashboard/DashboardComponents/NotificationHandler/NotificationHandler';
import AddModal from './Dashboard/Pages/Admin/AdminComponents/AddModal';
import AddGradientModal from './Dashboard/Pages/Gradients/AddGradientModal';
import MoveToTop from './Dashboard/DashboardComponents/MoveToTop/MoveToTop';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from './Store/Actions/authActions';

const MainWrapper = styled.div`
    width: 100%;
    /* height: 100vh; */
    margin: auto;
    background-color: var(--background2);
`;

const ActionsWrapper = styled.div`
   position: fixed;
   z-index: 3;
   bottom: 15px;
   right: 15px;
`;

const App = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(({ auth }) => auth.isLoading);
  const profile = useSelector((state) => state.auth.user);

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  return (
    <>
      {isLoading ?
        <Loading />
        :
        <MainWrapper>
          <ActionsWrapper>
            {profile !== undefined &&
              profile.accesLevel === 'admin' &&
              <>
                <AddGradientModal />
                <AddModal />
              </>
            }
            <MoveToTop />
          </ActionsWrapper>

          <NotificationHandler />

          <Routes />
        </MainWrapper>
      }
    </>
  );
}

export default withRouter(App);