import React from 'react';

// PACKAGES
import styled, { keyframes } from 'styled-components';

//ASSETS
// import loadingGif from '../Assets/loadingGif.gif';

const boo = keyframes`
    0% {
      transform: scale(1.1);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
`;

const boob = keyframes`
    0% {
      transform: scale(0.9);
    }
    80% {
      transform: scale(1) rotateZ(-3deg);
    }
    100% {
      transform: scale(1) rotateZ(-3deg);
    }
`;

const LoadingWrapper = styled.div`
    @import url("https://fonts.googleapis.com/css?family=Luckiest+Guy&display=swap");
    font-family: "Luckiest Guy", cursive!important; */
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height:100vh;
    display:flex;
    align-items:center;
    justify-content:center;
    z-index:888;
    background: var(--background5);
    /* background: radial-gradient(circle, #fffc00 0%, #f0ed17 100%); */
    backdrop-filter: var(--blur);
      >h2 {
          margin: 0;
          font-size: 5em;
          padding: 0;
          font-weight: bold;
          color: white;
          text-shadow: 0 0.1em 20px rgba(0, 0, 0, 1), 0.05em -0.03em 0 rgba(0, 0, 0, 1),
              0.05em 0.005em 0 rgba(0, 0, 0, 1), 0em 0.08em 0 rgba(0, 0, 0, 1),
              0.05em 0.08em 0 rgba(0, 0, 0, 1), 0px -0.03em 0 rgba(0, 0, 0, 1),
              -0.03em -0.03em 0 rgba(0, 0, 0, 1), -0.03em 0.08em 0 rgba(0, 0, 0, 1), -0.03em 0 0 rgba(0, 0, 0, 1);
          >div{
            display: flex;
              >div>span{
                color: var(--color4);
              }
              >div:nth-child(2){
                color: var(--color4);
              }
              >div:nth-child(2){
                transform: rotateZ(8deg);
                margin-left:10px;
              }
              >div:nth-child(1){
              animation: ${boo} 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards infinite alternate;
              } 
          }
          >div:nth-child(2){
                width: 50%;
                border: 2px solid var(--color4);
                box-shadow: 0 0.1em 20px rgba(0, 0, 0, 1), 0.05em -0.03em 0 rgba(0, 0, 0, 1),
                0.05em 0.005em 0 rgba(0, 0, 0, 1), 0em 0.08em 0 rgba(0, 0, 0, 1),
                0.05em 0.08em 0 rgba(0, 0, 0, 1), 0px -0.03em 0 rgba(0, 0, 0, 1),
                -0.03em -0.03em 0 rgba(0, 0, 0, 1), -0.03em 0.08em 0 rgba(0, 0, 0, 1), -0.03em 0 0 rgba(0, 0, 0, 1);
                margin-left: 40%;
          }
          >div:last-child{
            font-size: 30px;
            display: flex;
            justify-content: flex-end;
            animation: ${boob} 1s 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards infinite alternate;
          }
      }
`;


const Loading = () => {

  return (
    <LoadingWrapper>
      <h2>
        <div>
          <div>CS<span>YES</span></div>
          <div>!</div>
        </div>
        <div></div>
        <div>team™</div>
      </h2>
      {/* <img src={loadingGif} alt=''></img>
            <h3>Loading ...</h3> */}
    </LoadingWrapper>
  );
};

export default Loading;
