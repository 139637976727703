import React, { useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';

// PACKAGES
import axios from 'axios';
import styled from 'styled-components';

// STYLE
import { WrapperRightSide } from '../ShareStyle/ShareStyle';

const RightSide = styled.div`
    width: 100%;
    height: calc(100vh - 185px);
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
      >h1{
        font-size: 100px;
        color: var(--text4);
      }
      >h4{
        font-size: var(--fontXXL);
        color: var(--text2);
      }
      >h5{
        font-size: var(--fontM);
        color: var(--text2);
      }
`;

const BtnLink = styled(Link)`
    width: 250px;
    height:50px;
    display:flex;
    align-items: center;
    justify-content:center;
    color:var(--text5);
    font-weight:bold;
    letter-spacing:2px;
    border-radius: var(--radius);
    margin: 50px auto;
    cursor: pointer;
    border: none;
    outline:none;
    text-decoration: none;
    transition: filter var(--transition) ease-in-out;
    /* background: linear-gradient(60deg,#f3a81f,#e08b2a); */
    background-color: ${props => !props.less ? 'var(--color4)' : 'var(--color6)'};
    box-shadow:${props => !props.less ?
    '0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px #da13f5ad'
    :
    '0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08)'
  };
        &:active{
                transform:translateY(2px) ;
        }
        >a{
          
          color: var(--text5);
        }
`;

const Success = () => {
  const history = useHistory();
  const location = useLocation();
  // const [session, setSession] = useState({});
  const sessionId = location.search.replace('?session_id=', '');


  // console.log("Session id is: " + sessionId)

  if (sessionId === undefined || sessionId === null || sessionId === '') {
    history.push("/dashboard")
  };


  useEffect(() => {
    const fetchSession = async () => {
      await axios(`${process.env.REACT_APP_URL_BACKEND}/auth/checkout-session?sessionId=` + sessionId)
        .then((res) => {
          console.log(res.json())
        })
    }

    // async function fetchSession() {
    //   setSession(
    //     await fetch(`${process.env.REACT_APP_URL_BACKEND}/auth/checkout-session?sessionId=` + sessionId).then((res) =>
    //       res.json()

    //     )
    //   );

    // }
    fetchSession();

  }, [sessionId]);


  return (
    <WrapperRightSide>
      <RightSide>
        <h1>🎉Congratulations🎉</h1>
        <h4>Your payment was successfully done!</h4>
        {/* <h4>Thank you for your purchase!</h4> */}
        <h5>You can see all the details in the "My profile" section.</h5>
        <BtnLink to='/dashboard'>Let's begin</BtnLink>
      </RightSide>
    </WrapperRightSide>
  );
};


export default Success;