import React from 'react';

// PACKAGES
import styled from 'styled-components';


const Title = styled.div`
    width:100%;
    padding: 0px 20px;
    text-align: center;
    cursor: default;
        >h3{
            color: var(--text5);    
        }
        >h5{
            color: var(--text4);
        }
`;

const Titles = (props) => {

    return (
        <Title>
            <h3>{props.first}</h3>
            <h5>{props.second}</h5>
            <h5>{props.third}</h5>
        </Title>
    );
};

export default Titles;