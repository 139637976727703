import React from 'react';
import { Link } from 'react-router-dom';

// PACKEGES
import styled from 'styled-components';


const NotifDisplay = styled.div`
    position: absolute;
    top: -45px;
    width: 100%;
    min-height: 30px;
    /* padding: 0 10px; */
    display:flex;
    align-items: center;
    justify-content: flex-start;
        >h5{      
            /* font-weight: bold;*/
            color:var(--text4);
                >a{
                text-decoration: none;
                color: var(--text5);
                /* color: var(--warning); */
                margin-left:5px;
                font-weight: bold;
                letter-spacing: 1px;
                /* font-weight: bold; */
                padding: 2px 10px;
                background: var(--warning);
                border-radius: var(--radius);
                    &:hover{
                        text-decoration: underline;
                    }
                }
        }
`;



const Notif = () => {
    return (
        <NotifDisplay>
            <h5>Please activate your account.
                <Link to='/confirmEmail'>Click here!</Link>
            </h5>
        </NotifDisplay>
    );
}

export default Notif;