import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

// COMPONENTS
import Title from '../../Dashboard/DashboardComponents/PagesTitles/PagesTitles';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { resendEmail } from '../../Store/Actions/authActions';

// SHARE STYLE
import {
    AuthWrapper,
    RightSideAuth,
    FormWrapper,
    H1,
    H5,
    H6,
    LabelCss,
    InputCss,
    BtnWrapper,
    Btn,
    LinkCss
} from '../ShareStyle/ShareStyle';


const ResendEmail = () => {
    const dispatch = useDispatch();
    let history = useHistory();
    const resend = useSelector((state) => state.auth.resendEmail);
    const [access, setAccess] = useState({
        email: '',
    });
    const [waitBtn, setWaitBtn] = useState(false);
    const [timeLeft, setTimeLeft] = useState();

    // console.log('RESEND EMAIL',resend)

    const submitForm = (e) => {
        e.preventDefault();
        dispatch(resendEmail(access));
        setWaitBtn(true);
        setTimeout(() => {
            setWaitBtn(false);
        }, 5000)
        setTimeLeft(5);
    };

    if (resend === 'The e-mail has been sent!') {
        setTimeout(() => {
            history.push('/confirmEmail')
        }, 5000)
    };

    useEffect(() => {
        // exit early when we reach 0
        if (!timeLeft) return;
        // save intervalId to clear the interval when the
        // component re-renders
        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
        // add timeLeft as a dependency to re-rerun the effect
        // when we update it
    }, [timeLeft]);


    return (
        <AuthWrapper>
            <Title
                first='Relax!'
                second='We will resend your confirmation e-mail'
                third=''
            />

            <RightSideAuth style={{ background: 'var(--background2)' }}>

                <FormWrapper signin onSubmit={submitForm}>
                    <H1>Resend e-mail</H1>

                    <LabelCss htmlFor="email">E-mail<span>*</span></LabelCss>
                    <InputCss
                        placeholder="Enter your e-mail"
                        id='email'
                        type="email"
                        name='email'
                        required
                        value={access.email}
                        onChange={(e) =>
                            setAccess({
                                ...access,
                                email: e.target.value
                            })
                        }
                    >
                    </InputCss>

                    <BtnWrapper>
                        {waitBtn ?
                            resend === undefined ?
                                <Btn disabled>Try again in {timeLeft}s</Btn>
                                :
                                <Btn disabled>Redirect in {timeLeft}s</Btn>
                            :
                            <Btn type='submit'>Resend</Btn>
                        }
                    </BtnWrapper>

                    <H5>
                        <LinkCss to='/confirmEmail' >Confirm e-mail <i className="fas fa-chevron-right"></i></LinkCss >
                    </H5>
                    <H6>all fields marked with * are required</H6>
                </FormWrapper>

            </RightSideAuth>

        </AuthWrapper>
    );
}
export default ResendEmail;