import React, { useState, useRef, useEffect } from 'react';
import { Link } from "react-router-dom";

// PACKAGES
import styled from 'styled-components';

// ASSETS
import MainLogo from './MainLogo/MainLogo';

// COMPONENTS
import HamburgerMenu from './Hamburger/Ham';


const NavBarWrapper = styled.div`
    position: fixed;
    top:0;
    width:100%;
    background: transparent;
    z-index: 3;
    /* box-shadow: 0px 19px 26px -13px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 19px 26px -13px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 19px 26px -13px rgba(0, 0, 0, 0.75); */
    /* @media (max-width: 775px) {
        display: none;
    }; */
`;

const NavBarBody = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    /* height: 185px; */
    display: flex;
    padding: 0 20px;
    /* justify-content: flex-end; */
`;

const Left = styled.div`
    width: 50%;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

const LogoWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: default;
`;

const Right = styled.div`
    width: 50%;
    display: flex;
    margin: 0;
    padding: 0;
    align-items: center;
    justify-content: flex-end;
`;

const UnorderList = styled.ul`
    /* height:80px; */
    font-size: var(--fontS);
    list-style:none;
    padding:0;
    display:flex;
    align-items:center;
    justify-content: flex-end;
    flex:1;
    letter-spacing: 2px;
        @media (max-width: 775px) {
            display: none;
        };
`;

// const UnorderListHam = styled.ul`
//     position: absolute;
//     top: 120px;
//     width:100%;
//     height: 100px;
//     font-size: var(--fontS);
//     list-style:none;
//     padding:0;
//     display:flex;
//     flex-direction: column;
//     align-items:center;
//     justify-content: center;
//     background-color: var(--backLanding);
// `;

const List = styled.li`
    color: var(--color3);
        @media (max-width: 775px) {
            display: none;
        };
`;

const DialogBox = styled.div`
    position: absolute;
    top: 80px;
    right: 20px;
    width: 280px;
    padding: 20px;
    z-index: 88;
    text-align: center;
    color: var(--text2);
    border-radius: var(--radius);
    background: var(--background1);
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
     /* background: #5f678293;
    backdrop-filter: blur(10px); */
    opacity: ${props => !props.ham ? '0' : '1'};
    visibility: ${props => !props.ham ? 'hidden' : 'visible'};
    backface-visibility: ${props => !props.ham ? 'hidden' : 'visible'};;
    transform: ${props => !props.ham ? 'rotate3d(1, 0, 0, -90deg)' : 'rotate3d(0, 0, 0, 0)'};
    transform-origin: 0% 0%;
    transition-property: transform, opacity, visibility;
    transition-duration: 0.1s;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;   
        >h4{
            font-weight:bold;
            color: var(--text2);
        }
        >h5{
            color: var(--text3);
        }
`;

const ListHam = styled.div`
    display: flex;
    flex-direction: column;
`;

const LinkBtn = styled(Link)`
    text-decoration: none;
    color: var(--color3);
    font-weight: bold;
    font-size: var(--fontM);
    display: flex;
    align-items: center;
    padding: 15px 30px;
    cursor: pointer;
    transition: var(--transition) ease;
    border-radius: var(--radius);
    border: 1px solid #ffffff8c;
    /* background-image: linear-gradient(0deg,#ec250d,#fd5d93); */
    /* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
    -moz-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12); */
        &:hover {
            color: var(--color1);
            text-decoration: none;
            /* background-image: linear-gradient(to top, #039790 0%, #03bd91 100%); */
            background:var(--color3);
        }
`;

const LinkBtnHam = styled(Link)`
    text-decoration: none;
    color: var(--color3);
    font-weight: bold;
    font-size: var(--fontM);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 30px;
    cursor: pointer;
    transition: var(--transition) ease;
    border-radius: var(--radius);
    border: 1px solid #ffffff8c;
    background: var(--color4);
        &:hover {
            filter: brightness(0.9);
            color: var(--text5);
        }
`;

const LinkCss = styled(Link)`
    margin-right: 20px;
    color: var(--text5);
    font-size: var(--fontM);
    font-weight: bold;
    text-decoration: none;
    transition: var(--transition-quick) ease;
    cursor: pointer;
        &:hover {
            color: var(--color4);
        }
`;

const LinkHam = styled(Link)`
     width:100%;
    font-size: 14px;
    font-weight:bold;
    color: var(--text2);
    letter-spacing: 1px;
    padding: 15px 20px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom:1px solid rgba(250, 250, 250, 0.1);
    transition: var(--transition) ease;
        >div>i{
            font-size: 18px;
        }
        &:hover {
            color: var(--color4);
            background:var(--hover);
            border-radius: var(--radius);
            font-weight:bold;
        }
`;

const Hr = styled.hr`
    border: none;
    border-bottom: 1px solid var(--color2);
    width: 90%;
    margin: 10px auto;
`;

const makeLinks = [
    {
        to: '/dashboard/aboutUs',
        title: 'About',
    },
    {
        to: '/dashboard/price',
        title: 'Pricing',
    },
    {
        to: '/Register',
        title: 'Register',
    },
];


const NavBar = ({ change }) => {
    const openDialogRef = useRef();
    const [ham, setHam] = useState(false);

    useEffect(() => {
        let handler = (e) => {
            // console.log('haaai', e.target)
            if (openDialogRef.current && !openDialogRef.current.contains(e.target)) {
                if (openDialogRef.current.contains(e.target) === false) {
                    setHam(false);
                } else {
                    setHam(true);
                }
            }
        };
        document.addEventListener("mousedown", handler, true);
        return () => {
            document.removeEventListener("mousedown", handler, true);
        }
    }, [ham]);

    return (
        <NavBarWrapper>
            <NavBarBody>

                <Left>
                    <LogoWrapper>
                        <MainLogo />
                    </LogoWrapper>
                </Left>

                <Right>
                    <UnorderList>
                        <List>
                            {makeLinks.map((link, i) =>
                                <LinkCss
                                    key={i}
                                    to={link.to}
                                    onClick={change}
                                >
                                    {link.title}
                                </LinkCss>
                            )}
                        </List>
                        <List>
                            <LinkBtn to='/login' active='true' onClick={change}>Log In</LinkBtn>
                        </List>
                    </UnorderList>

                    <div ref={openDialogRef}>
                        <HamburgerMenu
                            ham={ham}
                            setHam={setHam}
                        />
                        {/* {ham && */}
                        <DialogBox ham={ham}>
                            <ListHam>
                                <LinkBtnHam to='/login' active='true' onClick={change}>Log In</LinkBtnHam>
                                <Hr></Hr>
                                {makeLinks.map((link, i) =>
                                    <LinkHam
                                        key={i}
                                        to={link.to}
                                        onClick={change}
                                    >
                                        {link.title}
                                    </LinkHam>
                                )}
                            </ListHam>
                        </DialogBox>
                        {/* } */}
                    </div>
                </Right>

            </NavBarBody>

        </NavBarWrapper>
    );
};

export default NavBar;
