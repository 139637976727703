import React, { useState } from 'react';

// PACKEGES
import styled from 'styled-components';
// import Select from 'react-select';

// REDUX
import { useDispatch } from 'react-redux';
import { addNewTeammate } from '../../../../Store/Actions/teammateActions';

// SHARE STYLE
import {
    ModalWrapper,
    DialogBox,
    H3,
    H6,
    Hr,
    LabelCss,
    InputCss,
    BtnWrapper,
    Btn,
} from './ModalStyle';


const ActionBtn = styled.button`
     padding: 5px 10px;
     display:flex;
     align-items: center;
     justify-content:center;
     border:none;
     color: var(--text2);
     border-radius: var(--radius);
     cursor: pointer;
     /* transition: all var(--transition) ease; */
     background: transparent;
     outline: none;
     font-weight: bold;
     font-size: 12px;
     transition: filter var(--transition) ease-in-out;
        >i{
            font-size: 15px;
            padding-right: 10px;
            color: var(--text2);
            /* transition: color var(--transition) ease-in-out; */
        }
         &:hover{
            filter: brightness(0.9);
            color:var(--text5);
             >i{
                color: var(--text4); 
             }
         }
         &:active {
            -webkit-transform: translateY(3px);
            -moz-transform: translateY(3px);
            transform: translateY(3px);
            box-shadow: none;
        }
`;


const TeamModal = ({ props, emp, reset }) => {
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);
    const [access, setAccess] = useState({
        fname: '',
        lname: '',
        email: '',
    });

    const handleClose = (e) => {
        e.preventDefault();
        setOpenModal(false);
        setAccess({
            ...access,
            fname: '',
            lname: '',
            email: '',
        })
    };

    // const handleChange = evt => {
    //     const name = evt.target.name;
    //     const value = evt.target.value;
    //     setAccess({
    //         ...access,
    //         [name]: value
    //     });
    // };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(addNewTeammate(access));
        handleClose(e);
    };


    return (
        <>
            <ActionBtn
                style={{ background: 'var(--color4)', color: 'var(--text5)' }}
                onClick={() => setOpenModal(!openModal)}>
                Add new
            </ActionBtn>

            {openModal &&
                <>
                    <ModalWrapper onClick={(e) => handleClose(e)} />

                    <DialogBox>
                        <H3>New teammate </H3>
                        <Hr></Hr>

                        <form onSubmit={handleSubmit}>
                            <LabelCss htmlFor="fname">First Name<span>*</span></LabelCss>
                            <InputCss
                                placeholder="Teammate first name"
                                id='fname'
                                type="text"
                                name='fname'
                                required
                                value={access.fname}
                                onChange={(e) =>
                                    setAccess({
                                        ...access,
                                        fname: e.target.value
                                    })
                                }
                            >
                            </InputCss>

                            <LabelCss htmlFor="lname">Last Name<span>*</span></LabelCss>
                            <InputCss
                                placeholder="Teammate last name"
                                id='lname'
                                type="text"
                                name='lname'
                                required
                                value={access.lname}
                                onChange={(e) =>
                                    setAccess({
                                        ...access,
                                        lname: e.target.value
                                    })
                                }
                            >
                            </InputCss>

                            <LabelCss htmlFor="email">E-mail<span>*</span></LabelCss>
                            <InputCss
                                placeholder="Teammate e-mail"
                                id='email'
                                type="mail"
                                name='email'
                                required
                                value={access.email}
                                onChange={(e) =>
                                    setAccess({
                                        ...access,
                                        email: e.target.value
                                    })
                                }
                            >
                            </InputCss>

                            <BtnWrapper>
                                <Btn disable onClick={handleClose}>Close</Btn>
                                <Btn type='submit'>Add new</Btn>
                            </BtnWrapper>

                            <H6>all fields marked with * are required</H6>
                        </form>
                    </DialogBox>
                </>
            }
        </>
    );
}
export default TeamModal;