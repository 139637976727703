import React from 'react'
import './MainLogo.scss';
// import { Link } from 'react-router-dom';

// PACKEGES
// import styled from 'styled-components';


// const LogoBody = styled.div`
//     height: 120px;
//     width: 150px;
//     min-width: 150px;
//     border-radius: 100%;
//     left: calc(50% - 75px);
//     top: calc(50% - 75px);
//     margin: 0;
//     padding: 0;
// `;

// const LogoTxt = styled.ul`
//      position: relative;
//     top: 50%;
//     left: 50%;
//     transform: translateX(-50%) translateY(-50%);
//     list-style: none;
//     border-bottom: 0;
//     margin: 0;
//     padding: 0;
//     font-weight: 100!important;
//     text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.91);
//     display: flex;
//     font-size: 40px;
//     color: rgb(255, 255, 255);
//     opacity: 1;
//     /* transition: all 0.5s ease-in-out; */
//     max-width: 2em;
//     border: bottom width 123px;
// `;



const MainLogo = () => {

    return (
        <div className="main-logo-body">
            <div className='circleBk'></div>
            <ul className="main-logo-text">
                <li className="main-logo-first">C</li>
                <li className="main-logo-first">S</li>
                <li className="ghost-1">Y</li>
                <li className="ghost-2">E</li>
                <li className="spaced">S</li>
                <li className="ghost-3">.</li>
                <li className="ghost-sign">!</li>
                <hr className="yes-line"></hr>
            </ul>
            <div className="main-logo-team">team<span>™</span></div>
        </div>
    );
}

export default MainLogo;