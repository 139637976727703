const initState = {
  handleSideBar: false,
  hasMore: true,
  // hasMore: false,
  // loading: true,
  // loading: false,
  content: [],
  loginSts: false,
};

const contentReducer = (state = initState, action) => {
  switch (action.type) {
    case 'RECIVING_CONTENT':
      return {
        ...state,
        loading: true,
      };

    case 'RECIVED_CONTENT':
      return {
        ...state,
        content: action.payload,
        loading: false,
      };

    case 'RECIVE_SEARCH_CONTENT':
      return {
        ...state,
        contentSearch: action.payload,
        loading: false,
      };

    case 'HAS_MORE':
      return {
        ...state,
        hasMore: action.payload,
      };

    case 'RECIVE_CONTENT_CODE_BY_ID':
      return {
        ...state,
        contentById: action.payload,
      };

    case 'NOTIF_HANDLER_CONTENT':
      return {
        ...state,
        handlerNotifContent: action.payload,
      };

    case 'ALL_CONTENT':
      return {
        ...state,
        allContent: action.payload,
      };

      case 'NOT_LOGIN':
      return {
        ...state,
        loginSts: action.payload,
      };

    default:
      return state;
  }
};

export default contentReducer;