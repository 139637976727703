import React, { useEffect, useState, useRef, useCallback } from 'react';

//ASSETS
// import loadingGif from '../../../Assets/loadingBtn2.gif';

// PACKAGES
// import styled from 'styled-components';
// import InfiniteScroll from 'react-infinite-scroll-component';
// SHARE STYLE
import { WrapperRightSide, RightSide, RightSideEmpty } from '../ShareStyle/ShareStyle';

// SHARE COMPONENTS
import Title from '../../DashboardComponents/PagesTitles/PagesTitles';
import CompCards from '../../DashboardComponents/CardComponent/CardComponent';
import CardSkeleton from '../../DashboardComponents/CardComponent/CardSkeleton';
import SawAll from '../Home/SawAllAnimation';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { reciveSearchContent } from '../../../Store/Actions/contentActions';
import { setPageNumber, setPrevLocation, setPrevFlag } from '../../../Store/Actions/otherActions';


const SearchPage = ({ flag }) => {
    const dispatch = useDispatch();
    const cont = useSelector((state) => state.content.contentSearch);

    const pageNumber = useSelector((state) => state.other.pageNumber);
    const prevPageNumber = useSelector(({ other }) => other.prevPageNumber);
    const prevFlag = useSelector(({ other }) => other.prevFlag);

    const loading = useSelector(({ content }) => content.loading);
    const hasMore = useSelector(({ content }) => content.hasMore);

    const [nrOfSkeletonCards] = useState(8);

    const observer = useRef();
    const lastCompElementRef = useCallback(node => {
        if (loading) return
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                dispatch(setPageNumber(pageNumber + 1));
            }
        },
            { threshold: 0.25, rootMargin: "0px" }
        )
        if (node) observer.current.observe(node)
        // console.log('NODE', node)
    }, [dispatch, loading, hasMore, pageNumber]);

    useEffect(() => {
        if (prevPageNumber !== pageNumber) {
            // console.log('AM INTRAT1')
            dispatch(reciveSearchContent(flag));
        }
        if (flag !== undefined) {
            // console.log('AM INTRAT2')
            dispatch(setPrevFlag(flag.search));
        }
    }, [dispatch, pageNumber, prevPageNumber, flag]);

    useEffect(() => {
        dispatch(setPrevLocation(pageNumber));
    }, [dispatch, pageNumber]);

    useEffect(() => {
        if (prevFlag !== undefined) {
            // dispatch(setPrevFlag(flag.flag));
            // console.log('prevFlag', prevFlag);
            if (prevFlag !== flag.search) {
                // console.log('AM INTRAT3')
                const reset = true;
                dispatch(reciveSearchContent(flag, reset));
            }
        }
    }, [dispatch, flag, prevFlag]);

    // console.log('CONTENT', cont);
    // console.log('hasMore', hasMore);
    // console.log('pageNumber', pageNumber);
    // console.log('prevPageNumber', prevPageNumber);
    // console.log('prevFlag', prevFlag);
    // console.log('Flag', flag);
    // console.log('loadingT', loading);


    return (
        <WrapperRightSide>
            <Title
                first='Search'
                second='Here are the results of your search'
                third='Enjoy!'
            />
            {cont !== undefined &&
                <>
                    {cont.length > 0 &&
                        <>
                            <RightSide length={cont.length}>
                                {cont.map((el, i) => {
                                    if (cont.length === i + 1) {
                                        return (
                                            <CompCards
                                                createRef={lastCompElementRef}
                                                key={i}
                                                content={el}
                                            />
                                        )
                                    } else {
                                        return (
                                            <CompCards
                                                key={i}
                                                content={el}
                                            />
                                        )
                                    }
                                }
                                )
                                }
                                {loading &&
                                    [...Array(nrOfSkeletonCards)].map((el, i) =>
                                        <CardSkeleton key={i} />
                                    )
                                }
                            </RightSide>
                            {(!hasMore && cont.length >= 4) && <SawAll />}
                        </>
                    }

                    {cont.length === 0 &&
                        <RightSide>
                            {loading &&
                                [...Array(nrOfSkeletonCards)].map((el, i) =>
                                    <CardSkeleton key={i} />
                                )
                            }
                        </RightSide>
                    }

                    {(!loading && cont.length === 0) &&
                        <RightSideEmpty >
                            <i className="fas fa-sad-tear"></i>
                            <h3>Sorry, no results found!</h3>
                        </RightSideEmpty>
                    }
                </>
            }
        </WrapperRightSide >
    );
};

export default SearchPage;
