export const handleSideBar = (props) => (dispatch) => {
  dispatch({
    type: 'HANDLE_SIDEBAR',
    payload: props,
  });
};

export const openSearch = (props) => (dispatch) => {
  dispatch({
    type: 'HANDLE_SEARCH',
    payload: props,
  });
};

export const setPageNumber = (props) => (dispatch) => {
  dispatch({
    type: 'SET_PAGE_NUMBER',
    payload: props,
  });
};

export const setPrevLocation = (props) => (dispatch) => {
  dispatch({
    type: 'SET_PREV_PAGE_NUMBER',
    payload: props,
  });
};

export const setPrevFlag = (props) => (dispatch) => {
  dispatch({
    type: 'SET_PREV_FLAG',
    payload: props,
  });
};


