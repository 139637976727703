import React, { useEffect, useCallback } from 'react';

// PACKAGES
import styled, { keyframes } from 'styled-components';


const MainWrapper = styled.div`
    position: absolute;
    z-index: 2;
    width:100%;     
`;

const BodyWrapper = styled.div`
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    padding: 0 20px;
`;

const Body = styled.div`
        >h1{
            position: relative;
            font-size: 200px;
            >span:nth-child(1){
                position: absolute;
                top:10px;
                letter-spacing: 20px;
                font-size: 20px;
                padding-left: 20px;
            }
            >span:nth-child(2){
                position: absolute;
                bottom:10px;
                letter-spacing: 2px;
                font-size: 16px;
                padding-left: 20px;
                color: var(--text3);
            }
        }
        @media (max-width: 810px) {
            >h1{
                font-size: 150px;
                >span:nth-child(1){
                    font-size: 15px;
                    letter-spacing: 10px;
                }
                >span:nth-child(2){
                    font-size: 12px;
                }
            }
        };
        @media (max-width: 625px) {
            >h1{
                font-size: 120px;
                >span:nth-child(1){
                    font-size: 12px;
                    letter-spacing: 10px;
                }
                >span:nth-child(2){
                    font-size: 10px;
                }
            }
        };
        @media (max-width: 500px) {
            >h1{
                font-size: 80px;
                padding: 10px 0 10px;
                >span:nth-child(1){
                    font-size: 12px;
                    letter-spacing: 8px;
                    padding-left: 10px;
                }
                >span:nth-child(2){
                    font-size: 10px;
                    letter-spacing: 1px;
                    padding-left: 10px;
                }
            }
        };
`;

const BtnWrapper = styled.div`
   width:100%;
   margin-top:20px;
   display:flex;
   align-items: flex-start;
   justify-content:flex-start;
   padding-left: 20px;
        @media (max-width: 500px) {
            padding-left: 10px;
        };
`;

const fadeBtn = keyframes`
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const Btn = styled.button`
    /* opacity: 0; */
    width: 200px;
    /* height: 50px; */
    padding: 15px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: var(--text2);
    font-weight: bold;
    font-size: var(--fontM);
    letter-spacing: 2px;
    outline: none;
    border-radius: var(--radius);
    background:#ffffffe0;
    /* animation: ${fadeBtn} 0.7s ease-in-out 0.8s forwards;
    -webkit-animation: ${fadeBtn} 0.7s ease-in-out 0.8s forwards;
    -moz-animation: ${fadeBtn} 0.7s ease-in-out 0.8s forwards; */
        >i{
            margin-left: 20px;
            font-size:24px;
            color: var(--text2);
            opacity: .7;
        }
        &:hover{
            color:var(--color4);
            transition: .5s ease-in-out;
            >i{
                color: var(--color4);
                transition: color .3s ease-in-out;
            }
        }
        @media (max-width: 500px) {
            width: 150px;
            padding: 10px;
            font-size: var(--fontS);
            >i{
                margin-left: 12px;
                font-size: 16px;
            }
        };
`;


const MainText = ({ change }) => {
    const handleUserKeyPress = useCallback(event => {
        if (event.keyCode === 13) {
            change(true)

        }
    }, [change]);

    useEffect(() => {
        window.addEventListener("keydown", handleUserKeyPress);
        return () => {
            window.removeEventListener("keydown", handleUserKeyPress);
        };
    }, [handleUserKeyPress]);


    return (
        <MainWrapper>
            <BodyWrapper >
                <Body>
                    <h1 >
                        <span>LET'S DO SOMETING</span>
                        <span>With our fully coded UI tools for web and mobile apps</span>
                        Creative.
                    </h1>

                    <BtnWrapper>
                        <Btn onClick={change}>Get started
                            <i className="fas fa-arrow-circle-right"></i>
                        </Btn>
                    </BtnWrapper>

                </Body>

                {/* <div className="support">Browser Support
                <hr className="support-hr"></hr>
                <div className="tooltip-support" >Chrome<br></br>Safari</div>
            </div > */}
            </BodyWrapper>
        </MainWrapper>
    );
};

export default MainText;