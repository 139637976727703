import axios from 'axios';
axios.defaults.withCredentials = true;

export const reciveContent = (flag, reset) => (dispatch, getState) => {
    // console.log('I`M IN');
    dispatch({ type: 'RECIVING_CONTENT' });

    // console.log(flag, reset)
    if (reset === true) {
        dispatch({
            type: 'RECIVED_CONTENT',
            payload: [],
        });
        dispatch({ type: 'RECIVING_CONTENT' });
        dispatch({
            type: 'HAS_MORE',
            payload: true,
        });
        dispatch({
            type: 'SET_PAGE_NUMBER',
            payload: 1,
        });
        dispatch({
            type: 'SET_PREV_PAGE_NUMBER',
            payload: 1,
        });
    }

    const content = getState().content.content;
    const pageNumber = getState().other.pageNumber;
    const articlesPerPage = 4;
    const body = {
        pageNumber: pageNumber,
        articlesPerPage: articlesPerPage,
        flag: flag !== undefined && flag.flag
    };

    // console.log(pageNumber);
    // console.log('RECIVING_CONTENT', content, pageNumber);
    // console.log('LOADING',getState().content.loading)
    // console.log('CONTENT', content);

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/reciveContent`, body)
        .then((res) => {
            // console.log("RECIVE_CONTENT", res.data)
            dispatch({
                type: 'RECIVED_CONTENT',
                payload: content === undefined ? res.data.content : [...content, ...res.data.content]
            });
            if (res.data.arrLength <= content.length || res.data.content.length < articlesPerPage) {
                dispatch({
                    type: 'HAS_MORE',
                    payload: false,
                });
            }

            // dispatch(notifHandler('success', 'Successfully logged in!'));
        })
        .catch((err) => {
            console.log(err);
            // dispatch(notifHandler('error', err.response.data.message));
        });
};

export const reciveSearchContent = (flag, reset) => (dispatch, getState) => {

    // console.log('I`M IN');
    dispatch({ type: 'RECIVING_CONTENT' });

    // console.log(flag, reset)
    if (reset === true) {
        dispatch({
            type: 'RECIVE_SEARCH_CONTENT',
            payload: [],
        });
        dispatch({ type: 'RECIVING_CONTENT' });
        dispatch({
            type: 'HAS_MORE',
            payload: true,
        });
        dispatch({
            type: 'SET_PAGE_NUMBER',
            payload: 1,
        });
        dispatch({
            type: 'SET_PREV_PAGE_NUMBER',
            payload: 1,
        });
    };

    const content = getState().content.contentSearch;
    const pageNumber = getState().other.pageNumber;
    const articlesPerPage = 4;
    const body = {
        pageNumber: pageNumber,
        articlesPerPage: articlesPerPage,
        search: flag !== undefined && flag.search

    };

    // console.log('SEARCH FORM ACTIONS', flag, reset);
    // console.log(pageNumber);
    // console.log('RECIVING_CONTENT', content, pageNumber);
    // console.log('LOADING',getState().content.loading)
    // console.log('CONTENT', content);

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/reciveSearchContent`, body)
        .then((res) => {
            // console.log("RECIVE_SEARCH_CONTENT", res.data);
            dispatch({
                type: 'RECIVE_SEARCH_CONTENT',
                payload: content === undefined ? res.data.content : [...content, ...res.data.content]
            });
            if (res.data.arrLength <= content.length || res.data.content.length < articlesPerPage) {
                dispatch({
                    type: 'HAS_MORE',
                    payload: false,
                });
            }

            // dispatch(notifHandler('success', 'Successfully logged in!'));
        })
        .catch((err) => {
            console.log(err);
            // dispatch(notifHandler('error', err.response.data.message));
        });
};

export const reciveAllContent = () => (dispatch, getState) => {
    // console.log('ALL_CONTENT',)

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/reciveAllContent`)
        .then((res) => {
            // console.log("ALL_CONTENT", res);
            dispatch({
                type: 'ALL_CONTENT',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

// export const reciveContent = (flag, reset) => (dispatch, getState) => {
//     // console.log('I`M IN');
//     dispatch({ type: 'RECIVING_CONTENT' });

//     // console.log(flag, reset)
//     if (reset === true) {
//         dispatch({
//             type: 'RECIVED_CONTENT',
//             payload: [],
//         });
//         dispatch({ type: 'RECIVING_CONTENT' });
//         dispatch({
//             type: 'HAS_MORE',
//             payload: true,
//         });
//         dispatch({
//             type: 'SET_PAGE_NUMBER',
//             payload: 1,
//         });
//         dispatch({
//             type: 'SET_PREV_LOCATION',
//             payload: 1,
//         });
//     }

//     const content = getState().content.content;
//     const pageNumber = getState().other.pageNumber;
//     const articlesPerPage = 4;
//     const body = {
//         pageNumber: pageNumber,
//         articlesPerPage: articlesPerPage,
//         flag: flag !== undefined && flag.flag
//     };

//     // console.log(pageNumber);
//     // console.log('RECIVING_CONTENT', content, pageNumber);
//     // console.log('LOADING',getState().content.loading)

//     axios
//         .post(`${process.env.REACT_APP_URL_BACKEND}/reciveContent`, body)
//         .then((res) => {
//             // console.log("RECIVE_CONTENT", res)
//             dispatch({
//                 type: 'RECIVED_CONTENT',
//                 payload: content === undefined ? res.data.content : [...content, ...res.data.content]
//             });
//             if (res.data.arrLength <= content.length || res.data.content.length < articlesPerPage) {
//                 dispatch({
//                     type: 'HAS_MORE',
//                     payload: false,
//                 });
//             }

//             // dispatch(notifHandler('success', 'Successfully logged in!'));
//         })
//         .catch((err) => {
//             console.log(err);
//             // dispatch(notifHandler('error', err.response.data.message));
//         });
// };

export const reciveCodeContentById = (id) => (dispatch, getState) => {

    const body = {
        id: id
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/reciveContentById`, body)
        .then((res) => {
            // console.log("RECIVE_CONTENT_BY_ID", res)
            dispatch({
                type: 'RECIVE_CONTENT_CODE_BY_ID',
                payload: res.data,
            });
            dispatch({
                type: 'NOT_LOGIN',
                payload: false
            });
            // dispatch(notifHandler('success', 'Successfully logged in!'));
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: 'NOT_LOGIN',
                payload: true
            });
            // dispatch(notifHandler('error', err.response.data.message));
        });
};

export const addPosterAndContent = (more) => (dispatch, getState) => {
    // console.log('ADD_POSTER', more)

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/addPoster`, more.poster)
        .then((res) => {
            // console.log("ADD_POSTER", res)
            // dispatch(addContent(more, res.data.secure_url));
            dispatch(addContent(more, res.data));
            dispatch(notifHandler('success', 'Successfully add poster!'));
        })
        .catch((err) => {
            console.log(err);
            dispatch(notifHandler('error', err.response.data.message));
        });
};

export const addContent = (more, poster) => (dispatch, getState) => {
    // console.log('ADD_CONTENT', more, poster)

    const body = {
        technology: more.tech,
        theme: more.theme,
        name: more.componentName,
        link: more.link,
        category: more.category,
        newcategory: more.newcategory,
        keywords: more.keywords,
        madeWith: more.madeWith,
        poster: poster,
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/addContent`, body)
        .then((res) => {
            // console.log("ADD_CONTENT", res)
            // dispatch({
            //     type: 'ADD_CONTENT',
            //     payload: res.data,
            // });
            dispatch(notifHandler('success', res.data.message));
            // dispatch(reciveContent())
        })
        .catch((err) => {
            console.log(err);
            dispatch(notifHandler('error', err.response.data.message));
        });
};

export const editPosterAndContent = (more, id, role) => (dispatch, getState) => {
    console.log('EDIT_POSTER', more)

    if (more.poster !== '') {
        axios
            .post(`${process.env.REACT_APP_URL_BACKEND}/editPoster`, more.poster)
            .then((res) => {
                // console.log("EDIT_POSTER", res.data);
                // dispatch(editContent(more, res.data.secure_url, id, role));
                dispatch(editContent(more, res.data, id, role));
                // dispatch(notifHandler('success', 'Successfully logged in!'));
                // dispatch(reciveContent());
            })
            .catch((err) => {
                console.log(err);
                dispatch(notifHandler('error', err.response.data.message));
            });
    } else {
        dispatch(editContent(more, more.poster, id, role))
    }
};

export const editContent = (more, poster, id, role) => (dispatch, getState) => {
    // console.log('EDIT_CONTENT', more, poster, id, role)

    const body = {
        id: id,
        role: role,
        technology: more.tech,
        theme: more.theme,
        name: more.componentName,
        link: more.link,
        category: more.category,
        newcategory: more.newcategory,
        keywords: more.keywords,
        madeWith: more.madeWith,
        poster: poster,
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/editContent`, body)
        .then((res) => {
            // console.log("ADD_CONTENT", res)
            // dispatch({
            //     type: 'ADD_CONTENT',
            //     payload: res.data,
            // });
            dispatch(notifHandler('success', res.data.message));
            // dispatch(reciveContent());
        })
        .catch((err) => {
            console.log(err);
            dispatch(notifHandler('error', err.response.data.message));
        });
};

export const deleteContent = (id, role) => (dispatch, getState) => {
    // console.log('DELETE_CONTENT', id, role)

    const body = {
        id: id,
        role: role,
    };

    axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/deleteContent`, body)
        .then((res) => {
            // console.log("DELETE_CONTENT", res)
            dispatch({
                type: 'DELETE_CONTENT',
                payload: res.data,
            });
            dispatch(notifHandler('success', res.data.message));
            // dispatch(reciveContent());
        })
        .catch((err) => {
            console.log(err);
            dispatch(notifHandler('error', err.response.data.message));
        });
};

const notifHandler = (flag, msg) => (dispatch) => {
    const obj = {
        flag: flag,
        msg: msg,
    };
    dispatch({
        type: 'NOTIF_HANDLER_CONTENT',
        payload: obj,
    });
    setTimeout(() => {
        dispatch({
            type: 'NOTIF_HANDLER_CONTENT',
            payload: undefined,
        });
    }, 3000);
};
