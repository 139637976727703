import React from 'react'
// import { Link } from 'react-router-dom';

// PACKAGES
import styled from 'styled-components';

// BACKGROUND
import img from '../Assets/Frame1.png';

// COMPONENTS
import NavBar from './LandingPageComponents/NavBar';
import MainText from './LandingPageComponents/MainText/MainText';
// import Shapes from './Components/Shapes';
import Shapes from './LandingPageComponents/Final';

const BackgroundWall = styled.div`
    position: relative;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    /* background-image: url(${img}); */
    /* background-size: cover; */
    /* background-position: center; */
    background-image: linear-gradient(to top,#28283e 0%,#52527b);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const BackgroundBlur = styled.div`
    position:absolute;
    z-index: 1;
    top:0;
    left: 0;
    height: 100vh;
    width: 70%;
    background-color: var(--backLanding);
    backdrop-filter: blur(3px);
    margin: 0;
    padding: 0;
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
`;


const LandingPage = ({ change }) => {

    return (
        <BackgroundWall>
            <Shapes />
            <NavBar change={change} />
            <BackgroundBlur />
            <MainText change={change} />
        </BackgroundWall>

        // <BackgroundWall>
        //     <Shapes/>
        //     <NavBar change={change}/>   
        //     <BackgroundBlur>

        //         <MainText change={change}/>
        //         {/* <Btn onClick= {change}>Go</Btn> */}
        //     </BackgroundBlur>
        // </BackgroundWall>
    );
}

export default LandingPage;



// const fadeBtn = keyframes`
//     from {
//             opacity: 0;
//         transform: translateY(20px) translateX(-50%);
//     }
//     to {
//         opacity: 1;
//         transform: translateY(0) translateX(-50%);
//     }
// `;

// const Btn = styled.button`
//     width: 50px;
//     height: 50px;
//     /* position: absolute; */
//     /* bottom: 15px; */
//     /* left: 50%; */
//     background: rgb(62, 58, 58);
//     border: none;
//     border-radius: 200px;
//     color: #999;
//     font-size: 18px;
//     // font-weight: bold;
//     text-transform: uppercase;
//     letter-spacing: 2px;
//     outline: none;
//     background: linear-gradient(145deg, #4e4d4d, #131313);
//     box-shadow: 7px 7px 14px #2f2f2f, -7px -7px 14px #212121;
//     -webkit-box-shadow: 7px 7px 14px #2f2f2f, -7px -7px 14px #212121;
//     -moz-box-shadow: 7px 7px 14px #2f2f2f, -7px -7px 14px #212121;
//     opacity: 0;
//     animation: ${fadeBtn} 0.7s ease-in-out 0.8s forwards;
//     -webkit-animation: ${fadeBtn} 0.7s ease-in-out 0.8s forwards;
//     -moz-animation: ${fadeBtn} 0.7s ease-in-out 0.8s forwards;
//         &:hover{
//             color: #00BFA6;
//             transition: .5s ease;
//         }
//         &:active{
//             box-shadow: none;
//             box-shadow: inset 7px 7px 31px #070707, inset -7px -7px 31px #707070;
//             -webkit-box-shadow: inset 7px 7px 31px #070707, inset -7px -7px 31px #707070;
//             -moz-box-shadow: inset 7px 7px 31px #070707, inset -7px -7px 31px #707070;
//             -o-box-shadow: inset 7px 7px 31px #070707, inset -7px -7px 31px #707070;
//         }
// `;