import React, { useState } from 'react'
import { Link } from 'react-router-dom';

// PACKAGES
import styled from 'styled-components';
import { CookieStorage } from 'cookie-storage';


const CookieWrapper = styled.div`
    position: fixed;
    z-index:888;
    bottom: 0;
    background: var(--background5);
    backdrop-filter: blur(10px);
    width: calc(100% - 250px);
    margin-left: 250px;
    border-top: 1px solid #4646691f;
        @media (max-width: 775px) {
            width:100%;
            margin-left:0;
        };
`;

const CookieBody = styled.div`
    width: 100%;
    min-height: 400px;
    padding:20px;
`;

const Title = styled.h1`
    padding: 20px 0;
    text-align: left;
`;

const Text = styled.p`
    width: 80%;
    padding: 20px 0;
    color: var(--text5);
    text-align: left;
        >a{
            text-decoration: none;
            color:var(--text5);
            font-weight: bold;
            transition: all .3s;
                &:hover{
                    color:var(--color4);
                }
        }
`;

const Hr = styled.hr`
    width: 100%;
    border:none;
    border-top: 1px solid var(--background4);
    margin:auto;
`;

const BtnWrapper = styled.div`
   width:100%;
   display:flex;
   align-items: flex-start;
   justify-content:flex-start;
`;

const Btn = styled.button`
    padding: 15px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: var(--text5);
    font-weight: bold;
    font-size: var(--fontM);
    letter-spacing: 2px;
    outline: none;
    border-radius: var(--radius);
    background: var(--backBtn);
        >i{
            margin-left: 20px;
            font-size: 24px;
            color: var(--text5); 
        }
        &:hover{
            /* color: var(--text4); */
            filter: brightness(0.9);
            /* opacity: .8; */
            transition: filter .3s ease-in-out;
            /* transition: color .3s ease-in-out;   */
            >i{
                /* color: var(--text4); */
                /* transition: color .3s ease-in-out;   */
            }
        }
        &:active{
            transform:translateY(2px) ;
        }  
`;


const CookieBanner = () => {
    const cookieStorage = new CookieStorage();
    const [accept, setAccept] = useState(cookieStorage.getItem('csyesCookieConsent') === null ?
        true
        :
        JSON.parse(cookieStorage.getItem('csyesCookieConsent'))
    );

    const setCookie = () => {
        setAccept(false);
        const theDate = new Date();
        const timeToExpires = new Date(theDate.getTime() + 31536000000);
        // const timeToExpires = new Date(the.getTime() + (90 * 24 * 60 * 60 * 1000)); fot 3 months

        cookieStorage.setItem('csyesCookieConsent', false, {
            // path: '/',
            // domain: 'example.com',
            expires: timeToExpires,
            // secure: true,
            // sameSite: 'Strict' // Can be 'Strict' or 'Lax'
        });
    };

    return (
        accept &&
        <CookieWrapper>
            <CookieBody>
                <Title>We Care About Your Privacy</Title>
                <Hr></Hr>
                <Text>
                    We and our partners use cookies and by clicking “Accept cookies”  you agree to their storage on your device to enhance site navigation, analyze site usage and assist in our marketing efforts.
                    <br></br>See here our <Link to='/dashboard/legal'>Cookie Policy</Link>.
                    {/* We and our partners use cookies to store and access personal data such as browsing data for purposes such as serving and personalizing content and advertising and analyzing site traffic.
                    You may find out more about the purposes for which we and our partners use cookies or exercise your preferences by clicking the ‘Cookie Settings’ button below.
                    You can revisit your consent choices or withdraw consent at any time by clicking the link to your cookie settings in our <Link to='/dashboard/legal'>Cookie Policy</Link>. */}
                </Text>

                <BtnWrapper>
                    <Btn onClick={setCookie}>Accept cookies
                        <i className="fas fa-cookie-bite"></i>
                    </Btn>
                </BtnWrapper>
            </CookieBody>
        </CookieWrapper>
    );
}

export default CookieBanner;