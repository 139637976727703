import React, { useState } from 'react';
import './Terms.css';

// ASSETS
// import Help from '../../../../Assets/help.png';
import spin from '../../../../Assets/loadingBtn2.gif';

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
import Title from '../../PagesTitles/PagesTitles';

// REDUX
import { useDispatch } from 'react-redux';
import { sentMsg } from '../../../../Store/Actions/dashboardActions';

const WrapperRightSide = styled.div`
    width:calc(100% - 250px);
    min-height:calc(100vh - 135px);
    margin-left:250px;
    margin-top: 80px;
    padding: 0px 15px 30px;
        @media (max-width: 775px) {
            width:100%;
            margin-left:0;
        };
`;

const FormWrapper = styled.form`
    width: 100%;
    max-width: 800px;
    background: var(--color5);
    margin: 40px auto;
    padding: 20px;
    border-radius: var(--radius);
    display: block;
    text-align: center;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
        >h2{
            padding: 20px 0;
        }
`;

const InputWrapper = styled.label`
	display: block;
    width: ${props => !props.full ? '70%' : '100%'};
    margin: auto;
        @media (max-width: 775px) {
            width: 100%;
        };
`;

const LabelCss = styled.label`
	display: flex;
    align-items: start;
	font-size: 1rem;
	margin: 20px 10px 10px;
    color: var(--text5);
`;

const InputCss = styled.input`
    width:100%;
    height: 50px;
    padding: 6px 12px;
    border: 1px solid var(--color6);
    border-radius: var(--radius);
    background: var(--color7);
    color: var(--text5);
    transition: var(--transition) ease;
    box-shadow: 0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08)!important;
    /* font-family: var(--fontFam); */
    /* letter-spacing:2px; */
        &::placeholder { 
            color: var(--text2);
            font-size: 14px;
        }
        &:hover{
            border-color: var(--border);
        }
        &:focus{
            outline:none;
            border: 1px solid var(--color4);
        }
        &:-webkit-autofill{
            -webkit-text-fill-color: var(--text5);
            -webkit-box-shadow: 0 0 0px 1000px #fff inset;
            transition: background-color 5000s ease-in-out 0s; 
        }
`;

const TextareaCss = styled.textarea`
    width:100%;
    height: 200px;
    resize: none;
    padding: 6px 12px;
    border: 1px solid var(--color6);
    border-radius: var(--radius);
    background: var(--color7);
    color: var(--text5);
    transition: var(--transition) ease;
    box-shadow: 0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08)!important;
    /* font-family: var(--fontFam); */
    /* letter-spacing:2px; */
    font-size: 14px;
        &::placeholder { 
            color: var(--text2);
            font-size: 14px;
            font-family: 'Quicksand', sans-serif;
        }
        &:hover{
            border-color: var(--border);
        }
        &:focus{
            outline:none;
            border: 1px solid var(--color4);
        }
        &:-webkit-autofill{
            -webkit-text-fill-color: var(--text5);
            -webkit-box-shadow: 0 0 0px 1000px #fff inset;
            transition: background-color 5000s ease-in-out 0s; 
        }
`;

const BtnWrapper = styled.div`
    position: relative;
    width: 70%;
    margin: 0 auto;
`;

const Btn = styled.button`
    width: 100%;
    height:50px;
    display:flex;
    align-items: center;
    justify-content:center;
    color:var(--text5);
    font-weight:bold;
    letter-spacing:2px;
    border-radius: var(--radius);
    margin: 40px 0;
    cursor: pointer;
    border: none;
    outline:none;
    box-shadow: 0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08)!important;
    transition: filter var(--transition) ease-in-out;
    background: ${props => (props.type === 'submit' && 'var(--color4)') ||
        (props.disabeld && 'var(--background4)') ||
        (props.sent && 'var(--success)')
    };
            > img{
                width: 35px;
                margin-right: 10px;
            }
            &:hover{
                filter: brightness(0.9);
                color: var(--text5)
            }
            &:active {
                -webkit-transform: translateY(3px);
                -moz-transform: translateY(3px);
                transform: translateY(3px);
                box-shadow: none;
            }
`;


const ContactUs = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [sent, setSent] = useState(false);
    const [body, setBody] = useState({
        email: '',
        msg: '',
    });


    const submitForm = (e) => {
        e.preventDefault();
        setLoading(true);
        dispatch(sentMsg(body));
        setTimeout(() => {
            setLoading(false);
            setSent(true);
            setBody({
                ...body,
                email: '',
                msg: '',
            })
            setTimeout(() => {
                setSent(false);
            }, 4000);
        }, 4000);
    }

    return (
        <WrapperRightSide>

            <Title
                first='Contact Us'
                second='We are here to help you!'
                // third='you best!'
            />

            <FormWrapper onSubmit={submitForm}>
                <h2>Please tell us what happened</h2>
                <InputWrapper smallR>
                    <LabelCss htmlFor="email">E-mail</LabelCss>
                    <InputCss
                        placeholder="Enter your e-mail"
                        id='email'
                        type="text"
                        name='email'
                        required
                        value={body.email}
                        onChange={(e) =>
                            setBody({
                                ...body,
                                email: e.target.value
                            })
                        }
                    >
                    </InputCss>
                </InputWrapper>

                <InputWrapper smallR>
                    <LabelCss htmlFor="msg">Message</LabelCss>
                    <TextareaCss
                        placeholder="How can we help you?"
                        id='msg'
                        type="text"
                        name='msg'  
                        required
                        value={body.msg}
                        onChange={(e) =>
                            setBody({
                                ...body,
                                msg: e.target.value
                            })
                        }
                    >
                    </TextareaCss>
                </InputWrapper>

                <BtnWrapper>
                    {/* <Btn type='submit'>Register</Btn> */}
                    {loading ?
                        <Btn disabled>
                            <img src={spin} alt='loading'></img>
                            Sending...
                        </Btn>
                        :
                        sent ?
                            <Btn sent>Sent</Btn>
                            :
                            <Btn type='submit'>Send to Us</Btn>
                    }
                </BtnWrapper>

            </FormWrapper>

        </WrapperRightSide>
    )
}

export default ContactUs;