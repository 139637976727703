import React, { Component } from 'react';
import './Terms.css';




class Privacy extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    


    render() {
        return (
            <div className="terms">

                <div className="terms-title"><h1>Privacy Policy</h1>
                    <h6>Last updated: Jun 19th, 2020</h6>
                </div><br></br>

                <hr className="terms-hr"></hr><br></br>

                <div className="terms-subtitle"><h4>General rules</h4></div>
                {/* <p>CSSYES<span className="terms-span">!</span> Team does not process any order payments through the website. All payments are processed securely through <span className="span-stripe">Stripe</span> Checkout, a third parth online payment provider.
                </p><br></br> */}

                <p><span className="csyes-span">CSYES<span className="terms-span">!</span> Team</span> does not share personal information of any kind with anyone. We will not sell or rent your name or personal information to any third party. We do not sell, rent or provide outside access to our mailing list or any data we store. Any data that a user stores via our facilities is wholly owned by that user or business. At any time a user or business is free to take their data and leave, or to simply delete their data from our facilities.</p>
                <p><span className="csyes-span">CSYES<span className="terms-span">!</span> Team</span> only collects such personal information that is necessary for you to access and use our services. This personal information includes, but is not limited to, first and last name, email address.</p>
                <p><span className="csyes-span">CSYES<span className="terms-span">!</span> Team</span>  may release personal information if <span className="csyes-span">CSYES<span className="terms-span">!</span> Team</span> is required to by law, search warrant, subpoena, court order or fraud investigation. We may also use personal information in a manner that does not identify you specifically nor allow you to be contacted but does identify certain criteria about our Site's users in general (such as we may inform third parties about the number of registered users, number of unique visitors, and the pages most frequently browsed).</p>


                <div className="terms-subtitle"><h4>Changes about privacy</h4></div>
                <p>If we change our terms of use we will post those changes on this page. Registered users will be sent an email that outlines changes made to the terms of use.</p>
                
            </div>
        )
    }
}

export default Privacy;