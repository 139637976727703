const initState = {
  hasMoreGradient: true,
  gradient: [],
  pageNumberGradient: 1,
};

const gradientReducer = (state = initState, action) => {
  switch (action.type) {
    case 'RECIVING_GRADIENT':
      return {
        ...state,
        loadingGradient: true,
      };

    case 'RECIVED_GRADIENT':
      return {
        ...state,
        gradient: action.payload,
        loadingGradient: false,
      };

    case 'RECIVE_SEARCH_GRADIENT':
      return {
        ...state,
        gradientSearch: action.payload,
        loadingGradient: false,
      };

    case 'HAS_MORE_GRADIENT':
      return {
        ...state,
        hasMoreGradient: action.payload,
      };

    case 'SET_PAGE_NUMBER_GRADIENT':
      return {
        ...state,
        pageNumberGradient: action.payload,
      };

    case 'SET_PREV_PAGE_NUMBER_GRADIENT':
      return {
        ...state,
        prevPageNumberGradient: action.payload,
      };

    case 'NOTIF_HANDLER_GRADIENT':
      return {
        ...state,
        handlerNotifGradient: action.payload,
      };

    default:
      return state;
  }
};

export default gradientReducer;