import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

// COMPONENTS
// import AdminNav from '../Components/AdminNav';
// import Other from '../Components/OtherLog';
// import Checkbox from '../Components/Checkbox';
import Title from '../../Dashboard/DashboardComponents/PagesTitles/PagesTitles';

// ASSETS
import spin from '../../Assets/loadingBtn2.gif';

// PACKEGES
// import styled, { css } from 'styled-components';
import PinInput from "react-pin-input";

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { confirmEmail } from '../../Store/Actions/authActions';

// SHARE STYLE
import {
    AuthWrapper,
    RightSideAuth,
    FormWrapper,
    H1,
    H5,
    H6,
    // InputWrapper,
    // PswWrapper,
    // EyeWrapper,
    LabelCss,
    InputCss,
    // InputNum,
    // InputNumContainer,
    // Label,
    // CheckTxt,
    BtnWrapper,
    Btn,
    LinkCss
} from '../ShareStyle/ShareStyle';

// const pinField = css`
// 	background-color: rgb(248, 249, 250);
//     border: 1px solid rgb(204, 204, 204);
//     width:1rem;
//     border-radius: 0.3rem;
//     font-size: 2rem;
//     margin: 0.25rem;
//     height: 3.5rem;
//     outline: none;
//     text-align: center;
//     transition-duration: 250ms;
//     transition-property: background, color, border, box-shadow, transform;
//             &:focus {
//                 border-color: rgb(0, 123, 255);
//                 outline: none;
//                 transform: scale(1.05);
//         }
// `;



const ConfirmEmail = () => {
    const dispatch = useDispatch();
    let history = useHistory();
    const confirm = useSelector((state) => state.auth.confirmEmail);
    const loading = useSelector((state) => state.auth.authLoading);
    const [access, setAccess] = useState({
        email: '',
        nr: '',
    });
    const [timeLeft, setTimeLeft] = useState();

    const submitForm = (e) => {
        e.preventDefault();
        dispatch(confirmEmail(access));
        setTimeLeft(5);
    }

    // console.log('CONFIRMATION', timeLeft, access)
    // console.log('COMFIRMATION', access.nr);

    if (confirm === 'The e-mail is confirmed!') {
        setTimeout(() => {
            history.push('/login')
        }, 5000);
    };

    useEffect(() => {
        // exit early when we reach 0
        if (!timeLeft) return;
        // save intervalId to clear the interval when the
        // component re-renders
        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
        // add timeLeft as a dependency to re-rerun the effect
        // when we update it
    }, [timeLeft]);

    const onChange = (e) => {
        setAccess({
            ...access,
            nr: e
        })
    };


    return (
        <AuthWrapper>
            <Title
                first='WELCOME!'
                second='Please check your email for the code!'
                third=''
            />

            <RightSideAuth style={{ background: 'var(--background2)' }}>

                <FormWrapper signin onSubmit={submitForm}>
                    <H1>Confirm e-mail</H1>

                    <LabelCss htmlFor="email">E-mail<span>*</span></LabelCss>
                    <InputCss
                        placeholder="Enter your e-mail"
                        id='email'
                        type="mail"
                        name='email'
                        required
                        value={access.email}
                        onChange={(e) =>
                            setAccess({
                                ...access,
                                email: e.target.value
                            })
                        }
                    >
                    </InputCss>

                    <LabelCss htmlFor="nr">Confirmation number<span>*</span></LabelCss>
                    <PinInput
                        length={7}
                        initialValue=""
                        onChange={(value, index) => { onChange(value, index) }}
                        onComplete={(value, index) => { onChange(value, index) }}
                        type="numeric"
                        inputMode="number"
                        style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(7,auto)',
                            columnGap: '10px',
                            rowGap: '15px',
                        }}
                        inputStyle={{
                            borderColor: 'var(--color6)',
                            borderRadius: 'var(--radius)',
                            color: 'var(--text5)',
                            fontSize: '26px',
                            background: 'var(--color7)',
                            // margin: '0  5px 10px 0 ',
                            boxShadow: '0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08)',
                        }}
                        inputFocusStyle={{ borderColor: 'var(--color4)' }}
                        // onComplete={(value, index) => { }}
                        autoSelect={true}
                        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                    />

                    <BtnWrapper>
                        {/* <Btn type='submit'>Confirm</Btn> */}
                        {loading ?
                            <Btn disabled>
                                <img src={spin} alt='loading'></img>
                                Loading...
                            </Btn>
                            :
                            confirm === undefined ?
                                <Btn type='submit'>Confirm</Btn>
                                :
                                <Btn disabled>Redirect in {timeLeft}s</Btn>
                        }
                    </BtnWrapper>

                    <H5>
                        Resend the confirmation e-mail
                        <LinkCss to='/resendConfirmEmail' >here!</LinkCss >
                    </H5>
                    <H6>all fields marked with * are required</H6>
                </FormWrapper>

            </RightSideAuth>

        </AuthWrapper>
    );
}
export default ConfirmEmail;