import React, { useState } from 'react';
import { NavLink } from "react-router-dom";

//STYLED COMPONENTS
// import styled, { keyframes } from 'styled-components';
import styled from 'styled-components';
import { List, Icon } from '../SharedStyle/Styles.css';

// REDUX
import { useSelector } from 'react-redux';

const Arrow = styled.div`
    position:absolute;
    top: 50%;
    right: 20px;
    transform: translate(0,-50%);
        >span{
            display: inline-block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 5px 0 5px 6px;
            border-color: transparent transparent transparent #a2a2a27b;
            pointer-events: none;
            transform: ${props => props.open ? 'rotate(0deg)' : 'rotate(90deg)'};
            border-left: ${props => !props.open && '6px solid var(--background1)'};
            transition: all .2s;
        }
`;

const NavBtn = styled.div`
        position:relative;
        width:100%;
        color: var(--text5);
        padding: 15px 20px;
        text-decoration: none;
        display:flex;
        align-items: center;
        justify-content:left;
        /* border-bottom:1px solid rgba(250, 250, 250, 0.1); */
        transition: var(--transition) ease;
        cursor:pointer;
        background: ${props => props.open && 'var(--hover)'};
            &:hover {
                    background:var(--hover);
                }
`;

const activeClassName = 'nav-item-active'
const NavLinks = styled(NavLink).attrs({ activeClassName })`
    position:relative;
    width:100%;
    color: var(--text5);
    padding: 15px 20px;
    text-decoration: none;
    display:flex;
    align-items: center;
    justify-content:left;
    /* border-bottom:1px solid rgba(250, 250, 250, 0.1); */
    transition: var(--transition) ease;
        &:hover {
        background:var(--hover);
        }
        &.${activeClassName} {
            background:var(--hover);
            border-left: 3px solid var(--text4);
        }
`;

const Newest = styled.div`
    position:absolute;
    right:20px;
    /* padding:4px 8px; */
    border-radius:20px;
    font-size:12px;
    font-weight: bold;
    /* background-color:var(--err); */
    text-shadow: 2px 2px 3px rgb(0 0 0 / 91%);
`;

// const grow = keyframes
//   0% {
//       opacity: 0;
//       transform: rotateX(-100deg);
//       transform-origin: top;
//     }
  
//     100% {
//       opacity: 1;
//       transform: rotateX(0deg);
//       transform-origin: top;
//     }
// `;

const SubMenu = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #00000029;
    overflow: hidden;
    height: ${props => props.opened === 'true' ? (props.tall * 55) : 0}px;
    transition: all 0.4s ease;
`;

const SubLinks = styled(NavLink).attrs({ activeClassName })`
    width:100%;
    color: var(--text5);
    /* padding: 15px 20px; */
    padding: 0px 20px;
    height: 55px!important;
    text-decoration: none;
    display:flex;
    align-items: center;
    justify-content:left;
    /* border-bottom:1px solid rgba(250, 250, 250, 0.1); */
    transition: var(--transition) ease;
        >span{
            margin-left: 14px;
        }
        &:hover {
            background:var(--hover);
        }
        &.${activeClassName} {
                background:var(--hover);
                border-left: 3px solid var(--text4);
            }
`;



const SideBarBtns = ({ index, to, icon, title, span, subBtn, handleClick }) => {
    const [openSubMenu, setOpenSubMenu] = useState(false);
    const loading = useSelector(({ content }) => content.loading);

    const handleSubMenu = () => {
        if (subBtn !== undefined) {
            setOpenSubMenu(!openSubMenu)
        }
    };

    // console.log('SIDE BAR', to)
    // console.log('loading', loading)

    return (
        <List>
            {subBtn === undefined ?
                // <NavLinks
                //     exact
                //     to={to}
                //     onClick={handleClick}
                // >
                //     <Icon>{icon}</Icon>
                //     {title}
                //     {span !== undefined &&
                //         <Newest>{span}</Newest>
                //     }
                // </NavLinks>
                loading === true ?
                    // Disabled link
                    <NavLinks
                        to={to}
                        onClick={(event) => event.preventDefault()}
                    >
                        <Icon>{icon}</Icon>
                        {title}
                        {span !== undefined &&
                            <Newest>{span}</Newest>
                        }
                    </NavLinks>
                    :
                    <NavLinks
                        exact
                        to={{
                            pathname: to,
                            state: { flag: title }
                        }}
                        onClick={handleClick}
                    >
                        <Icon>{icon}</Icon>
                        {title}
                        {span !== undefined &&
                            <Newest>{span}</Newest>
                        }
                    </NavLinks>
                :
                <NavBtn onClick={() => handleSubMenu()}>
                    <Icon>{icon}</Icon>
                    {title}
                    {span !== undefined &&
                        <Newest>{span}</Newest>
                    }
                    {subBtn !== undefined &&
                        <Arrow open={!openSubMenu && 'open'}>
                            <span></span>
                        </Arrow>
                    }
                </NavBtn>
            }

            {/* {subBtn !== undefined && */}
            {/* // openSubMenu && */}
            <SubMenu
                tall={`${subBtn !== undefined && subBtn.length}`}
                // opened={openSubMenu ? 'true' : 'false'}
                opened={openSubMenu ? 'true' : 'false'}
            >
                {subBtn !== undefined &&
                    subBtn.map((btn, i) =>
                        loading === true ?
                            // Disabled link
                            <div key={i}>
                                <SubLinks
                                    key={i}
                                    to={to + '/' + btn.toLowerCase()}
                                    onClick={(event) => event.preventDefault()}
                                >
                                    {btn.slice(0, 2).toUpperCase()}
                                    <span>{btn}</span>
                                </SubLinks>
                            </div>
                            :
                            <div key={i}>
                                <SubLinks
                                    key={i}
                                    // to={to + '/' + btn.toLowerCase()}
                                    to={{
                                        pathname: to + '/' + btn.toLowerCase(),
                                        state: { flag: btn }
                                    }}
                                    onClick={handleClick}
                                >
                                    {btn.slice(0, 2).toUpperCase()}
                                    <span>{btn}</span>
                                </SubLinks>
                            </div>
                    )}
            </SubMenu>
            {/* } */}

            {/* {subBtn !== undefined &&
                openSubMenu &&
                <SubMenu>
                    {subBtn.map((btn, i) =>
                        <SubLinks
                            key={i}
                            to={to + '/' + btn.toLowerCase()}
                            onClick={handleClick}
                        >
                            {btn.slice(0, 2).toUpperCase()}
                            <span>{btn}</span>
                        </SubLinks>
                    )}
                </SubMenu>
            } */}
        </List>
    );
};

export default SideBarBtns;