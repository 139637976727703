import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';

//ASSETS
import loadingGif from '../../../Assets/loadingBtn2.gif';

// PACKEGES
import styled from 'styled-components';

// SHARE STYLE
import {
    WrapperRightSide,
    // RightSide,
} from '../ShareStyle/ShareStyle';

// SHARE COMPONENTS
import Title from '../../DashboardComponents/PagesTitles/PagesTitles';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { reciveCodeContentById } from '../../../Store/Actions/contentActions';


const Iframe = styled.iframe`   
    width: 100%;
    height: 100%;
    border: none;
`;

const RightSide = styled.div`   
    position: relative;
    width: 100%;
    height: calc(100vh - 185px);
    background: var(--background3);
    border-radius: var(--radius);
    /* padding: 30px; */
    margin-top: 20px;
    display: flex;
    align-items:center;
    justify-content: center;
    overflow: hidden;
`;

const RightSideEmpty = styled.div`
    max-width: 2200px;
    margin: auto;
    height: calc(100vh - 260px);
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
            >i{
                font-size: 80px;
                color: var(--text2);
            }
            >h3{
                color: var(--text2);
                font-size: var(--fontXXL);
                margin-top: 20px;
            }
`

const Load = styled.div`  
    position: absolute;
    background: var(--background5);
    backdrop-filter: blur(10px);
    width: 100%;
    height: 100%;
    color: var(--text5);
    font-size: 20px;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
        >img{
            width:35px;
            margin-right: 10px;
        }
`;

const BtnLink = styled(Link)`
    width: 250px;
    height:50px;
    display:flex;
    align-items: center;
    justify-content:center;
    color:var(--text5);
    font-weight:bold;
    letter-spacing:2px;
    border-radius: var(--radius);
    margin: 40px auto;
    cursor: pointer;
    border: none;
    outline:none;
    text-decoration: none;
    transition: filter var(--transition) ease-in-out;
    /* background: linear-gradient(60deg,#f3a81f,#e08b2a); */
    background-color: ${props => !props.less ? 'var(--color4)' : 'var(--color6)'};
    box-shadow:${props => !props.less ?
        '0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px #da13f5ad'
        :
        '0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08)'
    };
        &:active{
                transform:translateY(2px) ;
        }
        >a{
          
          color: var(--text5);
        }
`;


const CodeComp = ({ props }) => {
    const location = useLocation();
    let history = useHistory();
    const dispatch = useDispatch();
    const contentById = useSelector((state) => state.content.contentById);
    const profile = useSelector((state) => state.auth.user);
    // const [prof, setProf] = useState();
    const loginSts = useSelector((state) => state.content.loginSts);

    const [load, setLoad] = useState(false);
    const [url, setUrl] = useState();
    const [title, setTitle] = useState();

    // console.log('profile', profile);

    // const [url] = useState(props.content.CodeURL);
    // const [title] = useState(props.content.Name);
    // console.log('CODE COMP', contentById);

    // useEffect(() => {
    //     setProf(profile)
    // }, [profile])
    // console.log("Prove them wrong", profile);

    useEffect(() => {
        const id = location.pathname.split('/')[3];
        dispatch(reciveCodeContentById(id));
    }, [dispatch, location])

    useEffect(() => {
        if (contentById !== undefined) {
            setUrl(contentById.CodeURL);
            setTitle(contentById.Name);
        }
    }, [contentById])

    useEffect(() => {
        setTimeout(() => {
            setLoad(true)
        }, 3000)
    }, []);

    return (
        profile !== undefined && !loginSts ?
            profile.Stripe[0].payStatus === 'paid' ?
                <WrapperRightSide>
                    <Title
                        first='Code'
                        second='Here you can find the code for the selected item.'
                        third='Enjoy!'
                    />
                    <RightSide>
                        {!load &&
                            <>
                                <Load>
                                    <img src={loadingGif} alt=''></img>
                                    <h3>Loading...</h3>
                                </Load>
                            </>
                        }
                        <Iframe
                            src={url}
                            title={title}
                            // allow="camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; xr-spatial-tracking"
                            allow="accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking"
                            sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
                        ></Iframe>

                    </RightSide>
                </WrapperRightSide>
                :
                history.replace("/dashboard")
            :
            <WrapperRightSide>
                <RightSideEmpty >
                    <i className="fa-solid fa-fingerprint"></i>
                    <h3>You need to log in!</h3>
                    <BtnLink to='/login'>Log in</BtnLink>
                </RightSideEmpty>
            </WrapperRightSide>
    );
};

export default CodeComp;