import React, { useState } from 'react';
// import { Link } from 'react-router-dom';

// PACKAGES
import styled from 'styled-components';

// COMPONENTS
import Admin from './AdminGradient';

// REDUX
import { useSelector } from 'react-redux';


const CardWrapper = styled.div`
    position: relative;
    width: 300px;
    height: 350px;
    /* border-radius: var(--radius); */
    margin: 10px 0px;
    padding: 10px;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    /* box-shadow: -50px 50px 150px -30px #101117; */
    /* box-shadow: -30px 30px 40px -10px rgba(16,17,23,0.6); */
    /* transition: all 0.3s cubic-bezier(.25,.8,.25,1); */
    overflow: hidden;
    /* background-image: linear-gradient(to top,#28283e 0%,#52527b); */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    background: var(--background3);
        >textarea {
            display: none;
            height: 1px;
            width: 1px;
            position: absolute;
            left: -100000px;
        }
  `;

const Sample = styled.div`
    position: relative;
    width: 250px;
    height: 250px;
    border-radius: 250px;
    background: ${props => props.bk};
    background-color: ${props => props.color};
    background-image: ${props => props.image};
    background-blend-mode: ${props => props.blend};
    box-shadow: inset 6px 4px 3px 5px rgb(0 0 0 / 30%);
    cursor: zoom-in;
`;

const Info = styled.div`
    width: 100%;
    padding:  0 10px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
        >h4{
            color: var(--text4);
            font-weight: bold;
        }
        >h5{
            color: var(--text3);
        }
`;

const Zoom = styled.div`
    position: fixed;
    z-index: 889;
    transition: transform 0.7s cubic-bezier(0.47, 0.04, 0.22, 0.92), height 0.2s ease, width 0.2s ease;
    transform: none;
    border-radius: 0;
    height: 100vh !important;
    width: 100vw !important;
    left: 0 !important;
    top: 0 !important;
    cursor: zoom-out;
    background: ${props => props.bk};
    background-color: ${props => props.color};
    background-image: ${props => props.image};
    background-blend-mode: ${props => props.blend};
`;

const Text = styled.div`
    display: none;
    height: 1px;
    width: 1px;
    position: absolute;
    left: -100000px;
`;

const BtnCopy = styled.button`
    position: absolute;
    bottom: 15px;
    right: 10px;
    /* padding: 10px 30px; */
    border:none;
    outline: none;
    /* background: var(--color4); */
    background: transparent;
    color: var(--text4);
    font-weight: bold;
    /* text-decoration: none; */
    border-radius: var(--radius);
    display: flex;
    align-items: center;
    justify-content: center;
        >i{
            /* margin-left: 20px;
            font-size:20px; */
            color: var(--text5);   
        }
        &:hover{
            /* opacity: .7; */
            transition: .5s ease-in-out;
            /* text-decoration: underline; */
            color: var(--text5); 
        }
        &:active{
                transform:translateY(2px) ;
        }  
`;


const GradientCard = ({ gradient, createRef, index }) => {
    const profile = useSelector((state) => state.auth.user);
    const [zoom, setZoom] = useState(false);
    const [sts, setSts] = useState({
        txt: '',
        msg: false,
    });

    // console.log('IN CARD', gradient);

    const copyCSS = () => {
        // get text in the text-container and 'put' it inside the empty textarea
        let textContainer = document.getElementById(`myText${index}`).innerText;
        document.getElementById(`holdtext${index}`).value = textContainer;

        // set textarea to display block, then select the text inside the textarea
        let text = document.getElementById(`holdtext${index}`);
        text.style.display = 'block';
        text.select();
        // copy the text in the textarea
        try {
            let status = document.execCommand("Copy");
            if (!status) {
                setSts({
                    ...sts,
                    txt: "Cannot copy text",
                    msg: true
                })
                // console.log('Cannot copy text');

            } else {
                // toastr.success('Copied');
                setSts({
                    ...sts,
                    txt: "Copied!",
                    msg: true
                })
                // console.log('Copied!');
            }
        } catch (err) {
            // handle error
            // console.log('Could not copy');
        }

        setTimeout(() => {
            setSts({ ...sts, msg: false })
        }, 4000)
    };

    return (
        <CardWrapper
            ref={createRef}
        >
            {(profile !== undefined && profile.accesLevel === 'admin') &&
                <Admin
                    gradient={gradient}
                />
            }

            <Sample
                onClick={() => setZoom(!zoom)}
                bk={gradient.Bk}
                color={gradient.BkColor}
                image={gradient.BkImage}
                blend={gradient.BkBlend}
            />

            <Info>
                <h4>{gradient.Name}</h4>
                <h5>{gradient.MadeWith}</h5>
            </Info>

            {zoom &&
                <Zoom
                    onClick={() => setZoom(!zoom)}
                    bk={gradient.Bk}
                    color={gradient.BkColor}
                    image={gradient.BkImage}
                    blend={gradient.BkBlend}
                >
                </Zoom>
            }

            <BtnCopy onClick={() => copyCSS()}>
                {sts.msg ?
                    sts.txt
                    :
                    'Copy Css'
                }
            </BtnCopy>

            <textarea id={`holdtext${index}`} ></textarea>
            <Text id={`myText${index}`}>
                {gradient.Bk ? `background: ${gradient.Bk};\n` : null}
                {gradient.BkColor ? `background-color: ${gradient.BkColor};\n` : null}
                {gradient.BkImage ? `background-image: ${gradient.BkImage};\n` : null}
                {gradient.BkBlend ? `background-blend-mode: ${gradient.BkBlend};\n` : null}
            </Text>
        </CardWrapper>
    );
};

export default GradientCard;