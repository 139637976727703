import styled, { keyframes } from 'styled-components';

// MODAL
export const PopUp = styled.div`
    position:absolute;
    top: ${props => props.new? '-45px':'-35px'};
    right: 0px;
    padding: 10px;
    min-width: ${props => props.new? '150px': null};
    background:#6f6f6f73;
    color: var(--text5);
    text-align: center;
    font-size: 12px;
    border-radius: var(--radius);
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    backdrop-filter: blur(2px);
    transition: var(--transition);
`;

export const IconWrapper = styled.div`
    position: relative;
        >i{
            font-size: 20px;
            padding: 10px;
            cursor: pointer;
                &:hover{
                    color:var(--color2);
            }
        }
        &:hover ${PopUp}{
                        opacity: 1;
                        visibility: visible;
                    }
`;


export const ModalWrapper = styled.div`
    position:fixed;
    width: 100%;
    height:100vh;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index:88;
    pointer-events: auto;
    cursor: pointer;
    background: #2525256e;
    backdrop-filter: blur(10px);
    padding: 20px;
    transition: background-color .1s;
`;

export const enter = keyframes`
0% {
    opacity: 0;
    transform:  translate(-50%, -50%) scale(1.4);
  }

  100% {
    opacity: 1;
    transform:  translate(-50%, -50%) scale(1);
  }
/* 0% {
    animation-timing-function: ease-in;
    transform: translate(-50%,-150%);
  }

  38% {
    visibility: visible;
    animation-timing-function: ease-out;
    transform: translate(-50%,-50%);
  }

  55% {
    visibility: visible;
    animation-timing-function: ease-in;
    transform: translate(-50%,-65%);
  }

  72% {
    visibility: visible;
    animation-timing-function: ease-out;
    transform: translate(-50%,-50%);
  }

  81% {
    visibility: visible;
    animation-timing-function: ease-in;
    transform: translate(-50%,-38%);
  }

  90% {
    visibility: visible;
    animation-timing-function: ease-out;
    transform: translate(-50%,-50%);
  }

  95% {
    visibility: visible;
    animation-timing-function: ease-in;
    transform: translate(-50%,-8%);
  }

  100% {
    visibility: visible;
    animation-timing-function: ease-out;
    transform: translate(-50%, -50%);
  } */
`;

export const out = keyframes`
0% {
    visibility: visible;
    transform:  translate(-50%, -50%);
}
45% {
    visibility: visible;
    transform:  translate(-50%, -55%);
}
80% {
    transform:  translate(-50%, -50%);
}
100% {
    transform:  translate(-50%, -100%);
}
`;

export const DialogBox = styled.div`
    /* visibility: hidden; */
    opacity: 0;
    position:fixed;
    top: 50%;
    left: 50%;
    /* transform:translate(-50%, -50%); */
    width: 50%;
    max-width: 500px;
    min-width: 350px;
    max-height: 800px;
    overflow: auto;
    background: var(--color3);
    border-radius: var(--radius);
    z-index: 888;
    padding: 10px;
    animation: ${enter} .1s ease 0s 1 normal forwards;
    display: block!important;
        @media (max-width: 775px) {
            max-height: calc(100vh - 100px);
        };
`;

export const Hr = styled.hr`
    width: 100%;
    border: none;
    border-bottom: 1px solid var(--text4);
`;

export const H3 = styled.h3`
    width:100%;
    text-align: center;
    color: var(--text2);
    padding: 10px;
`;


export const LabelCss = styled.label`
	display: block;
	font-size: 1rem;
	margin: 20px 10px 10px;
    text-align: left;
    font-weight:bold;
    /* font-style:italic; */
    color: var(--text1);
    display: flex;
    cursor: default;
`;

export const AddNewBtn = styled.div`
    margin-left: 10px;
    margin-top:2px;
    color: var(--text3);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
        &:hover{
            color: var(--success);
            transition: color var(--trasition) ease-in-out;
        }
`;

export const InputCss = styled.input`
    width:100%;
    height: 50px;
    padding: 6px 12px;
    border: 1px solid var(--border);
    border-radius: var(--radius);
    color: var(--text2);
    font-weight: bold;
    &:focus{
        outline: none;
        border: 1px solid var(--color4);
    }
    &::placeholder { 
        color: var(--text3);
        font-size: 14px;
    }
`;

export const InputWrapper = styled.label`
	display: block;
    width: ${props => !props.full ? '50%' : '100%'};
    padding: ${props=> (props.smallR && '0 10px 0 0')|| (props.smallL && '0 0 0 10px')};
    /* padding: 0 10px; */
        @media (max-width: 775px) {
            width: 100%;
        };
`;

export const Fields = styled.div`
    width:100%;
    display: flex;
        @media (max-width: 775px) {
        display:block;
        };
`;

export const TextArea = styled.textarea`
    width:100%;
    resize: none;
    padding: 6px 12px;
    border:1px solid var(--border);
    border-radius: var(--radius);
    &:focus{
        outline:none;
        border:1px solid var(--color2);
    }
    &::placeholder { 
            color: var(--text2);
            font-size: 14px;
    }
`;

export const CalendarWrapper = styled.div`
   position: absolute;
   z-index:2;
   right:10px;
   /* left: 50%;
   transform: translateX(-50%); */
`;

export const CalendarBtn = styled.div`
    width:100%;
    display: flex;
    justify-content: flex-end;
    background-color: var(--background);
    padding: 10px;
        >button{
            margin:0;
            height: auto;
            padding: 10px 20px;
            font-size: 12px;
        }
        >button:nth-child(1){
            margin-right:10px;
        }
`;

export const BtnWrapper = styled.div`
    width:100%;
    display: flex;
    justify-content: center;
        @media (max-width: 775px) {
            flex-direction: column;
        };
`;

export const Btn = styled.button`
    height:50px;
    padding: 0 30px;
    min-width: 150px;
    display:flex;
    align-items: center;
    justify-content:center;
    font-weight: bold;
    letter-spacing: 2px;
    border: none;
    color:${props => !props.disable ? 'var(--text5)' : 'var(--color1)'};
    border-radius: var(--radius);
    margin-top: 40px;
    margin-left: 10px;
    cursor: pointer;
    transition: var(--transition) ease;
    background: ${props => !props.disable ? 'var(--success)' : 'var(--text4)'};
    /* border: ${props => !props.disable ? '1px solid var(--success)' : '1px solid var(--color1)'}; */
    outline:none;
        &:hover{
            opacity: ${props => !props.disable && '.7'};;
            color:${props => !props.disable ? 'var(--text5)' : 'var(--text5)'};
            background: ${props => !props.disable ? 'var(--success)' : 'var(--err)'};
            /* border: ${props => !props.disable ? '1px solid var(--success)' : '1px solid var(--err)'}; */
        }
        @media (max-width: 775px) {
            margin-top: 10px;
            margin-left: 0px;
        };
`;


export const colourStyles = {
    control: styles => ({
        ...styles,
        backgroundColor: 'white',
        borderRadius: '4px',
        border: '1px solid var(--border)',
        minHeight: '50px',
        fontSize: '14px',
        paddingLeft: '4px',
        boxShadow: 'none',
        cursor: 'pointer',
    }),
    menu: styles => ({
        ...styles,
        backgroundColor: 'white',
        border: '1px solid var(--background1)',
        fontSize: '14px',
        color:'var(--text1)',
        zIndex:2,
    }),
    input: styles => ({ ...styles, fontSize: '14px' }),
    placeholder: styles => ({ ...styles, fontSize: '14px' }),
    singleValue: (styles, { data }) => ({ ...styles, fontSize: '14px' }),
    multiValue: (styles, { data }) => ({ ...styles, fontSize: '14px', backgroundColor: 'var(--color9)' }),
};

// LOGO
export const UploadPozWrapper = styled.div`
    /* position: absolute; */
    top: 30px;
    left: 50%;
    /* transform:translateX(50%); */
`;

export const UploadWrapper = styled.div`
    position: relative;
    width:100%;
    height: 50px;
    border:1px solid var(--border);
    border-radius: var(--radius);
        &:hover{
            border:1px solid var(--color4);
        }
        >h4{
            position: absolute;
            left:70px;
            top:50%;
            transform:translateY(-50%);
            color: var(--text1);
        }
        >span{
            z-index: 0;
            width: 50px;
            height: 50px;
            border-radius: 5px 0 0 5px;
            cursor: pointer;
            color: var(--text5);
            font-size:20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: var(--color4);
            backdrop-filter: blur(2px);
        }
        >div{
            position: relative;
            width: 50px;
            height: 50px;
            border-radius: 5px 0 0 5px;
            overflow:hidden;
                >img{
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    -webkit-transform: translate(-50%,-50%);
                    -ms-transform: translate(-50%,-50%);
                    transform: translate(-50%,-50%);
                    object-fit: cover;
                }
        }
`;

export const UploadFileInput = styled.input`
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
        &::-webkit-file-upload-button{
            cursor: pointer;
        }
`;