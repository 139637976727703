import React, { Component } from 'react';
import './Terms.css';


class Terms extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
      }


    render() {
        return (

            <div className="terms">

                <div className="terms-title"><h1>Terms and Conditions</h1>
                    <h6>Last updated: Jun 19th, 2020</h6>
                </div><br></br>

                <hr className="terms-hr"></hr><br></br>

                <div className="terms-subtitle"><h4>General Terms</h4></div>
                <p>By accessing and placing an order with <span className="csyes-span">CSYES<span className="terms-span">!</span> Team</span>, you confirm that you are in agreement with and bound by the terms and conditions contained in the Terms Of Use outlined below.
                These terms apply to the entire website and any email or other type of communication between you and <span className="csyes-span">CSYES<span className="terms-span">!</span> Team</span>.</p>
                <p> Under no circumstances shall <span className="csyes-span">CSYES<span className="terms-span">!</span> Team</span> be liable for any direct, indirect, special, incidental or consequential damages, including,
                but not limited to, loss of data or profit, arising out of the use, or the inability to use, the materials on this site, even if <span className="csyes-span">CSYES<span className="terms-span">!</span> Team</span> or an authorized representative has been advised of the possibility of such damages. If your use of materials from this site results in the need for servicing, repair or correction of equipment or data, you assume any costs thereof.</p>
                <p><span className="csyes-span">CSYES<span className="terms-span">!</span> Team</span> will not be responsible for any outcome that may occur during the course of usage of our resources.We reserve the rights to change prices and revise the resources usage policy in any moment.
                </p><br></br>

                <div className="terms-subtitle"><h4>Products</h4></div>
                <p>All products and services are delivered by <span className="csyes-span">CSYES<span className="terms-span">!</span> Team</span>.
                </p><br></br>

                <div className="terms-subtitle"><h4>Security</h4></div>
                <p><span className="csyes-span">CSYES<span className="terms-span">!</span> Team</span> does not process any order payments through the website. All payments are processed securely through <span className="span-stripe">Stripe</span> Checkout, a third party online payment provider.
                </p><br></br>

                <div className="terms-subtitle"><h4>Payments</h4></div>
                <p>We process all of our payments through <span className="span-stripe">Stripe</span> Checkout.
                </p>
                <p>You have 24 hours to inspect your purchase and to determine if it does not meet with the expectations laid forth by the seller. In the event that you wish to receive a refund, <span className="csyes-span">CSYES<span className="terms-span">!</span> Team</span> will issue you a refund and ask you to specify how the product failed to live up to expectations.
                </p>
                <br></br>

                <div className="terms-subtitle"><h4>Cookie Policy</h4></div>
                <p>A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a web server to a web browser and is stored by the browser. The identifier is then sent back to the server each time the browser requests a page from the server. Our website uses cookies. By using our website and agreeing to this policy, you consent to our use of cookies in accordance with the terms of this policy.</p>

                <p>We use session cookies to personalise the website for each user. We use persistent cookies to keep tracks of referrals coming from our affiliate network.</p>

                <p>We use Google Analytics to analyse the use of our website. Our analytics service provider generates statistical and other information about website use by means of cookies. Our analytics service provider's privacy policy is available at: <a href='http://www.google.com/policies/privacy/' target="_blank" rel="noopener noreferrer">http://www.google.com/policies/privacy/</a></p>.

                <p>Deleting cookies will have a negative impact on the usability of the site. If you block cookies, you will not be able to use all the features on our website.</p>
                <br></br>



                <div className="terms-subtitle"><h4>Ownership</h4></div>
                <p>Ownership of the product is governed by the usage license selected by the seller.
                You may not claim intellectual or exclusive ownership to any of the items offered on <span className="csyes-span">CSYES<span className="terms-span">!</span> Team</span>, modified or unmodified. Products are exclusive property of <span className="csyes-span">CSYES<span className="terms-span">!</span> Team</span>.
                </p><br></br>

                <div className="terms-subtitle"><h4>Changes about terms</h4></div>
                <p>If we change our terms of use we will post those changes on this page. Registered users will be sent an email that outlines changes made to the terms of use.
                </p><br></br>


            </div>
        )
    }
}


export default Terms;