import React, { useEffect, useState } from 'react';

// PACKEGES
import styled from 'styled-components';
import Select from 'react-select';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { editPosterAndContent, reciveAllContent } from '../../../../Store/Actions/contentActions';

// SHARE STYLE
import {
    ModalWrapper,
    DialogBox,
    H3,
    Hr,
    LabelCss,
    InputCss,
    Fields,
    InputWrapper,
    BtnWrapper,
    Btn,
    AddNewBtn,
    colourStyles,
    UploadPozWrapper,
    UploadWrapper,
    UploadFileInput,
} from './ModalStyle';


export const ActionBtn = styled.div`
    position: absolute;
    top: 10px;
    right: 55px;
    z-index: 1;
    width: 40px;
    height:40px;
    margin-left: 5px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: var(--background5);
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    backdrop-filter: blur(10px);
        >i{
            color:  var(--text5);
        }
        &:hover{
                >i{
                    color: ${props => props.color};
                    transition:color var(--transition) ease-in-out;
                }
           }
`;


const Modal = ({ content }) => {
    const dispatch = useDispatch();
    const profile = useSelector((state) => state.auth.user);
    const cont = useSelector((state) => state.content.allContent);
    const [openModal, setOpenModal] = useState(false);
    const [more, setMore] = useState({
        tech: content.Technology,
        theme: content.Theme,
        componentName: content.Name,
        link: content.CodeURL,
        category: content.Category,
        keywords: content.Keywords,
        madeWith: content.MadeWith,
        poster: '',
    });

    const [newCat, setNewCat] = useState(false);
    const [options, setOptions] = useState([]);
    const [imgName, setImgName] = useState('')
    const [imagePreview, setImagePreview] = useState();

    useEffect(() => {
        dispatch(reciveAllContent());
    }, [dispatch])

    useEffect(() => {
        if (cont !== undefined) {
            let arr = [];
            let createOptions = [];

            function capitalizeFirstLetter(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            };

            for (let i = 0; i < cont.length; i++) {
                arr.push(cont[i].Category);
            };

            let uniq = [...new Set(arr)].sort();

            uniq.forEach((op) => createOptions.push({ value: op, label: capitalizeFirstLetter(op) }));

            setOptions(createOptions);

            // console.log('FMM',createOptions);
        }
    }, [cont]);

    const handleChange = evt => {
        const name = evt.target.name;
        const value = evt.target.value;
        setMore({
            ...more,
            [name]: value
        });
    };

    const handleClose = (e) => {
        e.preventDefault();
        setOpenModal(false);
        setImgName();
        setImagePreview();
        setNewCat(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(editPosterAndContent(more, content.ContentID, profile.accesLevel,));
        handleClose(e);
    };

    const handleFile = (e) => {
        const image = e.target.files[0];
        if (image !== undefined) {
            setImgName(image.name);
        };
        // DISPLAY THE IMG/FILE
        const reader = new FileReader();
        reader.onloadend = () => {
            if (reader.readyState === 2) {
                setImagePreview(reader.result);
            }
        };
        if (image !== undefined) {
            reader.readAsDataURL(image);
        };

        const data = new FormData()
        data.append('file', image)
        setMore({
            ...more,
            poster: data,
        });
    };

    return (
        <>
            <ActionBtn onClick={() => setOpenModal(!openModal)} color='var(--warning)'>
                <i className="fas fa-pencil-alt"></i>
            </ActionBtn>

            {/* <ActionBtn ></ActionBtn>
            <ActionBtn color='var(--err)'><i className="fas fa-trash-alt"></i></ActionBtn> */}

            {openModal &&
                <>
                    <ModalWrapper onClick={(e) => handleClose(e)} />

                    <DialogBox>
                        <H3>Edit content</H3>
                        <Hr></Hr>


                        <form onSubmit={handleSubmit}>
                            <Fields>
                                <InputWrapper smallR>
                                    <LabelCss htmlFor='tech'>Technology used</LabelCss>
                                    <InputCss
                                        placeholder='eg. React, Vue, etc.'
                                        type='text'
                                        name='tech'
                                        id='tech'
                                        value={more.tech}
                                        onChange={handleChange}
                                    // onChange={(e) =>
                                    //     setFreeDays({
                                    //         ...freeDays,
                                    //         nr: e.target.value
                                    //     })
                                    // }
                                    ></InputCss>
                                </InputWrapper>

                                <InputWrapper smallL>
                                    <LabelCss htmlFor='theme'>Theme name</LabelCss>
                                    <InputCss
                                        placeholder='eg. Classic, Stylish'
                                        type='text'
                                        name='theme'
                                        id='theme'
                                        value={more.theme}
                                        onChange={handleChange}
                                    // value={freeDays.nr}
                                    // onChange={(e) =>
                                    //     setFreeDays({
                                    //         ...freeDays,
                                    //         nr: e.target.value
                                    //     })
                                    // }
                                    ></InputCss>
                                </InputWrapper>
                            </Fields>


                            <LabelCss htmlFor='componentName'>Component name</LabelCss>
                            <InputCss
                                placeholder='eg. Classic textarea, Grouped Buttons'
                                type='text'
                                name='componentName'
                                id='componentName'
                                value={more.componentName}
                                onChange={handleChange}
                            // value={freeDays.nr}
                            // onChange={(e) =>
                            //     setFreeDays({
                            //         ...freeDays,
                            //         nr: e.target.value
                            //     })
                            // }
                            ></InputCss>

                            <LabelCss htmlFor='link'>Link to code</LabelCss>
                            <InputCss
                                placeholder='Put the link here'
                                type='text'
                                name='link'
                                id='link'
                                value={more.link}
                                onChange={handleChange}
                            // value={freeDays.nr}
                            // onChange={(e) =>
                            //     setFreeDays({
                            //         ...freeDays,
                            //         nr: e.target.value
                            //     })
                            // }
                            ></InputCss>


                            <LabelCss htmlFor='category'>Category
                                <AddNewBtn onClick={() => setNewCat(!newCat)}>
                                    {newCat === false ?
                                        <i className="fas fa-plus-circle"></i>
                                        :
                                        <i className="fas fa-times-circle"></i>
                                    }
                                </AddNewBtn>
                            </LabelCss>
                            {newCat === true ?
                                <Select
                                    isMulti={false}
                                    isClearable={true}
                                    isSearchable={true}
                                    closeMenuOnSelect={true}
                                    // value={supervisor.serv}
                                    onChange={(e) =>
                                        setMore({
                                            ...more,
                                            category: e === null ? '' : e.value,
                                        })
                                    }
                                    name='category'
                                    id='category'
                                    options={options}
                                    styles={colourStyles}
                                    placeholder='eg. Buttons, Avatars, Alerts'
                                />
                                :
                                <InputCss
                                    placeholder='Add new category'
                                    type='text'
                                    name='category'
                                    id='category'
                                    value={more.category}
                                    onChange={handleChange}
                                ></InputCss>
                            }

                            <Fields>
                                <InputWrapper smallR>
                                    <LabelCss htmlFor='keywords'>Keywords</LabelCss>
                                    <InputCss
                                        placeholder='Keywords'
                                        type='text'
                                        name='keywords'
                                        id='keywords'
                                        value={more.keywords}
                                        onChange={handleChange}
                                    ></InputCss>
                                </InputWrapper>
                                <InputWrapper smallL>
                                    <LabelCss htmlFor='madeWith'>Made with</LabelCss>
                                    <InputCss
                                        placeholder='Made with'
                                        type='text'
                                        name='madeWith'
                                        id='madeWith'
                                        value={more.madeWith}
                                        onChange={handleChange}
                                    ></InputCss>
                                </InputWrapper>
                            </Fields>

                            <LabelCss htmlFor='upload'>Add poster</LabelCss>
                            <UploadPozWrapper>
                                <UploadWrapper>
                                    <h4>{imgName}</h4>
                                    {imagePreview === undefined ?
                                        <span ><i className="fas fa-upload"></i></span>
                                        :
                                        <div>
                                            <img src={imagePreview} alt=""></img>
                                        </div>
                                    }

                                    <UploadFileInput
                                        type="file"
                                        name="upload"
                                        id="upload"
                                        placeholder="Upload File"
                                        onChange={(e) => handleFile(e)} />
                                </UploadWrapper>
                            </UploadPozWrapper>

                            <BtnWrapper>
                                <Btn disable onClick={handleClose}>Close</Btn>
                                <Btn type='submit'>Edit</Btn>
                            </BtnWrapper>
                        </form>

                    </DialogBox>

                </>
            }

        </>

    );
}
export default Modal;