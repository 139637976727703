const initState = {
  allTeammates: [],
};

const teammateReducer = (state = initState, action) => {
  switch (action.type) {
    case 'RECIVED_TEAMMATES':
      return {
        ...state,
        allTeammates: action.payload,
      };

      case 'NOTIF_HANDLER_TEAMMATE':
      return {
        ...state,
        notifTeammate: action.payload,
      };

    default:
      return state;
  }
};

export default teammateReducer;