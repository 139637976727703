import React, { useEffect, useState, useRef, useCallback } from 'react';

//ASSETS
// import loadingGif from '../../../Assets/loadingBtn2.gif';

// PACKAGES
import styled from 'styled-components';
// import InfiniteScroll from 'react-infinite-scroll-component';

// SHARE STYLE
import { WrapperRightSide, RightSideEmpty } from '../ShareStyle/ShareStyle';

// SHARE COMPONENTS
import Title from '../../DashboardComponents/PagesTitles/PagesTitles';
import GradientCard from './GradientCard';
import GradientCardSkeleton from './GradientCardSkeleton';
import SawAll from '../Home/SawAllAnimation';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { reciveGradient, setPageNumber, setPrevLocation } from '../../../Store/Actions/gradientActions';

const RightSide = styled.div`
    width: 100%;
    max-width: 2200px;
    border-radius: var(--radius);
    margin: auto;
    margin-top: 20px;
    display: grid;
    grid-template-columns: ${props => props.length === 1 && `repeat(1,auto)!important`};
    grid-template-columns: ${props => props.length <= 4 ? `repeat(${props.length},auto)` : 'repeat(5,auto)'};
    grid-column: 50px;
    grid-row-gap: 0px;
    justify-content: space-evenly;
        @media (max-width: 2300px) {
                /* grid-template-columns: repeat(3,auto); */
                grid-template-columns: ${props => props.length <= 4 ? `repeat(${props.length},auto)` : 'repeat(5,auto)'};
        }
        @media (max-width: 1850px) {
                grid-template-columns: repeat(4,auto);
        }
        @media (max-width: 1550px) {
                grid-template-columns: repeat(3,auto);
        }
        @media (max-width: 1250px) {
                grid-template-columns: repeat(2,auto);
        }
        @media (max-width: 950px) {
                grid-template-columns: repeat(1,auto);
        }
`;

const Gradients = ({ flag }) => {
    const dispatch = useDispatch();
    const gradient = useSelector((state) => state.gradient.gradient);

    const pageNumber = useSelector((state) => state.gradient.pageNumberGradient);
    const prevPageNumber = useSelector(({ gradient }) => gradient.prevPageNumberGradient);

    const loading = useSelector(({ gradient }) => gradient.loadingGradient);
    const hasMore = useSelector(({ gradient }) => gradient.hasMoreGradient);

    const [nrOfSkeletonCards] = useState(10);

    const observer = useRef();
    const lastCompElementRef = useCallback(node => {
        if (loading) return
        if (observer.current) observer.current.disconnect()
        // const options = { threshold: 0.5 };
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                dispatch(setPageNumber(pageNumber + 1));
            }
        },
            { threshold: 0.25, rootMargin: "0px" }
        )
        if (node) observer.current.observe(node)
    }, [dispatch, loading, hasMore, pageNumber]);

    useEffect(() => {
        if (prevPageNumber !== pageNumber) {
            // console.log('AM INTRAT1')
            dispatch(reciveGradient());
        }
    }, [dispatch, pageNumber, prevPageNumber,]);

    useEffect(() => {
        dispatch(setPrevLocation(pageNumber));
    }, [dispatch, pageNumber]);

    // console.log('CONTENT', cont);
    // console.log('hasMore', hasMore);
    // console.log('pageNumber', pageNumber);
    // console.log('prevPageNumber', prevPageNumber);
    // console.log('loadingT', loading);


    return (
        <WrapperRightSide>
            <Title
                first='Gradients'
                second='We hope to inspire you.'
                third='Enjoy!'
            />
            {gradient !== undefined &&
                <>
                    {gradient.length > 0 &&
                        <>
                            <RightSide length={gradient.length}>
                                {gradient.map((el, i) => {
                                    if (gradient.length === i + 1) {
                                        return (
                                            <GradientCard
                                                createRef={lastCompElementRef}
                                                key={i}
                                                gradient={el}
                                                index={i}
                                            />
                                        )
                                    } else {
                                        return (
                                            <GradientCard
                                                key={i}
                                                gradient={el}
                                                index={i}
                                            />
                                        )
                                    }
                                }
                                )
                                }
                                {loading &&
                                    [...Array(nrOfSkeletonCards)].map((el, i) =>
                                        <GradientCardSkeleton key={i} />
                                    )
                                }
                            </RightSide>
                            {(!hasMore && gradient.length >= 10) && <SawAll />}
                        </>
                    }

                    {gradient.length === 0 &&
                        <RightSide>
                            {loading &&
                                [...Array(nrOfSkeletonCards)].map((el, i) =>
                                    <GradientCardSkeleton key={i} />
                                )
                            }
                        </RightSide>
                    }

                    {(!loading && gradient.length === 0) &&
                        <RightSideEmpty >
                            <i className="fas fa-fill-drip"></i>
                            <h3>Content in progress...</h3>
                        </RightSideEmpty>
                    }
                </>
            }
        </WrapperRightSide >
    );
};

export default Gradients;