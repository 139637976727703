import React, { useState } from 'react';
// import ReactDom from 'react-dom';

// PACKEGES
import styled from 'styled-components';
// import Select from 'react-select';

// REDUX
import { useDispatch } from 'react-redux';
import { editGradient } from '../../../Store/Actions/gradientActions';

// SHARE STYLE
import {
    ModalWrapper,
    DialogBox,
    H3,
    Hr,
    LabelCss,
    InputCss,
    // Fields,
    // InputWrapper,
    BtnWrapper,
    Btn,
    // AddNewBtn,
    // ActionBtn,
    // colourStyles,
    // UploadPozWrapper,
    // UploadWrapper,
    // UploadFileInput,
} from '../Admin/AdminComponents/ModalStyle';


export const ActionBtn = styled.div`
    position: absolute;
    top: 10px;
    right: 55px;
    z-index: 1;
    width: 40px;
    height:40px;
    margin-left: 5px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: var(--background5);
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    backdrop-filter: blur(10px);
        >i{
            color:  var(--text5);
        }
        &:hover{
                >i{
                    color: ${props => props.color};
                    transition:color var(--transition) ease-in-out;
                }
           }
`;


const Modal = ({ gradient }) => {
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);
    const [more, setMore] = useState({
        gradientName: gradient.Name,
        bk: gradient.Bk !== null ? gradient.Bk : '',
        bkColor: gradient.BkColor !== null ? gradient.BkColor : '',
        bkImage: gradient.BkImage !== null ? gradient.BkImage : '',
        bkBlend: gradient.BkBlend !== null ? gradient.BkBlend : '',
        keywords: gradient.Keywords,
        madeWith: gradient.MadeWith,
    });

    const handleClose = (e) => {
        e.preventDefault();
        setOpenModal(false);
    };

    const handleChange = evt => {
        const name = evt.target.name;
        const value = evt.target.value;
        setMore({
            ...more,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(editGradient(more, gradient.GradientID));
        handleClose(e);
    };


    return (
        <>
            <ActionBtn onClick={() => setOpenModal(!openModal)} color='var(--warning)'>
                <i className="fas fa-pencil-alt"></i>
            </ActionBtn>

            {openModal &&
                <>
                    <ModalWrapper onClick={(e) => handleClose(e)} />

                    <DialogBox>
                        <H3>Edit gradient</H3>
                        <Hr></Hr>

                        <form onSubmit={handleSubmit}>
                            <LabelCss htmlFor='gradientName'>Gradient name</LabelCss>
                            <InputCss
                                placeholder='eg. Lady Lips, Young Passion'
                                type='text'
                                name='gradientName'
                                id='gradientName'
                                value={more.gradientName}
                                onChange={handleChange}
                                required
                            ></InputCss>

                            <LabelCss htmlFor='bk'>Background</LabelCss>
                            <InputCss
                                placeholder='Put the background here'
                                type='text'
                                name='bk'
                                id='bk'
                                value={more.bk}
                                onChange={handleChange}
                            ></InputCss>

                            <LabelCss htmlFor='bkColor'>Background color</LabelCss>
                            <InputCss
                                placeholder='Put the background-color here'
                                type='text'
                                name='bkColor'
                                id='bkColor'
                                value={more.bkColor}
                                onChange={handleChange}
                            ></InputCss>

                            <LabelCss htmlFor='bkImage'>Background image</LabelCss>
                            <InputCss
                                placeholder='Put the background-image here'
                                type='text'
                                name='bkImage'
                                id='bkImage'
                                value={more.bkImage}
                                onChange={handleChange}
                            ></InputCss>

                            <LabelCss htmlFor='bkBlend'>Background blend</LabelCss>
                            <InputCss
                                placeholder='Put the background-blend here'
                                type='text'
                                name='bkBlend'
                                id='bkBlend'
                                value={more.bkBlend}
                                onChange={handleChange}
                            ></InputCss>

                            <LabelCss htmlFor='madeWith'>Made for</LabelCss>
                            <InputCss
                                placeholder='Made with'
                                type='text'
                                name='madeWith'
                                id='madeWith'
                                value={more.madeWith}
                                onChange={handleChange}
                                required
                            ></InputCss>

                            <LabelCss htmlFor='keywords'>Keywords</LabelCss>
                            <InputCss
                                placeholder='Keywords'
                                type='text'
                                name='keywords'
                                id='keywords'
                                value={more.keywords}
                                onChange={handleChange}
                                required
                            ></InputCss>

                            <BtnWrapper>
                                <Btn disable onClick={handleClose}>Close</Btn>
                                <Btn type='submit'>Edit</Btn>
                            </BtnWrapper>
                        </form>

                    </DialogBox>
                </>
            }
        </>
    );
}
export default Modal;