import React, { useEffect } from 'react';

// REDUX
import { useSelector } from 'react-redux';
import styled from 'styled-components';

// PACKAGES
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// COMPONENTS
import Msg from './Msg';

const StyledToastContainer = styled(ToastContainer).attrs({})`

    /* .Toastify__toast-container, 
    .Toastify__toast-container--top-right, 
    .toast-container {
        top: 14em!important;
        left: 1em!important;
        padding:0!important;
        min-width: 350px!important;
    } */

    /* .Toastify__toast-container {
        z-index: 9999;
        -webkit-transform: translate3d(0, 0, 9999px);
        position: fixed;
        padding: 4px;
        width: 420px!important;
        box-sizing: border-box;
        color: #fff;
    } */

    .Toastify__toast--default,
    .Toastify__toast--error,
    .Toastify__toast--warning,
    .Toastify__toast--success {
        background: var(--background5);
        backdrop-filter: blur(10px);
        top: 4em;
    }
    .Toastify__toast-body {
        color:var(--text4);
        letter-spacing: 1px;
    }
    .Toastify__close-button > svg {
        fill: var(--text5);
    }
    .Toastify__progress-bar--default {
        /* background: linear-gradient(to left,#E100FF,var(--info)); */
        background:var(--info);
    }
    .Toastify__progress-bar--error {
        /* background: linear-gradient(to left,#E100FF,var(--err)); */
        background:var(--err);
    }
    .Toastify__progress-bar--warning {
        /* background: linear-gradient(to left,#E100FF,var(--warning)); */
        background:var(--warning);
    }
    .Toastify__progress-bar--success {
        /* background: linear-gradient(to left,#E100FF,var(--success)); */
        background:var(--success);
    }
`;


const Notifications = () => {
    const notifAuth = useSelector((state) => state.auth.handlerNotifAuth);
    const notifDash = useSelector((state) => state.dash.handlerNotifDash);
    const handlerNotifContent = useSelector((state) => state.content.handlerNotifContent);
    const notifTeammate = useSelector((state) => state.team.notifTeammate);

    // console.log(handlerNotifContent);

    useEffect(() => {
        if (notifAuth !== undefined) {
            // console.log('NOTIF HANDLER AUTH', notif)
            createNotification(notifAuth.flag, notifAuth.msg);
        }
        if (notifDash !== undefined) {
            createNotification(notifDash.flag, notifDash.msg);
        }
        if (handlerNotifContent !== undefined) {
            createNotification(handlerNotifContent.flag, handlerNotifContent.msg)
        }
        if (notifTeammate !== undefined) {
            createNotification(notifTeammate.flag, notifTeammate.msg)
        }
    }, [notifAuth, notifDash, handlerNotifContent, notifTeammate]);

    const createNotification = (flag, msg) => {
        switch (flag) {
            case 'info':
                toast(
                    <Msg
                        icon={<i className="fas fa-info"></i>}
                        msg={msg}
                        color='var(--info)'
                    />
                );
                break;
            case 'success':
                toast.success(
                    <Msg
                        icon={<i className="fa-solid fa-thumbs-up"></i>}
                        msg={msg}
                        color='var(--success)'
                    />
                );
                break;
            case 'warning':
                toast.warn(
                    <Msg
                        icon={<i className="fa-solid fa-radiation"></i>}
                        msg={msg}
                        color='var(--warning)'
                    />
                );
                break;
            case 'error':
                toast.error(
                    <Msg
                        icon={<i className="fa-solid fa-bomb"></i>}
                        msg={msg}
                        color='var(--err)'
                    />
                );
                break;

            default:
        };
    };

    return (
        <StyledToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable={false}
            pauseOnHover
        />
    );
}

export default Notifications;
