const initState = {
  handleSideBar: false,
  pageNumber: 1,
  handleSearch: false,
};

const otherReducer = (state = initState, action) => {
  switch (action.type) {
    case 'HANDLE_SIDEBAR':
      return {
        ...state,
        handleSideBar: action.payload,
      };

      case 'HANDLE_SEARCH':
      return {
        ...state,
        handleSearch: action.payload,
      };

    case 'SET_PAGE_NUMBER':
      return {
        ...state,
        pageNumber: action.payload,
      };

    case 'SET_PREV_PAGE_NUMBER':
      return {
        ...state,
        prevPageNumber: action.payload,
      };

    case 'SET_PREV_FLAG':
      return {
        ...state,
        prevFlag: action.payload,
      };

    default:
      return state;
  }
};

export default otherReducer;